import { useState, useEffect, useCallback } from 'react';

interface UseOtpResendTimerReturn {
	timeLeft: number;
	isActive: boolean;
	startTimer: () => void;
}

const useOtpTimer = (initialTime: number = 60): UseOtpResendTimerReturn => {
	const [timeLeft, setTimeLeft] = useState<number>(initialTime);
	const [isActive, setIsActive] = useState<boolean>(false);

	useEffect(() => {
		let timer: NodeJS.Timeout;
		if (isActive && timeLeft > 0) {
			timer = setTimeout(() => setTimeLeft(timeLeft - 1), 1000);
		} else if (timeLeft === 0) {
			setIsActive(false);
		}
		return (): void => clearTimeout(timer);
	}, [timeLeft, isActive]);

	const startTimer = useCallback(() => {
		setTimeLeft(initialTime);
		setIsActive(true);
	}, [initialTime]);

	return { timeLeft, isActive, startTimer };
};

export default useOtpTimer;
