import { ReactElement } from 'react';
import { IContractorNavigation } from '.';
import { NavLink } from 'react-router-dom';

export const ContractorNavigation = (props: IContractorNavigation): ReactElement => {
	const { data, membershipNumber } = props;

	return (
		<section className="link-components-wrapper">
			<div className="container">
				<ul className="link-components-inner">
					<li>
						<NavLink
							to="/contractor/qr-code-scanner"
							aria-label="QR Code Scan"
							state={{
								number: data?.phone,
							}}
						>
							<span className="icon-scan"></span>
							<h2 className="h5">QR Code Scan</h2>
						</NavLink>
					</li>
					<li>
						<NavLink
							to="/contractor/banking-details"
							state={{ membershipNumber: membershipNumber, number: data?.phone }}
							aria-label="Banking Details"
						>
							<span className="icon-briefcase"></span>
							<h2 className="h5">Banking Details</h2>
						</NavLink>
					</li>
					<li>
						<NavLink
							to="/contractor/redemption-history"
							aria-label="Redemption History"
							state={{
								number: data?.phone,
								membershipNumber: membershipNumber,
							}}
						>
							<span className="icon-gift"></span>
							<h2 className="h5">Redemption History</h2>
						</NavLink>
					</li>
					<li>
						<NavLink
							to="/contractor/points-statement"
							aria-label="points History"
							state={{
								number: data?.phone,
							}}
						>
							<span className="icon-points-statement"></span>
							<h2 className="h5">Points Statement</h2>
						</NavLink>
					</li>
				</ul>

				<div className="banking-history-card">
					<NavLink
						to="/contractor/site-summary"
						aria-label="product banked tab"
						state={{
							siteId: data?.id,
						}}
						className="banking-history-card-wrapper"
					>
						<div className="left-content">
							<span className="icon-map-pin"></span>
							<h2 className="h5">Site Summary</h2>
						</div>
						<span className="icon-arrow-right-circle"></span>
					</NavLink>
				</div>
			</div>
		</section>
	);
};
