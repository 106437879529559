import { ReactElement, useMemo } from 'react';
import { ISubContractorListProps } from '@components/endUserDetails/components';
import { useNavigate } from 'react-router-dom';
import { useComponentLevelAccessCheck } from '../../../../authGuard/hooks/useComponentLevelAccessCheck';
import { useGetContractorDetails } from '@services/hooks/enduser-details/useGetContractorDetailsQuery';
import { useEndUserStore } from '@store/enduser';

export const SubContractorList = (props: ISubContractorListProps): ReactElement => {
	const navigate = useNavigate();
	const { monthlyGluePotential, isFormEdit, membershipno_c, setFormikValue, isEditing } = props;
	const { selectedSubContractor: selectedStoreValue } = useEndUserStore();
	const checkAddSubContractorPermission = useComponentLevelAccessCheck(
		'/add-member-details/sub-contractor-details',
		true
	);
	const checkEditSubContractorPermission = useComponentLevelAccessCheck(
		'/edit-member-details/sub-contractor-details',
		true
	);

	const urlPath = useMemo(() => {
		return isFormEdit ? '/edit-member-details/sub-contractor-details' : '/add-member-details/sub-contractor-details';
	}, [isFormEdit]);

	const { data: contractorsDetail } = useGetContractorDetails(membershipno_c);

	if (!checkAddSubContractorPermission && !checkEditSubContractorPermission) {
		return <></>;
	}
	if (monthlyGluePotential && monthlyGluePotential < 300) {
		return <></>;
	}

	const countView = (): ReactElement => {
		if (contractorsDetail && contractorsDetail?.count > 0) {
			return <span className="count">{contractorsDetail?.count}</span>;
		}
		if (selectedStoreValue && selectedStoreValue?.length > 0) {
			return <span className="count">{selectedStoreValue?.length}</span>;
		}
		return <></>;
	};

	const navigateToList = (): void => {
		setFormikValue?.();
		navigate(urlPath, {
			state: {
				subContractorMemberShipNo: membershipno_c,
				isFormEdit: isFormEdit,
			},
		});
	};

	return (
		<li className="d-col d-col-2">
			<div className={`manage-participants-button ${isEditing ? 'disable-btn' : ''}`}>
				<div className="label">
					<span className="content">Manage Sub-Contractor</span>
					{countView()}
				</div>

				<button onClick={navigateToList} aria-label="add sub contractor" className="add-user border-none">
					<span className="icon-add-user"></span>
					<span className="icon-right-arrow"></span>
				</button>
			</div>
		</li>
	);
};
