import { ReactElement, useState, useCallback, useEffect } from 'react';
import { IEndUserGenarateOtpProps } from '.';
import { overlayRemoveFadeAction } from '@components/common/sort';
import { Modal } from '@components/common/modal/modal';
import { ConfirmationModal } from '@components/conductedMeetings/confirmationModal/confirmationModal';
import { UseEndUserOtpGenerateService } from '@services/hooks/enduser-details';
import { ToastType, useTosterStore } from '@store/toster';
import { EndUserOtpModal } from '@components/common';
export const GenerateOTP = (props: IEndUserGenarateOtpProps): ReactElement => {
	const { primaryNumber, openOtpModal, setVerifyDetails, setOpenOtpModal } = props;
	const [confirmationModal, setConfirmationModal] = useState<boolean>(false);
	const [confirmClick, setConfirmClick] = useState<boolean>(false);
	const { setToaster } = useTosterStore();

	const { refetch: generateEndUserOtp } = UseEndUserOtpGenerateService({
		primary_number: primaryNumber,
	});

	const sendOtp = useCallback(() => {
		if (primaryNumber && generateEndUserOtp) {
			generateEndUserOtp()
				.then((response) => {
					if (response?.error) setToaster(true, ToastType.error, `Error sending OTP for Number: ${primaryNumber}`);
					if (response?.data) setToaster(true, ToastType.success, 'OTP sent successfully!');
				})
				.catch(() => {
					setToaster(true, ToastType.error, `Error sending OTP for Number: ${primaryNumber}`);
				});
		} else {
			setToaster(true, ToastType.error, `Invalid Number: ${primaryNumber}`);
		}
	}, [primaryNumber, generateEndUserOtp, setToaster]);

	const handleModalClose = useCallback(() => {
		setOpenOtpModal(false);
		setConfirmationModal(true);
	}, []);

	const handleConfirmationModalClose = useCallback(() => {
		overlayRemoveFadeAction(() => {
			setConfirmationModal(false);
			setOpenOtpModal(true);
			setConfirmClick(true);
		});
	}, []);

	const handleConfirmSubmit = useCallback(() => {
		handleConfirmationModalClose();
		setOpenOtpModal(false);
		setConfirmClick(false);
	}, [handleConfirmationModalClose]);

	useEffect(() => {
		if (openOtpModal && !confirmClick) {
			sendOtp();
		}
	}, [confirmClick, openOtpModal]);

	return (
		<>
			{openOtpModal && (
				<Modal
					modalId="primaeyOtpModal"
					className="dialog-sm site-lead-otp-code-modal dialog-bottom"
					modalOpen={openOtpModal}
					isEscAllow={false}
					onModalClose={handleModalClose}
					modalTitle={'Validate OTP'}
				>
					<EndUserOtpModal
						onClose={() => {
							setConfirmationModal(true);
							setOpenOtpModal(false);
						}}
						primaryNumber={primaryNumber}
						setVerifyDetails={() => setVerifyDetails(true)}
						sendOtp={sendOtp}
						onSubmit={() => {
							setOpenOtpModal(false);
							handleConfirmSubmit();
						}}
					/>
				</Modal>
			)}
			{confirmationModal && (
				<Modal
					modalId="confirmationModal"
					className="dialog-sm site-lead-confrim"
					modalOpen={confirmationModal}
					isEscAllow={false}
					onModalClose={handleConfirmationModalClose}
				>
					<ConfirmationModal
						onClose={handleConfirmationModalClose}
						onSubmit={handleConfirmSubmit}
						message="Are you sure you do not want to verify this phone number?"
						buttonLabel={'Confirm'}
					/>
				</Modal>
			)}
		</>
	);
};
