import { overlayRemoveFadeAction } from '@components/common/sort';
import { create } from 'zustand';

interface IBDEOnLeaveStore {
	isModalOpen: boolean;
	setModalClose: () => void;
	setModalOpen: () => void;
}

const initialState = {
	isModalOpen: false,
};

export const useBDEOnLeaveStore = create<IBDEOnLeaveStore>((set) => ({
	...initialState,
	setModalClose: (): void =>
		set((state) => {
			overlayRemoveFadeAction(() => {});
			return { ...state, isModalOpen: false };
		}),
	setModalOpen: (): void => set((state) => ({ ...state, isModalOpen: true })),
}));
