import { ReactElement, useMemo } from 'react';
import { CompetitionBrands, ICompetitionDetailsProps, TCompetitionDetails } from '.';
import { ActionButton, Dropdown, TextInput } from '@components/common';
import { useAddRemoveAction } from '@hooks/useAddRemoveAction';
import { keyDownEvent } from '@helper/utils';
import { brandUsedList } from '@config/constant';
import { DropdownOptions } from '@components/common/formControl/dropdown';
import { useEndUserProfileStore } from '@store/endUserProfileStore';

export const CompetitionDetailList = (props: ICompetitionDetailsProps): ReactElement => {
	const { formik, competitionListIndex, fieldName, isEditing, competition } = props;
	const addNewDeatils = {
		id: formik.values[fieldName].length,
		competition_brands: null,
		competition_volume: null,
	};
	const { handleAddDetails, handleRemoveDetails } = useAddRemoveAction({
		formik,
		addNewDeatils,
		fieldName,
	});
	const {
		moreDetailsRevalidate,
		setSubKeyBaseValue: setEndUserKeyBaseValue,
		moreDetailFormRevalidateField,
		setSubKeyValue,
	} = useEndUserProfileStore();

	const filteredCompetitionBrands = useMemo(() => {
		const selectedBrands = formik.values[fieldName].map((detail: TCompetitionDetails) => detail.competition_brands);
		return brandUsedList.filter((brand: CompetitionBrands) => !selectedBrands.includes(brand.value));
	}, [fieldName, formik.values]);

	const handleChange = (e: DropdownOptions): void => {
		if (!e) return;
		const data = e.value === 'NA' ? 0 : competition?.competition_volume?.toString();
		formik.setFieldValue(`${fieldName}[${competitionListIndex}].competition_volume`, data);
		if (moreDetailsRevalidate) {
			setEndUserKeyBaseValue('moreDetailFormRevalidateField', 'competition_brand', competitionListIndex, true);
			if (data === 0) {
				setEndUserKeyBaseValue('moreDetailFormRevalidateField', 'competition_volume', competitionListIndex, true);
			}
		}
	};

	const handleAddRemoveRevalidateField = (childIndex: number | undefined, remove = true): void => {
		const setValue = (name: boolean[], number: boolean[]): void => {
			setSubKeyValue('moreDetailFormRevalidateField', 'competition_brand', name);
			setSubKeyValue('moreDetailFormRevalidateField', 'competition_volume', number);
		};
		if (!moreDetailsRevalidate) return;
		const competition_brand: boolean[] = [...moreDetailFormRevalidateField.competition_brand];
		const competition_volume: boolean[] = [...moreDetailFormRevalidateField.competition_volume];
		if (childIndex && remove) {
			competition_brand.splice(childIndex, 1);
			competition_volume.splice(childIndex, 1);
			setValue(competition_brand, competition_volume);
			return;
		}
		competition_brand.push(true);
		competition_volume.push(true);
		setValue(competition_brand, competition_volume);
	};

	return (
		<li className="d-col">
			<div className="multi-input-box">
				<div className="inputs-wrap">
					<div>
						<Dropdown
							id={`competition_brands_${competitionListIndex}`}
							name={`${fieldName}[${competitionListIndex}].competition_brands`}
							label="Competition Brands Used"
							title={competition?.competition_brands ?? ''}
							onBlur={formik.handleBlur}
							options={filteredCompetitionBrands}
							setFieldValue={formik.setFieldValue}
							error={
								formik.touched[fieldName] &&
								formik.touched[fieldName][competitionListIndex]?.competition_brands &&
								formik.errors[fieldName]
									? formik.errors[fieldName][competitionListIndex]?.competition_brands
									: null
							}
							disabled={isEditing}
							required
							onChange={handleChange}
							revalidate={
								moreDetailsRevalidate && !moreDetailFormRevalidateField.competition_brand[competitionListIndex]
							}
							revalidateError={'Please revalidate competition brand'}
						/>
					</div>

					<div>
						<div className="form-control">
							<TextInput
								type="text"
								id={`competition_volume_${competitionListIndex}`}
								name={`${fieldName}[${competitionListIndex}].competition_volume`}
								onBlur={formik.handleBlur}
								value={competition?.competition_volume ?? ''}
								setFieldValue={formik.setFieldValue}
								label="Competition Avg Monthly Volume"
								error={
									formik.touched[fieldName] &&
									formik.touched[fieldName][competitionListIndex]?.competition_volume &&
									formik.errors[fieldName]
										? formik.errors[fieldName][competitionListIndex]?.competition_volume
										: null
								}
								onKeyDown={keyDownEvent}
								disabled={isEditing || competition?.competition_brands === 'NA'}
								required
								onChange={() => {
									setEndUserKeyBaseValue(
										'moreDetailFormRevalidateField',
										'competition_volume',
										competitionListIndex,
										true
									);
								}}
								revalidate={
									moreDetailsRevalidate &&
									!moreDetailFormRevalidateField.competition_volume[competitionListIndex] &&
									formik.values[fieldName][competitionListIndex].competition_brands !== 'NA'
								}
								revalidateError={'Please revalidate competition avg monthly volume'}
							/>
						</div>
					</div>
				</div>
				<div className="add-multi-input">
					{formik.values[fieldName].length > 1 && competitionListIndex !== 0 && (
						<ActionButton
							label="Remove"
							handlerFn={(e: React.MouseEvent<HTMLAnchorElement, MouseEvent>, childIndex: number | undefined) => {
								handleRemoveDetails(e, childIndex);
								handleAddRemoveRevalidateField(childIndex);
							}}
							className="remove-btn"
							childIndex={competitionListIndex}
							disabled={isEditing}
							action="remove"
						/>
					)}
					{competitionListIndex === formik.values[fieldName].length - 1 && formik.values[fieldName].length < 3 && (
						<ActionButton
							label="Add More"
							handlerFn={(e: React.MouseEvent<HTMLAnchorElement, MouseEvent>, childIndex: number | undefined) => {
								handleAddDetails(e);
								handleAddRemoveRevalidateField(childIndex, false);
							}}
							disabled={isEditing || competition?.competition_brands === 'NA'}
							action="add"
						/>
					)}
				</div>
			</div>
			<p className="error-message">Please Enter the valid field</p>
		</li>
	);
};
