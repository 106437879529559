import { Value } from '@components/common/date-picker';
import { Townlist } from '@components/common/formControl/address-fields';
import { get18YearsAgo } from '@helper/utils';
import { BasicDetails } from '@services/hooks/enduser-details';
import { FormikValues } from 'formik';
import {
	NumberNullUndefinedUnionType,
	NumberNullUnionType,
	StringNullUndefinedUnionType,
} from '@helper/commonTypesAlias';

export interface IBasicDetailsInitialValueProps {
	first_name: StringNullUndefinedUnionType;
	middle_name: StringNullUndefinedUnionType;
	last_name: StringNullUndefinedUnionType;
	sites_worked: StringNullUndefinedUnionType;
	sites_competition: StringNullUndefinedUnionType;
	calculated_glue: NumberNullUnionType;
	permanent_address: StringNullUndefinedUnionType;
	primary_number: StringNullUndefinedUnionType;
	secondary_number: StringNullUndefinedUnionType;
	average_glue: NumberNullUnionType;
	permanent_town: StringNullUndefinedUnionType;
	permanent_state: StringNullUndefinedUnionType;
	religion: StringNullUndefinedUnionType;
	language: StringNullUndefinedUnionType;
	permanent_zipcode: NumberNullUndefinedUnionType;
	local_zipcode: StringNullUndefinedUnionType;
	permanent_street: StringNullUndefinedUnionType;
	local_town: StringNullUndefinedUnionType;
	local_street: StringNullUndefinedUnionType;
	permanent_city: StringNullUndefinedUnionType;
	local_city: StringNullUndefinedUnionType;
	local_state: StringNullUndefinedUnionType;
	local_address: StringNullUndefinedUnionType;
	work_town: StringNullUndefinedUnionType;
	work_address: StringNullUndefinedUnionType;
	work_zipcode: NumberNullUndefinedUnionType;
	work_street: StringNullUndefinedUnionType;
	work_city: StringNullUndefinedUnionType;
	work_state: StringNullUndefinedUnionType;
	local_address_chekbox: boolean;
	work_address_chekbox: boolean;
	unmarried_chekbox: boolean;
	applicable_children_chekbox: boolean;
	hobbies: string[];
	birth_date: Date;
	wedding_anniversary: Date | string | null;
	children: Child[];
	samaj: StringNullUndefinedUnionType;
	samaj_checkbox: boolean;
	email: StringNullUndefinedUnionType;
	competitionConsumption: NumberNullUnionType;
	avg_monthly_banking_six_months?: NumberNullUnionType;
}

export const defaultBasicDetailFormInitialValues: IBasicDetailsInitialValueProps = {
	first_name: '',
	middle_name: '',
	last_name: '',
	sites_worked: '',
	sites_competition: '',
	calculated_glue: null,
	primary_number: '',
	secondary_number: '',
	average_glue: null,
	religion: '',
	language: '',
	permanent_town: '',
	permanent_state: '',
	permanent_address: '',
	permanent_zipcode: null,
	permanent_city: '',
	permanent_street: '',
	local_town: '',
	local_address: '',
	local_zipcode: '',
	local_street: '',
	local_city: '',
	local_state: '',
	work_town: '',
	work_zipcode: null,
	work_street: '',
	work_city: '',
	work_state: '',
	work_address: '',
	local_address_chekbox: false,
	work_address_chekbox: false,
	unmarried_chekbox: false,
	applicable_children_chekbox: false,
	hobbies: [],
	birth_date: get18YearsAgo(),
	wedding_anniversary: new Date(),
	children: [{ id: 0, child_gender: '', child_birthday: new Date() }],
	samaj: '',
	samaj_checkbox: false,
	email: '',
	competitionConsumption: null,
	avg_monthly_banking_six_months: null,
};

export type Child = { id: number; child_gender: string; child_birthday: Date | string };

export type TFormik = {
	values: FormikValues;
	setFieldValue: (field: string, value: Value | string | string[] | boolean | number, shouldValidate?: boolean) => void;
	touched: FormikValues;
	errors: FormikValues;
	handleBlur?: (e: React.FocusEvent<HTMLInputElement>) => void;
	handleChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
	setFieldError?: (field: string, value: string | undefined) => void;
	setFieldTouched?: (field: string, isTouched?: boolean, shouldValidate?: boolean) => void;
};

export interface IChildFields {
	formik: TFormik;
	childIndex: number;
	child: Child;
	fieldName: string;
	isEditing: boolean;
}

export type TownInfo = {
	towncode: StringNullUndefinedUnionType;
	townname: StringNullUndefinedUnionType;
};

export interface ChildDetail {
	id: number;
	child_gender: string;
	child_birthday: string;
}

export interface AdditionEndUser {
	child1_dob_c: string | Date | null;
	child1_gender_c: string;
	child2_dob_c: string | Date | null;
	child2_gender_c: string;
	child3_dob_c: string | Date | null;
	child3_gender_c: string;
	hobbies_c: string;
}

export interface BasicDetailsCommonFormSectionProps {
	formik: TFormik;
	isEditing: boolean;
	isFormEdit?: boolean;
	getBasicFormData?: BasicDetails;
	formId: NumberNullUnionType;
	townsList?: Townlist[];
}
