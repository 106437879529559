import { IQueryResponse } from '@services/api';
import { ISiteVisitOtpProps, ISiteVisitOtpResponse } from '@services/hooks/checksAndVerification/index';
import { ToastType, useTosterStore } from '@store/toster';
import { APIService } from '@services/api/common-services';
import { APICONFIG } from '@config/api.config';
import { ErrorHandler } from '@helper/errorHandler';
import { useQuery } from '@tanstack/react-query';

export const UseSiteVisitOtpGenerateService = (props: ISiteVisitOtpProps): IQueryResponse<ISiteVisitOtpResponse> => {
	const { membership_number, site_visit_id, phone_number } = props;
	const { setToaster } = useTosterStore();
	const fetchSiteVisitOtp = async (): Promise<ISiteVisitOtpResponse> => {
		try {
			return await APIService.postData<ISiteVisitOtpResponse>(APICONFIG.GET_SITE_VISIT_OTP, {
				membership_number: membership_number,
				site_visit_id: site_visit_id,
				phone_number: phone_number,
			});
		} catch (error) {
			const errMessage = new Error(error as string);
			setToaster(true, ToastType.error, errMessage.message);
			ErrorHandler(error);
			throw error;
		}
	};

	const { data, error, isLoading, isPending, isFetching, refetch } = useQuery({
		queryKey: ['siteVisitOtp'],
		queryFn: fetchSiteVisitOtp,
		enabled: false,
		refetchOnWindowFocus: false,
		gcTime: 0,
	});

	return {
		data,
		error,
		isLoading,
		isPending,
		isFetching,
		refetch,
	};
};
