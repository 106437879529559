import { create } from 'zustand';
interface ISiderToggleMenuModal {
	isOpen: boolean;
	isFullPageLoader: boolean;
	refetchName: string | string[];
	setRefetchName: (state: string | string[]) => void;
	setModal: (state: boolean) => void;
	setFullPageLoader: (state: boolean) => void;
}
const initialState = {
	isOpen: false,
	isFullPageLoader: false,
	refetchName: 'all',
};

export const useSiderToggleMenu = create<ISiderToggleMenuModal>((set) => ({
	...initialState,
	setModal: (item: boolean): void => set((state) => ({ ...state, isOpen: item })),
	setFullPageLoader: (item: boolean): void => set((state) => ({ ...state, isFullPageLoader: item })),
	setRefetchName: (item: string | string[]): void =>
		set((state) => ({ ...state, refetchName: Array.isArray(item) ? [...item] : item })),
}));
