import { ReactElement, useCallback, useEffect } from 'react';
import { MeetingTitleInfo, MeetingVenueThemeDetails } from '@components/meeting-details/component';
import { MeetingTimeDetails } from './component/meetingTimeDetails';
import { NavLink, useLocation, useNavigate } from 'react-router-dom';
import { IMeetingDetails } from '.';
import { useGetMeetingQuery } from '@services/hooks/meetings/useGetMeetingQuery';
import { AddProductsFields } from '@components/meetingModule/components/productFields/addProductFileds';
import { useFormik } from 'formik';
import { defaultAddMeetingInitialValues } from '@components/meetingModule/components/addNewMeeting';
import CheckTick from '@assets/images/check-circle 1.png';
import { DataRow } from '@helper/utils';
import { useTownDetailsQuery } from '@services/hooks/enduser-details/useTownDetailsQuery';
import { useGetRxDb } from '@hooks/getRxdbData';
import { TownList } from '@services/hooks/enduser-details';
import { usePublishMeetingMutation } from '@services/hooks/meetings/usePublishMeetingMutation';
import { ToastType, useTosterStore } from '@store/toster';
import { Wrapper } from '@components/common/Wrapper/wrapper';
import { useMeetingsListQuery } from '@services/hooks/meetings/useMeetingsListQuery';
import { useNonAttendedMembersStore } from '@store/nonAttendedMembersStore';
import { useGetParticipantsQuery } from '@services/hooks/meetings/useGetParticipants';
import { useComponentLevelAccessCheck } from '../../authGuard/hooks/useComponentLevelAccessCheck';
import { ExportXlsxFile } from '@helper/exportXlsxFile';

export const MeetingDetails = (): ReactElement => {
	const { state } = useLocation();
	const { data, isFetching, error } = useGetMeetingQuery(state.id);
	const { refetch } = useMeetingsListQuery();
	const path = useLocation();
	const navigate = useNavigate();
	const commonProps: IMeetingDetails = {
		data: data,
		isFetching,
		error,
	};
	const { setNonAttendedMembers, nonAttendedMembers } = useNonAttendedMembersStore();
	const { mutate: publishMeet, isPending: isPublishPending } = usePublishMeetingMutation(state.id);
	const { setToaster } = useTosterStore();
	const { data: participants, isFetching: participantsFetching } = useGetParticipantsQuery({
		id: state?.id,
		enable: path.state.status === 'meeting-completed',
	});
	const checkMeetingDetails = useComponentLevelAccessCheck('scheduled-meeting-button');

	const formik = useFormik({
		initialValues: nonAttendedMembers || defaultAddMeetingInitialValues,
		onSubmit: () => {},
	});

	useEffect(() => {
		if (data && nonAttendedMembers === null) {
			formik.setValues(
				{
					name: data?.name ?? `FV24${data.id}`,
					meetingType: data?.meeting_type_c ?? '',
					mdi: data?.mdi_name_c ?? '',
					startdate: data?.startdate ? new Date(data.startdate).toISOString() : '',
					enddate: data?.enddate ? new Date(data.enddate).toISOString() : '',
					start_time_c: data?.start_time_c ?? '',
					end_time_c: data?.end_time_c ?? '',
					club: data?.club_name_c?.split(',').map((club, index) => ({
						value: club,
						id: data?.club_name_id_c?.split(',')[index] || '',
					})),
					meetingVenue: data?.meeting_venue_c ?? '',
					meetFormat: 'Physical',
					product1Discussed: data?.focus_product_1 ?? '',
					product2Discussed: data?.focus_product_2 ?? '',
					primaryFocusProduct: data?.focus_product_4 ?? '',
					secondaryFocusProduct: data?.focus_product_5 ?? '',
					participants: data?.members,
					contact: data?.mobileno_c ? parseInt(data.mobileno_c) : '',
					meetingTheme: data?.meeting_theme_c ?? '',
					town: data?.town_city_c ?? '',
					status: data?.status ?? '',
					fccClub: data.club_name_c,
					imr_code: [],
				},
				true
			);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [data]);

	useTownDetailsQuery();
	const { result: towns } = useGetRxDb<TownList>('towns');

	useEffect(() => {
		if (towns?.length && data?.town_venue_c) {
			const matchedTown = towns.find((t) => t.towncode_c === data.town_venue_c);
			if (matchedTown) {
				formik.setFieldValue('town', matchedTown.towndescription_c);
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [towns, data?.town_venue_c, formik.setFieldValue]);

	const handlePublishMeeting = useCallback(() => {
		const currentDateTime = new Date();
		const meetingDate = new Date(data?.startdate ?? new Date());
		const [hours, minutes, seconds] = (data?.start_time_c ?? '00:00:00').split(':').map(Number);
		meetingDate.setHours(hours, minutes, seconds);
		if (meetingDate > currentDateTime) {
			publishMeet(undefined, {
				onSuccess: () => {
					setToaster(true, ToastType.success, 'Meeting Published Successfully');
					refetch?.();
					navigate('/meetings/todays-meetings');
				},
				onError: (error) => {
					setToaster(true, ToastType.error, error.message);
				},
			});
		} else {
			setToaster(true, ToastType.error, `Meeting Date Or Time should be future from today's date or current time`);
		}
	}, [data?.startdate, data?.start_time_c, navigate, publishMeet, refetch, setToaster]);

	const handleEditClick = useCallback((): void => {
		navigate('/edit-meeting', { state: { id: state.id } });
	}, [navigate, state.id]);

	const handleAttendanceDetails = async (): Promise<void> => {
		if (participants && participants.length > 0 && participants.filter((meet) => meet?.attendance_c).length > 0) {
			const membersData: DataRow[] = participants
				.filter((meet) => meet?.attendance_c)
				.map((item) => ({
					name: `${(item?.firstname ?? '') + ' ' + (item?.lastname ?? '')}`,
					phoneNumber: item?.phone ?? '',
					contactsfid: item.contactsfid,
					clubName: item?.clubname ?? '',
					attendance: item.attendance_c ?? '',
					contactId: item.contactid ?? '',
					repeatedAttendence: item.is_repeat_attendee_c ?? '',
					IsConverted: item.isconverted_within_5_days_c ?? '',
					meetingDate: data?.startdate ?? '',
					MeetingName: data?.name ?? '',
				}));
			ExportXlsxFile(membersData, commonProps?.data?.name);
		} else {
			setToaster(true, ToastType.error, 'No participant data available');
		}
	};

	return (
		<section className="meeting-details-section">
			<div className="container">
				<Wrapper isLoaderVisible={isFetching} isError={error}>
					<>
						{data?.status === 'Completed Meeting' && (
							<div className="congratulations-wrapper">
								<div className="check-image">
									<img src={CheckTick} alt="check" title="check" width="48" height="48" />
								</div>

								<span className="h1">Congratulations</span>
								<p>You have successfully completed a meeting.</p>
							</div>
						)}

						<MeetingTitleInfo {...commonProps} />

						<MeetingTimeDetails {...commonProps} />

						<MeetingVenueThemeDetails {...commonProps} />
						{checkMeetingDetails && path.state.status === 'meeting-completed' && (
							<div className={`submit-btn multi-submit-wrapper `}>
								<button
									type="button"
									className="btn btn-medium btn-primary"
									id="next-page"
									onClick={handleAttendanceDetails}
									disabled={isFetching || participantsFetching}
								>
									Attended Members Details
								</button>
							</div>
						)}

						{path.state.status !== 'meeting-completed' && (
							<>
								<form onSubmit={formik.handleSubmit}>
									<ul className="publish-meeting-form row">
										<AddProductsFields
											formik={formik}
											primaryFocusProduct={'primaryFocusProduct'}
											secondaryFocusProduct={'secondaryFocusProduct'}
											product1Discussed={'product1Discussed'}
											product2Discussed={'product2Discussed'}
											isEditing={true}
										/>

										<li className="d-col d-col-2">
											<div className={`manage-participants-button  disable-btn`}>
												<div className="label">
													<span className="required content">Manage Participants</span>
													{formik.values.participants.length !== 0 && (
														<span className="count">{formik.values.participants.length}</span>
													)}
												</div>

												<NavLink
													to={'/meeting-details/manage-participants'}
													aria-label="add participants"
													className="add-user"
													onClick={() => {
														setNonAttendedMembers(formik.values);
													}}
												>
													<span className="icon-add-user"></span>
													<span className="icon-right-arrow"></span>
												</NavLink>
											</div>
										</li>
									</ul>
								</form>

								{checkMeetingDetails && (
									<div className={`submit-btn multi-submit-wrapper `}>
										{/* <p className="note-content">
										Note: Please add meeting participants before
										{data?.enddate ? getDateByRange(new Date(data?.enddate), 5) : new Date().toLocaleDateString()}
									</p> */}
										{data?.name === null && (
											<p className="note-content">Note: You can publish meeting after the 1 minute of creation time.</p>
										)}

										<div className="multi-inner-wrapper">
											<button
												type="button"
												className={`btn btn-medium btn-primary `}
												id="next-page"
												onClick={handlePublishMeeting}
												disabled={isFetching || isPublishPending || data?.name === null}
											>
												Publish Meeting
											</button>
											<button
												type="button"
												className={`btn btn-medium btn-primary btn-primary-2 `}
												disabled={isFetching || isPublishPending}
												id="next-page"
												onClick={handleEditClick}
											>
												Edit Meeting
											</button>
										</div>
									</div>
								)}
							</>
						)}
					</>
				</Wrapper>
			</div>
		</section>
	);
};
