import { ReactElement } from 'react';
import { ToastType, useTosterStore } from '@store/toster';
import { useSiderToggleMenu } from '@store/useSiderToggleMenu';
import { cleanupDatabase } from '@rxdb/config/databaseConfig';
import { useLogoutMutation } from '@services/hooks/login';
import Cookies from 'universal-cookie';
import { clearAllCookies } from '@helper/cookiesClear';
import { UseChecksBDEOnLeaveService } from '@services/hooks/checksAndVerification';
import { useComponentLevelAccessCheck } from '../../../authGuard/hooks/useComponentLevelAccessCheck';
import { Modal } from '@components/common/modal/modal';
import { BDEOnLeaveModal } from '@components/checksAndVerification';
import { useBDEOnLeaveStore } from '@store/bdeOnLeaveStore';

export const LogoutButton = (): ReactElement => {
	const { setToaster } = useTosterStore();
	const { setFullPageLoader } = useSiderToggleMenu();
	const { isModalOpen, setModalClose } = useBDEOnLeaveStore();
	const { mutate: logout } = useLogoutMutation();
	const permissionAttendanceCheck = useComponentLevelAccessCheck('attendance-check');
	const handleClearDatabase = async (): Promise<void> => {
		setFullPageLoader(true);
		try {
			await cleanupDatabase();

			localStorage.clear();
			sessionStorage.clear();
			clearAllCookies();

			const clearCacheData = (): void => {
				caches.keys().then((names) => {
					names.forEach((name) => {
						caches.delete(name);
					});
				});
			};
			clearCacheData();

			setTimeout(() => {
				clearAllCookies();
				if (isModalOpen) setModalClose();
				window.location.href = process.env.REACT_APP_FV_REDIRECTION_URL ?? '/login';
			}, 50);
		} catch {
			setToaster(true, ToastType.error, 'Error during logout');
		} finally {
			setFullPageLoader(false);
		}
	};

	const handleLogout = async (): Promise<void> => {
		try {
			setFullPageLoader(true);
			const cookie = new Cookies();
			const token = localStorage.getItem('token') ?? sessionStorage.getItem('token') ?? cookie.get('token');
			if (!token) await handleClearDatabase();

			token &&
				logout(token, {
					onSuccess: () => {
						localStorage.clear();
						sessionStorage.clear();
						clearAllCookies();
						handleClearDatabase();
					},
					onError: (error) => {
						setToaster(true, ToastType.error, `Error during logout please try again. ${error}`);
					},
				});
		} catch {
			setFullPageLoader(false);
			setToaster(true, ToastType.error, 'Error during logout');
		}
	};

	UseChecksBDEOnLeaveService({
		apiEnable: permissionAttendanceCheck,
	});

	return (
		<>
			<div className="logout-btn">
				<button
					type="button"
					aria-label="logout-button"
					className="btn btn-primary navbar-logout-btn"
					value="logout-button"
					onClick={handleLogout}
				>
					Logout <span className="icon-logout" />
				</button>
			</div>
			{isModalOpen && (
				<Modal modalId="bdeOnLeave" className="dialog-lg dialog-sm" isEscAllow={false} modalOpen={isModalOpen}>
					<BDEOnLeaveModal
						handleModalClose={() => {
							handleLogout().then();
						}}
					/>
				</Modal>
			)}
		</>
	);
};
