import { RxCollectionCreator } from 'rxdb';

export const SupervisorSchema: RxCollectionCreator = {
	schema: {
		title: 'Supervisor List',
		description: 'Supervisor Schema',
		version: 2,
		primaryKey: 'id',
		type: 'object',
		properties: {
			id: { type: 'string', maxLength: 250 },
			sfid: { type: 'string' },
			firstname: { type: ['string', 'null'] },
			middlename: { type: ['string', 'null'] },
			lastname: { type: ['string', 'null'] },
			assigned_cmdi: { type: ['string', 'null'] },
			mdicode: { type: ['string', 'null'] },
			divisioncode_c: { type: ['string', 'null'] },
			salesgroupcode_c: { type: ['string', 'null'] },
			recordtypeid: { type: ['string', 'null'] },
			recordtypename: { type: ['string', 'null'] },
			phone: { type: ['string'] },
			membershipno: { type: ['string'] },
			AlternateMobileNo1: { type: ['string', 'null'] },
			primary_contractor: { type: ['string', 'null'] },
			primary_contractor_name: { type: ['string', 'null'] },
			primary_contractor_mobile_no: { type: ['string', 'null'] },
			createddate: { type: ['string', 'null'] },
			totalbankingQTD: { type: ['string', 'null'] },
			pwg_banking_qtd: { type: ['string', 'null'] },
			banking_ytd_kgs: { type: ['string', 'null'] },
			endusercategory_c: { type: ['string', 'null'] },
			fullName: { type: ['string', 'null'] },
		},
		required: ['id'],
	},
	migrationStrategies: {
		1: async (oldDoc) => {
			return {
				...oldDoc,
			};
		},
		2: async (oldDoc) => {
			return {
				...oldDoc,
			};
		},
	},
};
