import { ReactElement } from 'react';
import { NavLink } from 'react-router-dom';

export type IBankingDetailsCardProps = {
	redirectTo: string;
	title: string;
	value?: number;
	onBankingCardClick?: () => void;
};
export const BankingDetailsCard = (props: IBankingDetailsCardProps): ReactElement => {
	const { redirectTo, value, title } = props;

	const handleClick = (): void => {
		if (props.onBankingCardClick) {
			props.onBankingCardClick();
		}
	};

	return (
		<li>
			<NavLink to={redirectTo} aria-label={title} onClick={handleClick}>
				<fieldset>
					<legend title={title}>{title}</legend>

					<div className="about-details">
						<p className="user-details-number h1">{value || 0}</p>
						<span className="icon-right-arrow" />
					</div>
				</fieldset>
			</NavLink>
		</li>
	);
};
