import { useEffect } from 'react';
import { useEndUserProfileStore } from '@store/endUserProfileStore';
import { useEndUserProfileService } from '@services/hooks/endUserProfile/useEndUserProfleService';
import { useEndUserStore } from '@store/enduser';

export const useCheckEndUserProfileFields = (): void => {
	const { formId, formRevalidate } = useEndUserStore();
	const { data: endUserProfileData } = useEndUserProfileService(formId, formRevalidate);
	const { setKeyBaseValue: setEndUserKeyBaseValue } = useEndUserProfileStore();

	useEffect(() => {
		setEndUserKeyBaseValue('basicDetailsRevalidate', false);
		setEndUserKeyBaseValue('moreDetailsRevalidate', false);
		if (endUserProfileData && formRevalidate) {
			setEndUserKeyBaseValue('basicDetailsRevalidate', endUserProfileData?.data?.basic);
			setEndUserKeyBaseValue('moreDetailsRevalidate', endUserProfileData?.data?.more);
		}
	}, [endUserProfileData, setEndUserKeyBaseValue, formRevalidate]);
};
