export * from './subContractorList';
export * from './subContractorMapping';
export interface ISubContractorListProps {
	membershipno_c?: number;
	isEditing: boolean;
	isFormEdit: boolean;
	monthlyGluePotential: number | null;
	setFormikValue?: () => void;
}

export const ISSubContractorViewMode = true;
export const ISSubcontractorMemberListView = true;
