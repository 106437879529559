export interface IUrlsPaths {
	currentPath: string;
	previousPath: string;
	state?: unknown;
}

export const presetRoutePath: IUrlsPaths[] = [
	{
		currentPath: '/edit-member-details/basic-details',
		previousPath: '/contractor',
		state: '',
	},
	{
		currentPath: '/edit-member-details/kyc-details',
		previousPath: '/contractor',
		state: '',
	},
	{
		currentPath: '/edit-member-details/more-details',
		previousPath: '/contractor',
		state: '',
	},
	{
		currentPath: '/meeting-details',
		previousPath: '/meetings',
		state: '',
	},
	{
		currentPath: '/edit-meeting',
		previousPath: '/meeting-details',
		state: '',
	},
	{
		currentPath: '/contractor/qr-code-scanner',
		previousPath: '/contractor',
		state: '',
	},
	{
		currentPath: '/contractor/qr-code-check',
		previousPath: '/contractor',
		state: '',
	},
	{
		currentPath: '/contractor/points-statement',
		previousPath: '/contractor',
		state: '',
	},
	{
		currentPath: '/contractor/supervisor-list',
		previousPath: '/contractor',
		state: '',
	},
	{
		currentPath: '/meetings-conducted',
		previousPath: '/meetings/all-meetings',
		state: '',
	},
	{
		currentPath: '/meetings-conducted/expense-details',
		previousPath: '/meetings-conducted',
		state: '',
	},
	{
		currentPath: '/meetings-conducted/completed-record',
		previousPath: '/meetings-conducted',
		state: '',
	},
	{
		currentPath: '/edit-one-to-one-connect',
		previousPath: '/one-to-one-meeting-summary',
		state: '',
	},
	{
		currentPath: '/add-one-to-one-meeting',
		previousPath: '/one-to-one-meeting-summary',
		state: '',
	},
];

export const meetingPaths: string[] = [
	'/meetings',
	'/meetings/todays-meetings',
	'/meetings/all-meetings',
	'/dealer-visit-summary',
	'/pcms',
	'/competition-conversion/type-of-contractor',
	'/competition-conversion',
	'/competition-conversion/grade-of-competition',
	'/competition-conversion/products-recommended',
	'/one-to-one-meeting-summary',
];
