export interface IProductUsageData {
	product_code: string;
	product_name: string;
	purchased_in_qtd: number;
	percentage: number;
}

export interface IProductUsage {
	productUsageData?: IProductUsageData[];
}

export const productUsage = [
	{
		id: 1,
		classname: '',
	},
	{
		id: 2,
		classname: 'dark',
	},
	{
		id: 3,
		classname: 'strong',
	},
	{
		id: 4,
		classname: 'medium',
	},
	{
		id: 5,
		classname: 'light',
	},
];
