import { APICONFIG } from '@config/api.config';
import { APIService } from '@services/api/common-services';
import { useQuery } from '@tanstack/react-query';

export type MeetingsData = {
	id: number;
	name: string;
	meeting_venue_c: string;
	start_time_c: string;
	status: string;
	sfid: string;
};

type MeetingQuery = {
	data: MeetingsData[];
	error: Error | null;
	isLoading: boolean;
	isPending: boolean;
};

export const useMeetingsQuery = (): MeetingQuery => {
	// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
	const fetchMeetingsData = async () => {
		const response = await APIService.getData(APICONFIG.MEETNG_API);
		// eslint-disable-next-line @typescript-eslint/ban-ts-comment
		// @ts-expect-error
		return response.data;
	};

	const { data, error, isLoading, isPending } = useQuery({
		queryKey: ['meetings'],
		queryFn: fetchMeetingsData,
		refetchOnWindowFocus: false,
	});

	return {
		data,
		error,
		isLoading,
		isPending,
	};
};
