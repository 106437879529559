import { ReactElement } from 'react';
import { NavLink } from 'react-router-dom';

export type ISummaryCardProps = {
	heading: string;
	value: number | undefined;
	summaryLeftIcon: string;
	summaryRightIcon?: string;
	urlPath: string;
	onCardClick?: () => void;
};
export const SummaryCard = (props: ISummaryCardProps): ReactElement => {
	const { urlPath, heading, summaryLeftIcon, value, summaryRightIcon = 'icon-arrow-right-circle' } = props;
	const handleClick = (): void => {
		if (props.onCardClick) {
			props.onCardClick();
		}
	};
	return (
		<div className="summary-card-wrapper">
			<NavLink to={urlPath} aria-label="summary card link" onClick={handleClick}>
				<div className="summary-number-details">
					<span className={summaryLeftIcon} />
					<p className="summary-number h1">{value || 0}</p>
					<h2 className="h4">{heading}</h2>
				</div>

				<div className="summary-about-details">
					<h2 className="h4">{heading}</h2>
					<p className="summary-number h1">{value || 0}</p>
					<span className={summaryRightIcon} />
				</div>
			</NavLink>
		</div>
	);
};
