import { useRxData } from 'rxdb-hooks';
import { MangoQuery, RxCollection, RxDocument } from 'rxdb/dist/types/types';
import { useCallback } from 'react';
import { useRxCountQuery } from '@hooks/useCount';
import { ISupervisorDetail } from '@components/supervisorList';

export type ISupervisorReturn = {
	result: RxDocument<ISupervisorDetail>[];
	isFetching: boolean;
	fetchMore: () => void;
	totalCount: number;
};

export interface ISupervisorListFilterProps {
	searchValue?: string;
	sortBy?: string;
	createddate?: [null | Date, null | Date];
	contractor_mobile_no?: string[];
}

export const useSupervisorRxDb = (props: ISupervisorListFilterProps): ISupervisorReturn => {
	const { searchValue = '', sortBy, createddate, contractor_mobile_no } = props;

	const queryConstructor = useCallback(
		(collection: RxCollection<ISupervisorDetail>) => {
			let querySelector: MangoQuery<ISupervisorDetail> = {
				selector: {
					$or: [
						{
							firstname: { $regex: `.*${searchValue}.*`, $options: 'i' },
						},
						{
							lastname: { $regex: `.*${searchValue}.*`, $options: 'i' },
						},
						{
							phone: { $regex: `.*${searchValue}.*`, $options: 'i' },
						},
						{
							primary_contractor_name: { $regex: `.*${searchValue}.*`, $options: 'i' },
						},
						{
							primary_contractor_mobile_no: { $regex: `.*${searchValue}.*`, $options: 'i' },
						},
						{
							primary_contractor: { $regex: `.*${searchValue}.*`, $options: 'i' },
						},
						{
							fullName: { $regex: `.*${searchValue}.*`, $options: 'i' },
						},
						{
							membershipno: { $regex: `.*${searchValue}.*`, $options: 'i' },
						},
					],
				},
				sort: [{ createddate: 'desc' }],
			};

			if (querySelector.selector && contractor_mobile_no && contractor_mobile_no.length > 0) {
				querySelector.selector.primary_contractor_mobile_no = {
					$in: contractor_mobile_no,
				};
			}

			if (querySelector.selector && createddate) {
				const [startDate, endDate] = createddate;
				if (startDate && endDate) {
					querySelector.selector.createddate = {
						$gte: startDate.toISOString(),
						$lte: endDate.toISOString(),
					};
				}
			}

			if (sortBy) {
				querySelector = {
					...querySelector,
					sort: [{ [sortBy]: 'desc' }],
				};
			}

			return collection.find(querySelector);
		},
		[contractor_mobile_no, createddate, searchValue, sortBy]
	);
	const totalCount = useRxCountQuery<ISupervisorDetail>('supervisor_list', queryConstructor);

	const { result, isFetching, fetchMore } = useRxData<ISupervisorDetail>('supervisor_list', queryConstructor, {
		pageSize: 20,
		pagination: 'Infinite',
	});

	return {
		result,
		isFetching,
		fetchMore,
		totalCount,
	};
};
