import { DropdownOptions } from '@components/common/formControl/dropdown';
import { phoneRegExp } from '@config/constant';
import { convertTo24Hour, RoleType } from '@helper/utils';
import * as Yup from 'yup';
import { contractorNameValidation, dealerVisitProductsValidationSchema, meetingClubValidationSchema } from './utils';
import { SiteLeadConfigurations } from '@components/siteLeads';

type FeedbackResponseType = string | number | boolean | { [key: string]: string } | undefined;

export interface IValidationFields {
	primary_number: Yup.StringSchema<string | null | undefined>;
	secondary_number: Yup.StringSchema<string | null | undefined>;
	sites_worked: Yup.NumberSchema<number | null | undefined>;
	sites_competition: Yup.NumberSchema<number | null | undefined>;
	average_glue: Yup.NumberSchema<number | null | undefined>;
	competitionConsumption: Yup.NumberSchema<number | null | undefined>;
	calculated_glue: Yup.NumberSchema<number | null | undefined>;
	permanent_town: Yup.StringSchema<string | null | undefined>;
	permanent_address: Yup.StringSchema<string | null | undefined>;
	permanent_zipcode: Yup.NumberSchema<number | null | undefined>;
	permanent_street: Yup.StringSchema<string | null | undefined>;
	permanent_city: Yup.StringSchema<string | null | undefined>;
	permanent_state: Yup.StringSchema<string | null | undefined>;
	local_address: Yup.StringSchema<string | null | undefined>;
	local_zipcode: Yup.NumberSchema<number | null | undefined>;
	local_street: Yup.StringSchema<string | null | undefined>;
	local_city: Yup.StringSchema<string | null | undefined>;
	local_state: Yup.StringSchema<string | null | undefined>;
	work_address: Yup.StringSchema<string | null | undefined>;
	work_zipcode: Yup.NumberSchema<number | null | undefined>;
	work_street: Yup.StringSchema<string | null | undefined>;
	work_city: Yup.StringSchema<string | null | undefined>;
	work_state: Yup.StringSchema<string | null | undefined>;
	document: Yup.StringSchema<string | null | undefined>;
	document_number: Yup.StringSchema<string | null | undefined>;
	club: Yup.StringSchema<string | null | undefined>;
	average_glue_potential: Yup.NumberSchema<number | null | undefined>;
	workshop_zipcode: Yup.NumberSchema<number | null | undefined>;
	workshop_address: Yup.StringSchema<string | null | undefined>;
	workshop_city: Yup.StringSchema<string | null | undefined>;
	workshop_street: Yup.StringSchema<string | null | undefined>;
	workshop_state: Yup.StringSchema<string | null | undefined>;
	factory_zipcode: Yup.NumberSchema<number | null | undefined>;
	factory_address: Yup.StringSchema<string | null | undefined>;
	factory_city: Yup.StringSchema<string | null | undefined>;
	factory_state: Yup.StringSchema<string | null | undefined>;
	siteName: Yup.StringSchema<string | null | undefined>;
	siteLocation: Yup.StringSchema<string | null | undefined>;
	gradeUsed: Yup.StringSchema<string | null | undefined>;
	contractType: Yup.StringSchema<string | null | undefined>;
	competitionReason: Yup.StringSchema<string | null | undefined>;
	contractorName: Yup.StringSchema<string | null | undefined>;
	contractorNumber: Yup.StringSchema<string | null | undefined>;
	dealerName: Yup.StringSchema<string | null | undefined>;
	gluePotentialOfSite: Yup.StringSchema<string | null | undefined>;
	availableProducts: Yup.ArraySchema<
		{
			id: number;
			value: string;
		}[],
		Yup.AnyObject,
		'',
		''
	>;
	kyc_file_name: Yup.StringSchema<string | null | undefined>;
	name: Yup.StringSchema<string | null | undefined>;
	contact: Yup.StringSchema<string | null | undefined>;
	town: Yup.StringSchema<string | null | undefined>;
	meetingVenue: Yup.StringSchema<string | null | undefined>;
	meetingType: Yup.StringSchema<string | null | undefined>;
	mdi: Yup.StringSchema<string | null | undefined>;
	primaryFocusProduct: Yup.StringSchema<string | null | undefined>;
	productDiscussed: Yup.StringSchema<string | null | undefined>;
	meetFormat: Yup.StringSchema<string | null | undefined>;
	participants: Yup.StringSchema<string | null | undefined>;
	productHierarchyCode: Yup.StringSchema<string | null | undefined>;
	catagories: Yup.StringSchema<string | null | undefined>;
	designation: Yup.StringSchema<string | null | undefined>;
	natureOfWork: Yup.StringSchema<string | null | undefined>;
	userToBeLinked: Yup.StringSchema<string | null | undefined>;
	obstacleFaced: Yup.StringSchema<string | null | undefined>;
	learningForm: Yup.StringSchema<string | null | undefined>;
	meeting_expenses: Yup.StringSchema<string | null | undefined>;
	food: Yup.StringSchema<string | null | undefined>;
	venue: Yup.StringSchema<string | null | undefined>;
	gifts: Yup.StringSchema<string | null | undefined>;
	others: Yup.StringSchema<string | null | undefined>;
	sku_names_c: Yup.StringSchema<string | null | undefined>;
	ref_lot_no_c: Yup.StringSchema<string | null | undefined>;
	manufactured_in_c: Yup.StringSchema<string | null | undefined>;
	quantity_c: Yup.StringSchema<string | null | undefined>;
	customer_name_c: Yup.StringSchema<string | null | undefined>;
	registered_mobile_number_c: Yup.StringSchema<string | null | undefined>;
	type_of_complaint_c: Yup.StringSchema<string | null | undefined>;
	dealer_membership_code_c: Yup.StringSchema<string | null | undefined>;
	wss_names_c: Yup.StringSchema<string | null | undefined>;
	site_under_warranty_c: Yup.StringSchema<string | null | undefined>;
	what_is_the_warranty_certificate_number_c: Yup.StringSchema<string | null | undefined>;
	nature_of_complaints_c: Yup.StringSchema<string | null | undefined>;
	sub_category_defect_c: Yup.StringSchema<string | null | undefined>;
	description_c: Yup.StringSchema<string | null | undefined>;
	pictures_videos_c: Yup.ArraySchema<string[], Yup.AnyObject, undefined>;
	complaint_sample_availability_c: Yup.StringSchema<string | null | undefined>;
	sample_non_availability_reason: Yup.StringSchema<string | null | undefined>;
	sample_non_availability_reason_others: Yup.StringSchema<string | null | undefined>;

	applicator_name: Yup.StringSchema<string | null | undefined>;
	stage_of_application_during_complaint_c: Yup.StringSchema<string | null | undefined>;
	application_surface_c: Yup.StringSchema<string | null | undefined>;
	area_in_sqft_c: Yup.StringSchema<string | null | undefined>;
	observation_c: Yup.StringSchema<string | null | undefined>;
	glueUsedAtSite: Yup.StringSchema<string | null | undefined>;

	primary_details: Yup.ArraySchema<
		| {
				id?: number;
				dealer_code: string;
				dealer_name: string;
		  }[]
		| undefined,
		Yup.AnyObject,
		'',
		''
	>;
	competition_details: Yup.ArraySchema<
		| {
				id?: number;
				competition_brands: string;
				competition_volume: number;
		  }[]
		| undefined,
		Yup.AnyObject,
		'',
		''
	>;
	title: Yup.StringSchema<string | null | undefined>;
	meeting_venue_c: Yup.StringSchema<string | null | undefined>;
	startdate: Yup.DateSchema<Date | null | undefined>;
	enddate: Yup.DateSchema<Date | null | undefined>;
	start_time_c: Yup.StringSchema<string | null | undefined>;
	end_time_c: Yup.StringSchema<string | null | undefined>;
	slab: Yup.StringSchema<string | null | undefined>;
	productRSI: Yup.ArraySchema<
		| {
				id: number;
				value: string;
		  }[]
		| undefined,
		Yup.AnyObject,
		'',
		''
	>;
	activityDiscussed: Yup.ArraySchema<
		| {
				id: number;
				value: string;
		  }[]
		| undefined,
		Yup.AnyObject,
		'',
		''
	>;
	address: Yup.StringSchema<string | null | undefined>;
	contractorName1: Yup.StringSchema<string | null | undefined>;
	contractorName2: Yup.StringSchema<string | null | undefined>;
	contractorNumber1: Yup.StringSchema<string | null | undefined>;
	contractorNumber2: Yup.StringSchema<string | null | undefined>;
	architect_details: Yup.ArraySchema<
		| {
				number?: string | null;
				id?: number;
				name?: string | null;
				isActive?: boolean;
		  }[]
		| undefined,
		Yup.AnyObject,
		'',
		''
	>;
	influencer_details: Yup.ArraySchema<
		| {
				number?: string | null;
				id?: number;
				name?: string | null;
				isActive?: boolean;
		  }[]
		| undefined,
		Yup.AnyObject,
		'',
		''
	>;
	owner_details: Yup.ArraySchema<
		| {
				number?: string | null;
				id?: number;
				name?: string | null;
				isActive?: boolean;
		  }[]
		| undefined,
		Yup.AnyObject,
		'',
		''
	>;
	feedback: Yup.ArraySchema<
		| (
				| {
						feedbackResponse?: FeedbackResponseType;
				  }[]
				| undefined
		  )[]
		| undefined,
		Yup.AnyObject,
		'',
		''
	>;
	exception: Yup.ArraySchema<
		| {
				id?: number;
				productId?: number;
				feedbackQuestion?: string;
				feedbackOptions?: {
					id?: number;
					value?: string;
				}[];
				type?: string;
				placeHolder?: string;
				feedbackResponse: string;
		  }[]
		| undefined,
		Yup.AnyObject,
		'',
		''
	>;
	veneer: Yup.StringSchema<string | null | undefined>;
	isContractorAvailable: Yup.StringSchema<string | null | undefined>;
	substratesCombinations: Yup.ArraySchema<
		{
			id: number;
			value: string;
		}[],
		Yup.AnyObject,
		'',
		''
	>;
	meetingClub: Yup.ArraySchema<
		| {
				id: string;
				value: string;
		  }[]
		| undefined,
		Yup.AnyObject,
		'',
		''
	>;
	meetingDealer: Yup.StringSchema;
}

const substratesValidationSchema = Yup.array()
	.of(
		Yup.object().shape({
			id: Yup.number().required('ID is required'),
			value: Yup.string().required('Value is required'),
		})
	)
	.min(1, 'Are any of the below substrates combinations being used at site is required')
	.required('Are any of the below substrates combinations being used at site is required');

const availableProductsValidationSchema = Yup.array()
	.of(
		Yup.object().shape({
			id: Yup.number().required('ID is required'),
			value: Yup.string().required('Value is required'),
		})
	)
	.min(1, 'Product Available at Site is required')
	.required('Product Available at Site is required');

const pcmsProductImageValidationSchema = Yup.array()
	.min(1, 'At least one Image/Video is required')
	.required('Image/Video is required');
const dealerSchema = Yup.object().shape({
	id: Yup.number(),
	dealer_code: Yup.string().required('Dealer Code is required'),
	dealer_name: Yup.string().required('Dealer Name is required'),
});
const competitionSchema = Yup.object().shape({
	id: Yup.number(),
	competition_brands: Yup.string().required('Competition Brand is required'),
	competition_volume: Yup.number().required('Competition Volume is required'),
});

const accordianSchema = Yup.object().shape({
	id: Yup.number(),
	isActive: Yup.boolean(),
	name: Yup.string()
		.matches(/^(?!\s)(?=.*[a-zA-Z])[a-zA-Z\s.]+$/, 'Only English characters are allowed in the name')
		.nullable(),

	number: Yup.string().matches(phoneRegExp, 'Number is not valid').nullable(),
});

const useValidationFields = (): IValidationFields => {
	const primary_number = Yup.string()
		.matches(phoneRegExp, 'Phone number is not valid')
		.required('Primary Number is Required')
		.nullable();
	const secondary_number = Yup.string()
		.matches(phoneRegExp, 'Phone number is not valid')
		.test('not-same-as-primary', 'Secondary number should not be the same as Primary Number', function (value) {
			const primary = this.parent.primary_number;
			if (primary && value) {
				return primary !== value;
			}
			return true;
		})
		.nullable();

	const sites_worked = Yup.number()
		.required('How many sites have you worked on in the last 6 months is required')
		.nullable()
		.min(0, 'Value must be positive number');
	const sites_competition = Yup.number()
		.min(0, 'Value must be a positive number')
		.required('How many sites did you have to use competition is required')
		.nullable()
		.test('max-sites-comp', 'No of competition sites cannot be greater than total no of sites', function (value) {
			const { sites_worked } = this.parent;
			return value == null || sites_worked == null || value <= sites_worked;
		});
	const average_glue = Yup.number()
		.min(0, 'Value must be positive number')
		.required('Glue consumption per month is required');
	const competitionConsumption = Yup.number()
		.min(0, 'Value must be a positive number')
		.required('Monthly Competition Consumption is required')
		.nullable()
		.test('max-avg-glue', 'Monthly competition consumption cannot be greater than average glue', function (value) {
			const { average_glue } = this.parent;
			return value == null || sites_worked == null || value <= average_glue;
		});
	const calculated_glue = Yup.number().min(0, 'Value must be positive number').nullable();
	const permanent_town = Yup.string().required('Town is required').nullable();
	const permanent_address = Yup.string().required('Permanent Address required').nullable();
	const permanent_zipcode = Yup.number().required('Zip code is required');
	const permanent_street = Yup.string().required('Street required').nullable();
	const permanent_city = Yup.string().required('City required').nullable();
	const permanent_state = Yup.string().required('State required').nullable();

	const local_address = Yup.string().when('local_address_chekbox', {
		is: false,
		then: () => Yup.string().required('Local Address is required').nullable(),
		otherwise: () => Yup.string().nullable(),
	});

	const local_zipcode = Yup.number().when('local_address_chekbox', {
		is: false,
		then: () => Yup.number().required('Zip code is required').nullable(),
		otherwise: () => Yup.number().nullable(),
	});

	const local_street = Yup.string().when('local_address_chekbox', {
		is: false,
		then: () => Yup.string().required('Street is required').nullable(),
		otherwise: () => Yup.string().nullable(),
	});

	const local_city = Yup.string().when('local_address_chekbox', {
		is: false,
		then: () => Yup.string().required('City is required').nullable(),
		otherwise: () => Yup.string().nullable(),
	});

	const local_state = Yup.string().when('local_address_chekbox', {
		is: false,
		then: () => Yup.string().required('State is required').nullable(),
		otherwise: () => Yup.string().nullable(),
	});

	const work_address = Yup.string().when('work_address_chekbox', {
		is: false,
		then: () => Yup.string().required('Work Address is required').nullable(),
		otherwise: () => Yup.string().nullable(),
	});

	const work_zipcode = Yup.number().when('work_address_chekbox', {
		is: false,
		then: () => Yup.number().required('Zip code is required').nullable(),
		otherwise: () => Yup.number().nullable(),
	});

	const work_street = Yup.string().when('work_address_chekbox', {
		is: false,
		then: () => Yup.string().required('Street is required').nullable(),
		otherwise: () => Yup.string().nullable(),
	});

	const work_city = Yup.string().when('work_address_chekbox', {
		is: false,
		then: () => Yup.string().required('City is required').nullable(),
		otherwise: () => Yup.string().nullable(),
	});

	const work_state = Yup.string().when('work_address_chekbox', {
		is: false,
		then: () => Yup.string().required('State is required').nullable(),
		otherwise: () => Yup.string().nullable(),
	});
	const document = Yup.string().required('Document is required');
	const document_number = Yup.string().required('Document Number is Required');
	const club = Yup.string().required('Club is Required');
	const primary_details = Yup.array().of(dealerSchema);
	const competition_details = Yup.array().of(competitionSchema);
	const average_glue_potential = Yup.number().required('Avg monthly Glue Potential is Required');
	const workshop_address = Yup.string().when('workshop_checkbox', {
		is: true,
		then: () => Yup.string().required('Workshop Address is Required'),
		otherwise: () => Yup.string().nullable(),
	});
	const workshop_zipcode = Yup.number().when('workshop_checkbox', {
		is: true,
		then: () => Yup.number().required('Zipcode is Required'),
		otherwise: () => Yup.number().nullable(),
	});

	const workshop_city = Yup.string().when('workshop_checkbox', {
		is: true,
		then: () => Yup.string().required('City is Required'),
		otherwise: () => Yup.string().nullable(),
	});
	const workshop_street = Yup.string().when('workshop_checkbox', {
		is: true,
		then: () => Yup.string().required('Street is Required'),
		otherwise: () => Yup.string().nullable(),
	});
	const workshop_state = Yup.string().when('workshop_checkbox', {
		is: true,
		then: () => Yup.string().required('State is Required'),
		otherwise: () => Yup.string().nullable(),
	});
	const factory_address = Yup.string().when('factory_checkbox', {
		is: true,
		then: () => Yup.string().required('Factory Address is Required'),
		otherwise: () => Yup.string().nullable(),
	});
	const factory_zipcode = Yup.number().when('factory_checkbox', {
		is: true,
		then: () => Yup.number().required('Zipcode is Required'),
		otherwise: () => Yup.number().nullable(),
	});

	const factory_city = Yup.string().when('factory_checkbox', {
		is: true,
		then: () => Yup.string().required('City is Required'),
		otherwise: () => Yup.string().nullable(),
	});

	const factory_state = Yup.string().when('factory_checkbox', {
		is: true,
		then: () => Yup.string().required('State is Required'),
		otherwise: () => Yup.string().nullable(),
	});

	const title = Yup.string().required('Title is required').max(30, 'Title should no be more than 30 characters');
	const meeting_venue_c = Yup.string()
		.trim()
		.required('Venue is required')
		.max(50, 'Venue should no be more than 50 characters');
	const today = new Date();
	today.setHours(0, 0, 0, 0);

	const startdate = Yup.date()
		.required('Start date is required')
		.min(today, 'Start date must be today or in the future');

	const enddate = Yup.date()
		.required('End date is required')
		.test('is-valid-range', 'End date must be the same or later than start date', function (value) {
			const { startdate } = this.parent;
			if (startdate && value) {
				const start = new Date(startdate);
				const end = new Date(value);

				start.setHours(0, 0, 0, 0);
				end.setHours(0, 0, 0, 0);

				return end >= start;
			}
			return true;
		})
		.test('is-within-5-days', 'You can create meetings for up to 5 days only', function (value) {
			const { startdate } = this.parent;
			if (startdate && value) {
				const start = new Date(startdate);
				const end = new Date(value);

				start.setHours(0, 0, 0, 0);
				end.setHours(0, 0, 0, 0);

				const diffTime = end.getTime() - start.getTime();
				const diffDays = Math.floor(diffTime / (1000 * 60 * 60 * 24)) + 1;

				return diffDays <= 5;
			}
			return true;
		});
	const start_time_c = Yup.string().required('Start time is required');
	const end_time_c = Yup.string()
		.trim()
		.required('End time is required')
		.test('is-at-least-one-hour-later', 'End time must be at least one hour later than start time', function (value) {
			const { startdate, enddate, start_time_c } = this.parent;
			if (!startdate || !enddate || !start_time_c || !value) {
				return true;
			}
			const startDate = new Date(startdate);
			const endDate = new Date(enddate);
			const [startHour, startMinute] = convertTo24Hour(start_time_c).split(':').map(Number);
			const [endHour, endMinute] = convertTo24Hour(value).split(':').map(Number);
			startDate.setHours(startHour, startMinute, 0, 0);
			endDate.setHours(endHour, endMinute, 0, 0);

			return endDate.getTime() - startDate.getTime() >= 60 * 60 * 1000;
		});

	const siteName = Yup.string().required('Site Name is required');
	const glueUsedAtSite = Yup.string().required('Glue Used at Site is required');
	const siteLocation = Yup.string().required('Site Location is required');
	const gradeUsed = Yup.string().required('Grade & Brand of Glue used at Site is required');
	const contractType = Yup.string().required('Type of Contract is required');
	const contractorName = Yup.string().required('Contractor Name is required');
	const contractorNumber = Yup.string().required('Contractor Number is required');
	const competitionReason = Yup.string().when('glueUsedAtSite', {
		is: (val: string) => val === 'Competition',
		then: () => Yup.string().required('Reason For Competition is Required').nullable(),
		otherwise: () => Yup.string().nullable(),
	});
	const dealerName = Yup.string().required('Dealer Name is required');
	const gluePotentialOfSite = Yup.string().required('Glue Potential of Site is required');
	const availableProducts = availableProductsValidationSchema;
	const architect_details = Yup.array().of(accordianSchema);
	const influencer_details = Yup.array().of(accordianSchema);
	const owner_details = Yup.array().of(accordianSchema);
	const kyc_file_name = Yup.string().required();
	const feedback = Yup.array().of(
		Yup.array().of(
			Yup.object().shape({
				feedbackResponse: Yup.mixed()
					.test('conditional-required', 'This field is required', function (value) {
						const { type, validation } = this.parent;
						if (type === 'checkbox') {
							return true;
						}
						if (validation) {
							return !!value;
						}
						return true;
					})
					.test('valid-dropdown', 'Please select a valid option', function (value) {
						const { type, feedbackOptions } = this.parent;
						if (type === 'dropdown' && feedbackOptions?.length) {
							return feedbackOptions.some((option: DropdownOptions) => option.value === value);
						}
						return true;
					})
					.test('valid-number', 'Number is not valid', function (value) {
						const { type, validation } = this.parent;
						if (type === 'text' && validation !== undefined && validation === 'number' && typeof value === 'string') {
							return phoneRegExp.test(value);
						}
						return true;
					})
					.test('valid-text', 'Name is not valid', function (value) {
						const { type, validation } = this.parent;
						if (type === 'text' && validation === 'text' && typeof value === 'string') {
							return /^[A-Za-z\s]*$/.test(value);
						}
						return true;
					}),
			})
		)
	);
	const exception = Yup.array().of(
		Yup.object().shape({
			id: Yup.number(),
			productId: Yup.number(),
			feedbackQuestion: Yup.string(),
			feedbackOptions: Yup.array().of(
				Yup.object().shape({
					id: Yup.number(),
					value: Yup.string(),
				})
			),
			feedbackResponse: Yup.string()
				.required('Feedback is required')
				.test('valid-dropdown', 'Please select a valid option', function (value) {
					const { type, feedbackOptions } = this.parent;
					if (type === 'dropdown' && feedbackOptions?.length) {
						return feedbackOptions.some((option: DropdownOptions) => option.value === value);
					}
					return true;
				}),
			type: Yup.string(),
			placeHolder: Yup.string(),
		})
	);
	const name = Yup.string().required('Name is required');
	const contact = Yup.string().required('Contact number is required');
	const town = Yup.string().required('Town is required');
	const meetingVenue = Yup.string().required('Meeting Venue is required');
	const meetingType = Yup.string().required('Meeting Type is required');
	const mdi = Yup.string().required('MDI is required');
	const primaryFocusProduct = Yup.string().required('Primary Focus Product is required');
	const productDiscussed = Yup.string().required('Products Discussed is required');
	const meetFormat = Yup.string().required('Meet Format is required');
	const participants = Yup.string().required('Participants is required');
	const productHierarchyCode = Yup.string().required('Product Hierarchy Code is required');

	const catagories = Yup.string().required('Category is required');

	const designation = Yup.string().when('catagories', {
		is: 'Pidilite Employee',
		then: (schema) => schema.required('Designation is required'),
		otherwise: (schema) => schema.notRequired(),
	});

	const natureOfWork = Yup.string().when('catagories', {
		is: 'Helper/Junior Staff',
		then: (schema) => schema.required('Nature of Work is required'),
		otherwise: (schema) => schema.notRequired(),
	});

	const userToBeLinked = Yup.string().when('catagories', {
		is: 'Helper/Junior Staff',
		then: (schema) => schema.required('User to be linked is required'),
		otherwise: (schema) => schema.notRequired(),
	});

	const obstacleFaced = Yup.string().required('Obstacle Faced is required');
	const learningForm = Yup.string().required('Learning from in the meeting is required');

	const meeting_expenses = Yup.string().required('Meeting Expense is required');
	const food = Yup.string().required('Food Expense is required');
	const venue = Yup.string().required('Venue Expense is required');
	const gifts = Yup.string().required('Gifts Expense is required');
	const others = Yup.string().required('Others Expense is required');
	const slab = Yup.string().required();
	const address = Yup.string().required('Address is required');
	const contractorName1 = contractorNameValidation(1);
	const contractorName2 = contractorNameValidation(2);
	const contractorNumber1 = Yup.string().matches(phoneRegExp, 'Phone number is not valid').nullable();
	const contractorNumber2 = Yup.string().matches(phoneRegExp, 'Phone number is not valid').nullable();
	const productRSI = dealerVisitProductsValidationSchema;
	const activityDiscussed = dealerVisitProductsValidationSchema;

	const sku_names_c = Yup.string().required('SKU Name is required');

	const ref_lot_no_c = Yup.string().when('sub_category_defect_c', {
		is: 'Post Application',
		then: (schema) => schema.notRequired(),
		otherwise: (schema) => schema.required('Ref/Lot No is required'),
	});
	const manufactured_in_c = Yup.string().required('Manufacture date is required');
	const quantity_c = Yup.string().required('Quantity in Pieces is required');
	const customer_name_c = Yup.string().required('Customer Name is required');
	const registered_mobile_number_c = Yup.string()
		.matches(phoneRegExp, 'Number is not valid')
		.required('Customer Registered Mobile Number is required')
		.nullable();
	const type_of_complaint_c = Yup.string().required('Type of Complainant is required');
	const dealer_membership_code_c = Yup.string().required('Dealer/User Membership Code is required');
	const wss_names_c = Yup.string().required('WSS Name is required');
	const site_under_warranty_c = Yup.string().required('Site under warranty is required');
	const what_is_the_warranty_certificate_number_c = Yup.string().when('site_under_warranty_c', {
		is: 'Yes',
		then: (schema) => schema.required('What is the Warranty Certificate Number is Required'),
		otherwise: (schema) => schema.notRequired(),
	});
	const nature_of_complaints_c = Yup.string().required('Nature of complaint is required');
	const sub_category_defect_c = Yup.string().when('nature_of_complaints_c', {
		is: 'Expired Product',
		then: (schema) => schema.notRequired(),
		otherwise: (schema) => schema.required('Sub Category/ Defect is required'),
	});

	const description_c = Yup.string()
		.trim()
		.required('Complaint Description is required')
		.max(255, 'Complaint Description should no be more than 255 characters');
	const pictures_videos_c = pcmsProductImageValidationSchema;
	const complaint_sample_availability_c = Yup.string().required('Complaint sample availability is required');
	const sample_non_availability_reason = Yup.string().when('complaint_sample_availability_c', {
		is: 'No',
		then: (schema) => schema.required('Reason for unavailability of Sample is Required'),
		otherwise: (schema) => schema.notRequired(),
	});
	const sample_non_availability_reason_others = Yup.string().when('sample_non_availability_reason', {
		is: 'Others',
		then: (schema) =>
			schema
				.required('Specification is Required')
				.max(255, 'Complaint Description should no be more than 255 characters'),
		otherwise: (schema) => schema.notRequired(),
	});

	const applicator_name = Yup.string().when('nature_of_complaints_c', {
		is: 'Product Application Complaint',
		then: (schema) => schema.required('Name of the Applicator/Contractor is required'),

		otherwise: (schema) => schema.notRequired(),
	});
	const stage_of_application_during_complaint_c = Yup.string().when('nature_of_complaints_c', {
		is: 'Product Application Complaint',
		then: (schema) => schema.required('Stage of application during complaint is required'),

		otherwise: (schema) => schema.notRequired(),
	});
	const application_surface_c = Yup.string().when('nature_of_complaints_c', {
		is: 'Product Application Complaint',
		then: (schema) => schema.required('Application Surface is required'),

		otherwise: (schema) => schema.notRequired(),
	});
	const area_in_sqft_c = Yup.string().when('nature_of_complaints_c', {
		is: 'Product Application Complaint',
		then: (schema) => schema.required('Area is required'),

		otherwise: (schema) => schema.notRequired(),
	});
	const observation_c = Yup.string().when('nature_of_complaints_c', {
		is: 'Product Application Complaint',
		then: (schema) =>
			schema
				.required('Observation is required')
				.max(255, 'Observation Description should no be more than 255 characters'),
		otherwise: (schema) => schema.notRequired(),
	});
	const veneer = Yup.string().required('Is MDF/Veneer being used at site is required');
	const isContractorAvailable = Yup.string().when('$config', {
		is: () => SiteLeadConfigurations.OTP_FLOW,
		then: (schema) => schema.required('Is contractor available at site is required'),
		otherwise: (schema) => schema.notRequired(),
	});
	const substratesCombinations = substratesValidationSchema;
	const meetingClub = meetingClubValidationSchema;
	const meetingDealer = Yup.string().when('role', {
		is: RoleType.TSI,
		then: (schema) => schema.required('Dealer is required'),
		otherwise: (schema) => schema.notRequired(),
	});

	return {
		primary_number,
		secondary_number,
		sites_worked,
		sites_competition,
		average_glue,
		calculated_glue,
		permanent_town,
		permanent_address,
		permanent_city,
		permanent_state,
		permanent_street,
		permanent_zipcode,
		local_address,
		local_city,
		local_state,
		local_street,
		local_zipcode,
		work_address,
		work_city,
		work_state,
		work_street,
		work_zipcode,
		document,
		document_number,
		club,
		primary_details,
		average_glue_potential,
		workshop_address,
		workshop_zipcode,
		workshop_city,
		workshop_street,
		workshop_state,
		competition_details,
		siteName,
		siteLocation,
		gradeUsed,
		contractType,
		competitionReason,
		contractorName,
		contractorNumber,
		title,
		meeting_venue_c,
		startdate,
		enddate,
		start_time_c,
		end_time_c,
		dealerName,
		gluePotentialOfSite,
		availableProducts,
		architect_details,
		influencer_details,
		owner_details,
		kyc_file_name,
		feedback,
		name,
		contact,
		town,
		meetingVenue,
		meetingType,
		mdi,
		primaryFocusProduct,
		productDiscussed,
		meetFormat,
		participants,
		productHierarchyCode,
		catagories,
		designation,
		natureOfWork,
		userToBeLinked,
		obstacleFaced,
		learningForm,
		meeting_expenses,
		food,
		venue,
		gifts,
		others,
		slab,
		exception,
		activityDiscussed,
		productRSI,
		address,
		contractorName1,
		contractorName2,
		contractorNumber1,
		contractorNumber2,
		sku_names_c,
		ref_lot_no_c,
		manufactured_in_c,
		quantity_c,
		customer_name_c,
		registered_mobile_number_c,
		type_of_complaint_c,
		dealer_membership_code_c,
		wss_names_c,
		site_under_warranty_c,
		what_is_the_warranty_certificate_number_c,
		nature_of_complaints_c,
		sub_category_defect_c,
		description_c,
		pictures_videos_c,
		complaint_sample_availability_c,
		sample_non_availability_reason,
		sample_non_availability_reason_others,
		applicator_name,
		stage_of_application_during_complaint_c,
		application_surface_c,
		area_in_sqft_c,
		observation_c,
		factory_address,
		factory_city,
		factory_state,
		factory_zipcode,
		veneer,
		isContractorAvailable,
		substratesCombinations,
		glueUsedAtSite,
		meetingClub,
		meetingDealer,
		competitionConsumption,
	};
};

export default useValidationFields;
