import { ReactElement } from 'react';
import { NavLink } from 'react-router-dom';
import { INavigatorLinkProps } from '@components/common/navigator-tab/components/index';
import { useComponentLevelAccessCheck } from '../../../../authGuard/hooks/useComponentLevelAccessCheck';

export const NavigatorLink = (props: INavigatorLinkProps): ReactElement => {
	const { tab, index, handleResetSearch } = props;
	const accessCheck = useComponentLevelAccessCheck(tab.pathUrl, true);

	if (!accessCheck) return <></>;

	return (
		<li key={tab.name + index}>
			<NavLink
				to={tab.pathUrl}
				aria-label={tab.name}
				end
				className={({ isActive }) => {
					return 'tablinks ' + (isActive ? 'active' : '');
				}}
				onClick={handleResetSearch}
			>
				<span className="icon">
					<span className={tab.tabIcon}></span>
				</span>
				<span className="tab-name">{tab.name}</span>
			</NavLink>
		</li>
	);
};
