import { useQuery } from '@tanstack/react-query';
import { ErrorHandler } from '@helper/errorHandler';
import { IQueryResponse } from '@services/api';
import { APIService } from '@services/api/common-services';
import { APICONFIG } from '@config/api.config';
import { BirthdayQuery } from '@components/dashboard/components/birthdayPopup';

export const useBirthdayQuery = (): IQueryResponse<BirthdayQuery> => {
	const fetchBirthdayData = async (): Promise<BirthdayQuery> => {
		try {
			return await APIService.getData<BirthdayQuery>(APICONFIG.BIRTHDAY_POP_UP);
		} catch (error) {
			ErrorHandler(error);
			return null;
		}
	};

	const { data, error, isLoading, isPending, isFetching, refetch } = useQuery({
		queryKey: ['birthday'],
		queryFn: fetchBirthdayData,
		refetchOnWindowFocus: false,
		enabled: false,
	});

	return {
		data,
		error,
		isLoading,
		isPending,
		isFetching,
		refetch,
	};
};
