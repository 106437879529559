import { ReactElement } from 'react';
import { BankingDetailsCard, IDashboardQuarterSummary, SummaryCard } from '.';
import { useQuarterSummaryQuery } from '@services/hooks/dashboard/useQuarterSummaryQuery';
import { Wrapper } from '@components/common/Wrapper/wrapper';
import { useMeetingFilterStore } from '@store/meetingFilterStore';
import { meetingInitialValues } from '@components/meetingModule/components/meeting-filter';
import { EndUserFilterStore } from '@store/memberList';
import { getQuarterStartEndDate } from '@helper/utils';
import { initialAllEndUsersFilter } from '@components/members/components/allEndUserList';
import { useSiteSummaryListingStore } from '@store/siteSummaryListingFilterStore';
import { useComponentLevelAccessCheck } from '../../../../authGuard/hooks/useComponentLevelAccessCheck';

export const QuarterSummary = (props: IDashboardQuarterSummary): ReactElement => {
	const { id } = props;
	const { data, error, isFetching } = useQuarterSummaryQuery();
	const { updateFilter } = useMeetingFilterStore();
	const { updateFilter: siteLeadUpdate, allSiteSummaryFilter } = useSiteSummaryListingStore();
	const { updateAllEndUserFilter } = EndUserFilterStore();
	const checkMeetingPermissions = useComponentLevelAccessCheck('meeting-banner');
	const checkMemberModulePermissions = useComponentLevelAccessCheck('member-module');

	const handleCardRedirect = (): void => {
		const { start, end } = getQuarterStartEndDate(new Date());
		updateFilter('allMeetingFilter', {
			...meetingInitialValues,
			meetingStatus: [
				{
					id: 1,
					value: 'Completed Meeting',
				},
			],
			meetingType: 'FCC PRODUCT AWARENESS MEET',
			filter_date: [start, end],
		});
	};

	const handleSiteRedirection = (): void => {
		const { start, end } = getQuarterStartEndDate(new Date());
		siteLeadUpdate({
			...allSiteSummaryFilter,
			current_stage_of_site_c: 'Site Visited',
			lastactivitydate: [start, end],
		});
	};

	const handleAllEndUserFilter = (name: string): void => {
		const { start, end } = getQuarterStartEndDate(new Date());
		updateAllEndUserFilter({
			...initialAllEndUsersFilter,
			...(name === 'date' && {
				dateOfVisit: {
					...initialAllEndUsersFilter.dateOfVisit,
					startDate: start,
					endDate: end,
				},
			}),
			...(name === 'zbc' && {
				customToggle: { ...initialAllEndUsersFilter.customToggle, toggleData: ['ZBC'] },
			}),
			...(name === 'dgc' && {
				customToggle: { ...initialAllEndUsersFilter.customToggle, toggleData: ['Degrowing'] },
			}),
		});
	};

	return (
		<Wrapper isLoaderVisible={isFetching} isError={error}>
			<section key={id} className="quarter-summary-card">
				<div className="container">
					<span className="h3 cmn-heading">
						<span>Quarter Summary</span>
					</span>

					<div className="summary-card">
						{checkMeetingPermissions && (
							<SummaryCard
								heading="Total Meetings"
								urlPath="/meetings/all-meetings"
								value={data?.total_meeting}
								summaryLeftIcon="icon-coffee"
								onCardClick={handleCardRedirect}
							/>
						)}
						{checkMemberModulePermissions && (
							<>
								<SummaryCard
									heading="Total Site Visits"
									urlPath="/site-summary"
									value={data?.site_visits}
									summaryLeftIcon="icon-hammer"
									onCardClick={handleSiteRedirection}
								/>
								<SummaryCard
									heading="Total Users Added"
									urlPath="/end-users/all"
									value={data?.total_user_enrollment}
									summaryLeftIcon="icon-user"
									onCardClick={() => handleAllEndUserFilter('date')}
								/>
							</>
						)}
					</div>

					{checkMemberModulePermissions && (
						<div className="zero-banking-user-card">
							<ul className="banking-details-list">
								<BankingDetailsCard
									title="0 Banking User"
									redirectTo="/end-users/all"
									value={data?.zero_banking_users}
									onBankingCardClick={() => handleAllEndUserFilter('zbc')}
								/>
								<BankingDetailsCard
									title="De-growing Users"
									redirectTo="/end-users/all"
									value={data?.de_growing_users}
									onBankingCardClick={() => handleAllEndUserFilter('dgc')}
								/>
							</ul>
						</div>
					)}
				</div>
			</section>
		</Wrapper>
	);
};
