import { IQueryResponse } from '@services/api';
import {
	IChecksBDEOnLeaveResponse,
	IUseChecksBDEOnLeaveServiceProps,
} from '@services/hooks/checksAndVerification/index';
import { APIService } from '@services/api/common-services';
import { APICONFIG } from '@config/api.config';
import { ErrorHandler } from '@helper/errorHandler';
import { useQuery } from '@tanstack/react-query';
import { useBDEOnLeaveStore } from '@store/bdeOnLeaveStore';
import { ToastType, useTosterStore } from '@store/toster';

export const UseChecksBDEOnLeaveService = (
	props: IUseChecksBDEOnLeaveServiceProps
): IQueryResponse<IChecksBDEOnLeaveResponse> => {
	const { apiEnable } = props;
	const { setModalOpen } = useBDEOnLeaveStore();
	const { setToaster } = useTosterStore();
	const fetchChecksBDEOnLeave = async (): Promise<IChecksBDEOnLeaveResponse> => {
		try {
			const response = await APIService.getData<IChecksBDEOnLeaveResponse>(APICONFIG.CHECK_BDE_LEAVE);
			if (response.is_cmdi_on_leave) {
				setModalOpen();
			}
			return response;
		} catch (error) {
			const errMessage = new Error(error as string);
			setToaster(true, ToastType.error, errMessage.message);
			ErrorHandler(error);
			throw error;
		}
	};

	const { data, error, isLoading, isPending, isFetching, refetch } = useQuery({
		queryKey: ['checksBDEOnLeave'],
		queryFn: fetchChecksBDEOnLeave,
		enabled: apiEnable,
		refetchInterval: 6 * 60 * 60 * 1000,
		refetchOnWindowFocus: false,
		gcTime: 0,
	});

	return {
		data,
		error,
		isLoading,
		isPending,
		isFetching,
		refetch,
	};
};
