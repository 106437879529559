import { IQueryResponse } from '@services/api';
import { APIService } from '@services/api/common-services';
import { APICONFIG } from '@config/api.config';
import { ErrorHandler } from '@helper/errorHandler';
import { useQuery } from '@tanstack/react-query';
import { EndUserProfileResponse } from '@services/hooks/endUserProfile/index';

export const useEndUserProfileService = (
	accountId?: number | null,
	isFetch?: boolean | null
): IQueryResponse<EndUserProfileResponse> => {
	const fetchEndUserProfileService = async (): Promise<EndUserProfileResponse> => {
		try {
			return await APIService.getData<EndUserProfileResponse>(
				APICONFIG.END_USER_ELIGIBLE_FOR_VISIT + `?account_id=${accountId}`
			);
		} catch (error) {
			ErrorHandler(error);
			throw error;
		}
	};

	const { data, error, isLoading, isPending, isFetching, refetch } = useQuery({
		queryKey: ['endUserProfileService', accountId],
		queryFn: fetchEndUserProfileService,
		enabled: !!accountId && !!isFetch,
		refetchOnWindowFocus: false,
		retry: false,
		gcTime: 0,
		staleTime: 0,
	});

	return {
		data,
		error,
		isLoading,
		isPending,
		isFetching,
		refetch,
	};
};
