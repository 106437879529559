import { ReactElement } from 'react';
import { IDashboardCompetitionConversion } from '.';
import { NavLink } from 'react-router-dom';

export const CompetitionConversion = (props: IDashboardCompetitionConversion): ReactElement => {
	const { id } = props;

	return (
		<section key={id} className="competition-conversion-card">
			<div className="container">
				<NavLink
					to="/competition-conversion"
					aria-label="conversion recommender link"
					className="conversion-card-details"
				>
					<span className="icon-competition"> </span>
					<p>Competition Conversion Recommender</p>
					<span className="icon-arrow-right-circle"> </span>
				</NavLink>
			</div>
		</section>
	);
};
