import { create } from 'zustand';
import { DropdownOptions } from '@components/common/formControl/dropdown';

export interface SupervisorListFilterObject {
	contractor_name_value: DropdownOptions[];
	createddate: [Date | null, Date | null];
}

export interface SupervisorListingStore {
	supervisorFilter: SupervisorListFilterObject;
	updateFilter: (filterObject: SupervisorListFilterObject) => void;
	resetSupervisorFilter: () => void;
}

const initialState: { supervisorFilter: SupervisorListFilterObject } = {
	supervisorFilter: {
		contractor_name_value: [],
		createddate: [null, null],
	},
};

export const useSupervisorListingStore = create<SupervisorListingStore>((set) => ({
	...initialState,
	updateFilter: (filterObject): void => {
		set((state) => ({
			...state,
			supervisorFilter: { ...state.supervisorFilter, ...filterObject },
		}));
	},
	resetSupervisorFilter: (): void => {
		set((state) => ({
			...state,
			supervisorFilter: {
				contractor_name_value: [],
				createddate: [null, null],
			},
		}));
	},
}));
