import { useState, useEffect } from 'react';

export interface LocationDetails {
	currentAddress: string;
	streetNumber: string;
	route: string;
	city: string;
	state: string;
	country: string;
	fullAddress: string;
	latitude?: string;
	longitude?: string;
}

interface IuseGeoLocationDetailsReturnType {
	locationDetails: LocationDetails | null;
	error: string | null;
	loading: boolean;
	coords: { latitude: number | null; longitude: number | null };
}

export const useGeoLocationDetails = (): IuseGeoLocationDetailsReturnType => {
	const [locationDetails, setLocationDetails] = useState<LocationDetails | null>(null);
	const [error, setError] = useState<string | null>(null);
	const [loading, setLoading] = useState(true);
	const [coords, setCoords] = useState<{ latitude: number | null; longitude: number | null }>({
		latitude: null,
		longitude: null,
	});

	useEffect(() => {
		const reverseGeocode = (lat: number, lng: number): void => {
			const geocoder = new window.google.maps.Geocoder();
			const latlng = { lat, lng };

			geocoder.geocode({ location: latlng }, (results, status) => {
				if (status === 'OK' && results?.[0]) {
					const result = results[0];

					const addressComponents = {
						streetNumber: '',
						route: '',
						city: '',
						state: '',
						country: '',
					};

					result.address_components.reduce((acc, component) => {
						switch (true) {
							case component.types.includes('street_number'):
								acc.streetNumber = component.long_name;
								break;
							case component.types.includes('route'):
								acc.route = component.long_name;
								break;
							case component.types.includes('locality'):
								acc.city = component.long_name;
								break;
							case component.types.includes('administrative_area_level_1'):
								acc.state = component.long_name;
								break;
							case component.types.includes('country'):
								acc.country = component.long_name;
								break;
						}
						return acc;
					}, addressComponents);

					const { streetNumber, route, city, state, country } = addressComponents;
					const fullAddress = result.formatted_address;
					const currentAddress = `${streetNumber} ${route}`.trim();

					setLocationDetails({
						currentAddress,
						streetNumber,
						route,
						city,
						state,
						country,
						fullAddress,
						latitude: lat.toString(),
						longitude: lng.toString(),
					});
					setError(null);
				} else {
					setError(`Geocoding failed with status: ${status}`);
					setLocationDetails(null);
				}
				setLoading(false);
			});
		};

		const getLocation = (): void => {
			if (navigator.geolocation) {
				navigator.geolocation.getCurrentPosition(
					(position) => {
						const { latitude, longitude } = position.coords;
						setCoords({ latitude, longitude });
						reverseGeocode(latitude, longitude);
					},
					(err) => {
						setError(`Geolocation error: ${err.message}`);
						setLoading(false);
					}
				);
			} else {
				setError('Geolocation is not supported by this browser.');
				setLoading(false);
			}
		};

		if (window?.google?.maps) {
			getLocation();
		} else {
			setError('Google Maps API is not loaded');
			setLoading(false);
		}
	}, []);

	return { locationDetails, error, loading, coords };
};
