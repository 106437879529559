import '@assets/styles/pages/product-recommendations.scss';
import { Stepper } from '@components/common';
import { ReactElement, useCallback, useMemo } from 'react';
import { ProductDiscussion } from './productDiscussion';
import { SchemeDiscussion } from './schemeDiscussion';
import { FeedbackExpectation } from './feedbackExpectation';
import { useFormik } from 'formik';
import { defaultTaskListFormInitialValues, IFeedback } from '.';
import { useSiteLeadStore } from '@store/siteLeadStore';
import { useTaskListProductsQuery } from '@services/hooks/site-leads/useTaskListProductsQuery';
import useStepperStore from '@store/stepperStore';
import { SiteLeadData, useFPCDetailsQuery } from '@services/hooks/site-leads';
import { useValidation } from '@hooks/validations';
import { useLocation } from 'react-router-dom';
import { useVisitFeedbackMutation } from '@services/hooks/site-leads/useVisitFeedbackMutation';
import { Wrapper } from '@components/common/Wrapper/wrapper';
import { useCurrentSlabQuery } from '@services/hooks/contractor360/useCurrentSlabQuery';
import { useSetMainHeader } from '@hooks/useSetMainHeader';
import { isNullOrEmpty } from '@components/delaerVisit/components/addEditDealerVisit';
import { ToastType, useTosterStore } from '@store/toster';

export const TaskList = (): ReactElement => {
	useSetMainHeader('Task List');
	const { state } = useLocation();
	const {
		siteName,
		siteLocation,
		glue,
		type_of_contract,
		reason,
		grade,
		setSiteVisitId,
		setSiteLeadId,
		brandUsed,
		substrates,
		siteDetails,
	} = useSiteLeadStore();
	const {
		data: taskListProducts,
		isFetching: isTaskListProductsFetching,
		error: taskListProductsError,
	} = useTaskListProductsQuery(siteDetails?.membershipno);
	const { setCurrentStep } = useStepperStore();
	const { TaskListValidationSchema } = useValidation();
	const { mutate: visitFeedbackMutation, isPending: isVisitFeedbackPending } = useVisitFeedbackMutation();
	const { data: currentSlab } = useCurrentSlabQuery(siteDetails?.membershipno);
	const { data: fpcDetails } = useFPCDetailsQuery(siteDetails?.membershipno);
	const listProducts = taskListProducts?.data?.product_recommendations;
	const { setToaster } = useTosterStore();

	const formik = useFormik({
		initialValues: defaultTaskListFormInitialValues,
		onSubmit: () => {},
		validationSchema: TaskListValidationSchema,
	});

	const commonProps = {
		formik,
	};

	const returnFeedbackResponseValue = (product: IFeedback): string => {
		//TODO: Need to refactor this condition
		if (typeof product.feedbackResponse === 'boolean' && product.feedbackResponse) {
			return 'True';
		}
		if (product.feedbackResponse.length > 0) {
			return product.feedbackResponse;
		}
		return 'False';
	};

	const siteLeadPayload = useCallback((): SiteLeadData => {
		const { feedback, exception } = formik.values;

		const payload: SiteLeadData = {
			site_lead: {
				name: siteName,
				site_location_c: siteLocation,
				end_user_c: siteDetails?.sfid,
				glue_used_at_the_site: glue,
				grade_glue_used_at_the_site: grade,
				reason_of_competition: glue === 'Competition' ? reason : null,
				type_of_contract: type_of_contract,
				brand_of_glue_used_at_site: brandUsed,
				market: 'BV',
				contract_phone: siteDetails?.name,
				contract_name: siteDetails?.number,
				roff_substrate_c: isNullOrEmpty(substrates),
			},
			visit: {
				product1_demoed_code: listProducts?.[0]?.product_code,
				product1_demoed_name: listProducts?.[0]?.product_name,
				product2_demoed_code: null,
				product2_demoed_name: null,
				product1_discussed:
					listProducts?.[1] && Object.keys(listProducts?.[1]).length !== 0
						? `${listProducts?.[1]?.product_code}-${listProducts?.[1]?.product_name}`
						: null,
				product2_discussed: null,
			},
			product_feedback: [],
			product_answered: [],
		};

		if (state?.siteId) {
			payload.site_lead = {
				site_id: state?.siteId,
			};
		}

		if (feedback.length > 0) {
			payload.product_answered = formik.values.productResponse.map((product, index) => ({
				product: listProducts?.[index].product_code,
				answer: product,
			}));

			payload.product_feedback = feedback
				.flat()
				.map((product) => {
					return {
						question_type: 'product_feedback',
						question: product.feedbackQuestion,
						answer: returnFeedbackResponseValue(product),
						product: product.productId,
					};
				})
				.filter((product) => product !== undefined);
		}

		if (exception.length > 0) {
			payload.product_feedback = [
				...payload.product_feedback,
				...exception.map((elem) => {
					return {
						question_type: 'visit_feedback',
						question: elem.feedbackQuestion,
						answer: typeof elem.feedbackResponse === 'string' ? elem.feedbackResponse : '',
					};
				}),
			];
		}

		if (formik.values.slab.length > 0) {
			payload.slab_answered = formik.values.slab;
		}

		return payload;
	}, [
		formik.values,
		siteName,
		siteLocation,
		siteDetails,
		glue,
		grade,
		reason,
		type_of_contract,
		brandUsed,
		substrates,
		listProducts,
		state?.siteId,
	]);

	const handleNext = useCallback((): void => {
		const payload = siteLeadPayload();

		visitFeedbackMutation(payload, {
			onSuccess: (data) => {
				if (data && Object.keys(data).length) {
					setSiteVisitId(data?.visit?.id);
					setSiteLeadId(data?.site_lead?.id ?? state?.siteId);
					setCurrentStep(2);
				}
			},
			onError: (error) => {
				setToaster(true, ToastType.error, error?.message);
			},
		});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [siteLeadPayload, visitFeedbackMutation, setSiteVisitId, setSiteLeadId, state?.siteId, setCurrentStep]);

	const isAllowedNext = useMemo(
		() => formik.values.productResponse.filter((item) => item !== null).length !== listProducts?.length,
		[formik.values.productResponse, listProducts?.length]
	);

	return (
		<section className="task-list-section">
			<div className="container">
				<Wrapper isLoaderVisible={isTaskListProductsFetching} isError={taskListProductsError}>
					<>
						<ProductDiscussion products={listProducts} formik={formik} />
						<SchemeDiscussion {...commonProps} slabData={currentSlab} fpcDetails={fpcDetails} />
						{taskListProducts?.data?.feedback_exceptions?.length !== 0 && (
							<FeedbackExpectation {...commonProps} exception={taskListProducts?.data?.feedback_exceptions} />
						)}

						<Stepper
							nextHandler={handleNext}
							isPending={isVisitFeedbackPending}
							isNext={isAllowedNext || !formik.isValid || !formik.dirty}
						/>
					</>
				</Wrapper>
			</div>
		</section>
	);
};
