import { RedemptionHistoryDetails } from '@services/hooks/contractor360';
import { Value } from '@wojtekmaj/react-daterange-picker/dist/cjs/shared/types';

export interface RedemptionObject {
	id: string;
	quantity: string;
	orderDate: string;
	amount: string;
	name: string;
	billingDate: string;
	deliveryStatus: string;
}

export interface FilterObject {
	limit: number;
	offset: number;
	search: string;
}

export interface IRedemptionHeaderQuery {
	data: RedemptionHistoryDetails | undefined;
	redemptionData: RedemptionObject[];
}
export interface IRedemptionFilter {
	setSearch: (value: string) => void;
	date: Value;
	setDate: (value: Value) => void;
}

export type ICustomerQuery = {
	mobile: string;
	format: string;
	points_summary: string;
	promotion_points: string;
};
export interface CustomerObject {
	response: {
		status: Status;
		customers: Customers;
	};
}

export interface Status {
	success: string;
	code: number;
	message: string;
	total: string;
	success_count: string;
}

export interface Customers {
	customer: Customer[];
}

export interface Customer {
	firstname: string;
	lastname: string;
	mobile: string;
	email: string;
	external_id: string;
	lifetime_points: number;
	lifetime_purchases: number;
	loyalty_points: number;
	current_slab: string;
	registered_on: string;
	updated_on: string;
	type: string;
	source: string;
	identifiers: string[];
	gender: string;
	registered_by: string;
	registered_store: RegisteredStore;
	registered_till: RegisteredStore;
	user_groups2: string[];
	fraud_details: FraudDetails;
	points_summary: PointsSummary;
	tier_expiry_date: string;
	trackers: string;
	promotion_points: PromotionPoints;
	current_nps_status: string | boolean;
	custom_fields: CustomFields;
	extended_fields: CustomFields;
	transactions: Transactions2;
	coupons: Coupons;
	notes: string[];
	points_summaries: PointsSummaries;
	item_status: ItemStatus;
}

export interface RegisteredStore {
	code: string;
	name: string;
}

export interface FraudDetails {
	status: string;
	marked_by: string;
	modified_on: string;
	reason: string;
}

export interface PointsSummary {
	expired: number;
	redeemed: number;
	adjusted: number;
	returned: number;
	linked_partner_programs: LinkedPartnerPrograms;
}

export interface LinkedPartnerPrograms {
	linked_partner_program: string[];
}

export interface PromotionPoints {
	customer: Customer2;
	transactions: Transactions;
	lineitems: Lineitems;
}

export interface Customer2 {
	item: Item[];
}

export interface Item {
	points: number;
	expiry_date: string;
	issued_at: RegisteredStore;
	issued_on: string;
	program_id: string;
	promotion_name: string;
	promotion_source: PromotionSource;
}

export interface PromotionSource {
	source_type: string;
	source_id: string;
}

export interface Transactions {
	item: Item2[];
}

export interface Item2 {
	points: number;
	expiry_date: string;
	issued_at: RegisteredStore;
	issued_on: string;
	program_id: string;
	promotion_source: PromotionSource2;
}

export interface PromotionSource2 {
	source_type: string;
	source_id: string;
}

export interface Lineitems {
	item: Item3[];
}

export interface Item3 {
	lineitem_id: number;
	transaction_id: number;
	points: number;
	expiry_date: string;
	issued_at: RegisteredStore;
	issued_on: string;
	program_id: string;
	promotion_source: PromotionSource3;
}

export interface PromotionSource3 {
	source_type: string;
	source_id: string;
}

export interface CustomFields {
	field: Field[];
}

export interface Field {
	name: string;
	value: string;
}

export interface Transactions2 {
	transaction: Transaction[];
}

export interface Transaction {
	id: string;
	number: string;
	type: string;
	created_date: string;
	store: string;
}

export interface Coupons {
	coupon: string[];
}

export interface PointsSummaries {
	points_summary: string[];
}

export interface ItemStatus {
	success: string;
	code: string;
	message: string;
	warnings: Warnings;
}

export interface Warnings {
	warning: string[];
}

export const PAGE_LIMIT = 500;
