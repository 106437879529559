import * as Yup from 'yup';
import { StringSchema } from 'yup';

export interface ISupervisorFields {
	firstname: string | null | undefined;
	middlename: string | null | undefined;
	lastname: string | null | undefined;
	phone: string | null | undefined;
	largeContractor: string | null | undefined;
}

interface ISupervisorValidationReturn {
	largeContractor: StringSchema<string>;
}

export const SupervisorInitialValue: ISupervisorFields = {
	firstname: '',
	middlename: '',
	lastname: '',
	phone: '',
	largeContractor: '',
};

export const useSupervisorValidationFields = (): ISupervisorValidationReturn => {
	const largeContractor: StringSchema<string> = Yup.string().required('Large contractor is required');

	return { largeContractor };
};
