import { APICONFIG } from '@config/api.config';
import { APIService } from '@services/api/common-services';
import { useQuery } from '@tanstack/react-query';
import { IQueryResponse } from '@services/api';
import { ErrorHandler } from '@helper/errorHandler';
import { useCheckRxdb } from '@hooks/checkRxdb';
import { setRxdb } from '@helper/utils';
import { ISupervisorDetail } from '@components/supervisorList';

export const useSupervisorList = (forceFetch = false): IQueryResponse<Array<ISupervisorDetail>> => {
	const { isDbChecked, shouldFetch } = useCheckRxdb('supervisor_list');
	const fetchSupervisorListing = async (): Promise<Array<ISupervisorDetail>> => {
		try {
			const response = await APIService.getData<Array<ISupervisorDetail>>(APICONFIG.SUPERVISOR_LIST_BY_CONTACT, {
				limit: '0',
			});
			if (response) {
				const data = response.map((item) => {
					return {
						...item,
						id: item?.id?.toString(),
						fullName: `${item?.firstname} ${item.lastname}`,
					};
				});
				await setRxdb('supervisor_list', data);
			}
			return response;
		} catch (error) {
			ErrorHandler(error);
			return [];
		}
	};

	const { data, error, isLoading, isPending, isFetching, refetch } = useQuery({
		queryKey: ['supervisorListing'],
		queryFn: fetchSupervisorListing,
		enabled: (isDbChecked && shouldFetch) || forceFetch,
		refetchOnWindowFocus: false,
		retry: false,
	});

	return {
		data,
		error,
		isLoading,
		isPending,
		isFetching,
		refetch,
	};
};
