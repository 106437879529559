import { create, StateCreator } from 'zustand';
import { persist, PersistOptions } from 'zustand/middleware';

interface IEndUserStore {
	refGlider: React.RefObject<HTMLLIElement> | null;
	setGliderRef: (value: React.RefObject<HTMLLIElement>) => void;
	activeNavTabs: number[];
	setActiveNavTab: (value: number[]) => void;
	resetNavTabs: () => void;
	isEditing: boolean;
	isFormEdit: boolean;
	formId: number | null;
	formRevalidate?: boolean | null;
	setIsEditing: (val: boolean) => void;
	setIsFormEdit: (val: boolean) => void;
	setFormId: (val: number | null) => void;
	setFormRevalidate: (val: boolean | null) => void;
}

const initialState = {
	refGlider: null,
	activeNavTabs: [0],
	isEditing: false,
	isFormEdit: false,
	formId: null,
	formRevalidate: false,
};

type EnduserPersistOptions = PersistOptions<IEndUserStore, Partial<IEndUserStore>>;

export const useEndUserStore = create<IEndUserStore>(
	(
		persist as unknown as (
			config: StateCreator<IEndUserStore>,
			options: EnduserPersistOptions
		) => StateCreator<IEndUserStore>
	)(
		(set) => ({
			...initialState,
			setGliderRef: (value: React.RefObject<HTMLLIElement>): void => set((state) => ({ ...state, refGlider: value })),
			setActiveNavTab: (value: number[]): void => set((state) => ({ ...state, activeNavTabs: value })),
			resetNavTabs: (): void => set((state) => ({ ...state, activeNavTabs: [0] })),
			setIsEditing: (val: boolean): void => set((state) => ({ ...state, isEditing: val })),
			setIsFormEdit: (val: boolean): void => set((state) => ({ ...state, isFormEdit: val })),
			setFormId: (val: number | null): void => set((state) => ({ ...state, formId: val })),
			setFormRevalidate: (val: boolean | null): void => set((state) => ({ ...state, formRevalidate: val })),
		}),
		{
			name: 'enduser-storage',
			partialize: (state) => ({
				activeNavTabs: state.activeNavTabs,
				isFormEdit: state.isFormEdit,
				formId: state.formId,
				isEditing: state.isEditing,
				formRevalidate: state.formRevalidate,
			}),
		} as EnduserPersistOptions
	)
);
