import { useEffect } from 'react';

export const useSetMainHeader = (header?: string, isHideResyncBtn?: boolean): void => {
	useEffect(() => {
		const mainHeader = document.getElementById('main-header');
		if (mainHeader) {
			mainHeader.innerHTML = header ?? '';
		}
	}, [header]);

	useEffect(() => {
		const resyncBtn = document.getElementById('resync-button');
		if (!resyncBtn) return;
		if (isHideResyncBtn) {
			resyncBtn.style.display = 'none';
			return;
		}
		resyncBtn.style.display = 'flex';
	}, [isHideResyncBtn]);
};
