import { ReactElement } from 'react';
import { ITopBanks } from '.';
import { Wrapper } from '@components/common/Wrapper/wrapper';
import { renderNullCase } from '@helper/utils';

export const TopBanks = (props: ITopBanks): ReactElement => {
	const { data, isGetContractorDetailFetching, error } = props;

	const formatValue = (value?: number | null, unit?: string): JSX.Element => {
		if (!value) return renderNullCase('h4');
		return (
			<span className="h4">
				{Number.isInteger(value) ? value.toString() : value.toFixed(2)}
				<span className="unit-wrapper"> {unit}</span>
			</span>
		);
	};

	return (
		<section className="top-banks-wrapper">
			<Wrapper isLoaderVisible={isGetContractorDetailFetching} isError={error}>
				<div className="container">
					<ul className="top-banks-inner">
						<li>
							<span className="icon-total-collection"></span>
							<div className="top-bank-item">
								<span className="bank-title h5">Total Banked KGs QTD</span>
								{formatValue(data?.totalbankingQTD, 'Kg')}
							</div>
						</li>
						<li>
							<span className="icon-achieve-point"></span>
							<div className="top-bank-item">
								<span className="bank-title h5">Total Points Achieved QTD</span>
								{formatValue(data?.banking_points_qtd, 'Pts')}
							</div>
						</li>
						<li>
							<span className="icon-star-bag"></span>
							<div className="top-bank-item">
								<span className="bank-title h5">Total Points / Kg</span>
								{formatValue(data?.total_points_per_kgs, 'Pts/Kg')}
							</div>
						</li>
					</ul>
				</div>
			</Wrapper>
		</section>
	);
};
