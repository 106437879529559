import { ReactElement, useMemo, useState, useCallback } from 'react';
import '@assets/styles/pages/addNewMeetings.scss';
import { MultiselectDropdown } from '@components/common';
import Checkbox from '@components/common/formControl/checkbox/checkbox';
import { ClubDetails } from '@services/hooks/enduser-details';
import { useGetRxDb } from '@hooks/getRxdbData';
import { NonAttendedMeetingFilterObject, SelectedCustomers } from '.';
import { DropdownOptions } from '@components/common/formControl/dropdown';
import { Wrapper } from '@components/common/Wrapper/wrapper';
import { NonRepeatedUser } from '@services/hooks/meetings';
import { useNonAttendedMembersQuery } from '@services/hooks/meetings/useNonRepeatedAttendedQuery';
import { useNonAttendedMembersStore } from '@store/nonAttendedMembersStore';
import { NoDataFound } from '@components/common/no-data-found/noDataFound';
import { useNavigate } from 'react-router-dom';

export const MeetingParticipants = (): ReactElement => {
	const navigate = useNavigate();
	const { result: clubOptions } = useGetRxDb<ClubDetails>('club');
	const clubOptionsFormatted = useMemo(
		() => clubOptions.map((item) => ({ id: item?.sfid ?? '', value: item.name ?? '' })),
		[clubOptions]
	);
	const { setNonAttendedMembers, nonAttendedMembers } = useNonAttendedMembersStore();
	const [selectedUsers, setSelectedUsers] = useState<SelectedCustomers[]>(nonAttendedMembers?.participants || []);
	const [filter, setFilter] = useState<NonAttendedMeetingFilterObject>({
		limit: 50,
		offset: 0,
		search: '',
		isOb: '',
		club: nonAttendedMembers?.club?.map((item: DropdownOptions) => item.id).join(',') || '',
	});
	const { data, isFetching, error } = useNonAttendedMembersQuery(filter);

	const handleObChange = useCallback((): void => {
		setFilter((prevFilter) => ({ ...prevFilter, isOb: prevFilter.isOb === '' ? 'true' : '' }));
	}, []);

	const handleSearch = useCallback((e: React.ChangeEvent<HTMLInputElement>): void => {
		e.preventDefault();
		setFilter((prevFilter) => ({
			...prevFilter,
			search: e.target.value,
		}));
	}, []);

	const handleCheckBoxChange = useCallback((item: NonRepeatedUser): void => {
		setSelectedUsers((prevUsers) => {
			const existingUserIndex = prevUsers.findIndex((user) => user.contactid === item.personcontactid);
			if (existingUserIndex !== -1) {
				return prevUsers.filter((_, index) => index !== existingUserIndex);
			} else {
				return [
					...prevUsers,
					{ membershipno_c: item.membershipno, contactid: item.personcontactid, club_name_c: item.clubname },
				];
			}
		});
	}, []);

	const isUserSelected = useCallback(
		(userId: string): boolean => {
			return selectedUsers.some((user) => user.contactid === userId);
		},
		[selectedUsers]
	);

	const handleSelectAll = useCallback((): void => {
		if (data) {
			setSelectedUsers((prevUsers) => {
				if (prevUsers.length === data.length) {
					return [];
				} else {
					return data.map((item) => ({
						membershipno_c: item?.membershipno,
						contactid: item?.personcontactid,
						club_name_c: item?.clubname,
					}));
				}
			});
		}
	}, [data]);

	const handleSubmitHandler = useCallback(() => {
		if (nonAttendedMembers) {
			setNonAttendedMembers({ ...nonAttendedMembers, participants: selectedUsers });
		}
		navigate(-1);
	}, [navigate, nonAttendedMembers, selectedUsers, setNonAttendedMembers]);

	return (
		<div className="manage-participants">
			<div className="container">
				<div className="clubs-wrapper">
					<span className="h4">Clubs</span>
					<MultiselectDropdown
						options={clubOptionsFormatted}
						values={nonAttendedMembers?.club ?? []}
						name={'club'}
						disabled
						className="input-border"
					/>
				</div>

				<div className="ob-questions">
					<Checkbox
						id="ob"
						name="ob"
						type="checkbox"
						checked={filter.isOb !== ''}
						label="Is Office Bearer (OB)?"
						disabled={isFetching}
						onChange={handleObChange}
					/>
				</div>

				<div className="filter-section">
					<div className="search-filter-wrapper">
						<div className="search-with-filter">
							<div className="search">
								<form>
									<div className="search-row">
										<div className="input-col">
											<label htmlFor="search-top" className="hide">
												search
											</label>
											<input
												type="text"
												name="search"
												id="search-top"
												placeholder="Search by Name/ Mobile Number"
												className="search-bar"
												autoComplete="off"
												value={filter.search}
												onChange={handleSearch}
											/>
										</div>
										<button className="search-button" aria-label="search user" disabled>
											<span className="icon-search"></span>
										</button>
									</div>
								</form>
							</div>
						</div>
					</div>
				</div>

				<p className="text-center participants-number">Total number of participants selected: {selectedUsers.length}</p>

				<Wrapper isLoaderVisible={isFetching} isError={error}>
					<div className="participants-list">
						<div className="all-select">
							<Checkbox
								id="allSelect"
								name="allSelect"
								type="checkbox"
								checked={data && data.length > 0 && selectedUsers.length === data.length}
								label="Select All"
								disabled={isFetching}
								onChange={handleSelectAll}
							/>
						</div>

						<ul className="participants-box">
							{Array.isArray(data) && data?.length > 0 ? (
								data?.map((item) => (
									<li key={item.sfid}>
										<div className="participants-list-wrapper">
											<Checkbox
												id={item.personcontactid}
												name={item.personcontactid}
												type="checkbox"
												checked={isUserSelected(item.personcontactid)}
												label={item.name}
												disabled={isFetching}
												onChange={() => handleCheckBoxChange(item)}
											/>

											<div className="mobile-number">
												<a href={`tel:+91${item.phone}`} aria-label="mobile number">
													<span className="icon-call"></span>
													<span className="user-number">{item.phone}</span>
												</a>
											</div>
										</div>
									</li>
								))
							) : (
								<NoDataFound />
							)}
						</ul>
					</div>
				</Wrapper>
				<div className="submit-btn">
					<button
						className="btn btn-medium btn-primary"
						id="next-page"
						onClick={handleSubmitHandler}
						disabled={selectedUsers.length === 0}
					>
						Submit
					</button>
				</div>
			</div>
		</div>
	);
};
