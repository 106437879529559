import { useStepperStore } from '@store/stepperStore';
import { ReactElement } from 'react';
import { AddNewSite, SiteAddress, TaskList, VisitRecord } from './components';

export const SiteLeads = (): ReactElement => {
	const { currentStep } = useStepperStore();

	return (
		<>
			{currentStep === 0 && <AddNewSite />}
			{currentStep === 1 && <TaskList />}
			{currentStep === 2 && <SiteAddress />}
			{currentStep === 3 && <VisitRecord />}
		</>
	);
};
