import { ReactElement, useState } from 'react';
import { Outlet, useLocation } from 'react-router-dom';
import { Filter, FilterTags, Sort } from '@components/common';
import { AddNewOptionList, MemberTabList } from '.';
import { useFilterStore } from '@store/index';
import { useEndUserStore } from '@store/enduser';
import { NavigatorTab } from '@components/common/navigator-tab';
import { EndUserSearch } from '@components/members/components';
import { AddNewDataButton } from '@components/common/add-new-data-button';
import { overlayRemoveFadeAction } from '@components/common/sort';

export const EndUserList = (): ReactElement => {
	const { pathname } = useLocation();
	const [openFilter, setOpenFilter] = useState<boolean>(false);
	const [openSort, setOpenSort] = useState<boolean>(false);
	const { filterObject } = useFilterStore((state) => state);
	const { dateOfVisit, kycStatus, visitStatus, customToggle, checkSecondary, customSelect, singleDropDown } =
		filterObject;
	const { setIsEditing, setActiveNavTab, setIsFormEdit, setFormId, setFormRevalidate } = useEndUserStore();
	const customData = customToggle.toggleData;
	const secondaryData = checkSecondary;
	const customSelectData = customSelect.customData;

	const allStatesPresent =
		visitStatus.visitStatus.length ||
		kycStatus.kycStatus.length ||
		customData.length ||
		secondaryData.some((item) => item.secondaryData.length > 0) ||
		customSelectData.length ||
		dateOfVisit.startDate?.toString().length ||
		dateOfVisit.endDate?.toString().length ||
		singleDropDown.dropDownData.length;

	const handleClick = (): void => {
		setIsEditing(false);
		setFormId(null);
		setIsFormEdit(false);
		setActiveNavTab([0]);
		setFormRevalidate(false);
	};

	return (
		<>
			<section className="tab-search-section" id="end-user">
				<div className="container">
					<NavigatorTab tabList={MemberTabList} selectedFilterChild={!!allStatesPresent && <FilterTags />}>
						<EndUserSearch
							setFilterOpen={() => setOpenFilter(!openFilter)}
							setSortOpen={() => setOpenSort(!openSort)}
							placeHolder={
								MemberTabList.findIndex((tab) => tab.pathUrl === pathname) === 1 ||
								MemberTabList.findIndex((tab) => tab.pathUrl === pathname) === 3
									? 'Search Name / Mobile'
									: 'Search Name / Mobile / Membership Id'
							}
						/>
					</NavigatorTab>
					<Outlet />
				</div>
			</section>
			{openFilter && <Filter setFilterOpen={() => overlayRemoveFadeAction(() => setOpenFilter(false))} />}
			{openSort && <Sort setSortOpen={() => overlayRemoveFadeAction(() => setOpenSort(false))} />}
			{/*currently add new supervisor flow is commented to open uncomment the code and remove last line*/}
			{/*<AddNewDataButton redirectUrlList={AddNewOptionList} labelName={'add user'} handleClick={handleClick} />*/}
			<AddNewDataButton redirectUrl={AddNewOptionList[0].route} labelName={'add user'} handleClick={handleClick} />
		</>
	);
};
