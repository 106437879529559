import { ReactElement } from 'react';
import { IDashboardPowerBi } from '.';
import { NavLink } from 'react-router-dom';

export const PowerBi = (props: IDashboardPowerBi): ReactElement => {
	const { id } = props;

	const scoreCardLink =
		'https://app.powerbi.com/groups/me/apps/70b919b4-f14f-471a-ac53-1b9e18562a82/reports/293dcf7c-192b-4ed5-81b7-6969fc81cf54/ReportSectiona33c6c3065bdc4dd9235?experience=power-bi';
	return (
		<section key={id} className="power-bi-button-section">
			<div className="container">
				<div className="power-bi-button-wrapper">
					<ul className="fwd-wrapper">
						<li>
							<NavLink to={scoreCardLink} target="_blank" aria-label="link">
								<p>Score Card Link</p>
								<span className="icon-link"></span>
							</NavLink>
						</li>
						<li>
							<NavLink to={scoreCardLink} target="_blank" aria-label="link">
								<p>Field Working Days</p>
								<span className="icon-link" />
							</NavLink>
						</li>
					</ul>
				</div>
			</div>
		</section>
	);
};
