import { ReactElement, useCallback, useEffect } from 'react';
import { Modal } from '@components/common/modal/modal';
import { useBirthdayQuery } from '@services/hooks/dashboard/useBirthdayQuery';
import { Link } from 'react-router-dom';
import Cookies from 'universal-cookie';
import { ToastType, useTosterStore } from '@store/toster';
import { overlayRemoveFadeAction } from '@components/common/sort';
import { useBDEOnLeaveStore } from '@store/bdeOnLeaveStore';
import { useCommonModalPopUpStore } from '@store/useCommonModalPopUpStore';
import { NoDataFound } from '@components/common/no-data-found/noDataFound';

export const BirthdayPopup = (): ReactElement => {
	const { setKeyBaseModalState, isBirthDayModalOpen: modalOpen } = useCommonModalPopUpStore();
	const { data, refetch } = useBirthdayQuery();
	const { setToaster } = useTosterStore();
	const { isModalOpen: bdeIsOnLeave } = useBDEOnLeaveStore();

	useEffect(() => {
		if (bdeIsOnLeave) {
			return;
		}
		const cookies = new Cookies();
		const lastLoginDate = cookies.get('lastLoginDate');
		const currentDate = new Date();
		const tomorrow = new Date(currentDate.getTime() + 24 * 60 * 60 * 1000);
		tomorrow.setHours(0, 0, 0, 0);

		if (modalOpen || !lastLoginDate) {
			refetch?.()
				.then((response) => {
					if (response?.data && response?.data?.data?.length > 0) {
						setKeyBaseModalState('isBirthDayModalOpen', true);
					}
					cookies.set('lastLoginDate', currentDate, { expires: tomorrow, path: '/', maxAge: 24 * 60 * 60 });
				})
				.catch((error) => {
					setToaster(true, ToastType.error, error);
				});
		}
	}, [bdeIsOnLeave, modalOpen, refetch, setKeyBaseModalState, setToaster]);

	const handleModalClose = useCallback(
		() => overlayRemoveFadeAction(() => setKeyBaseModalState('isBirthDayModalOpen', false)),
		[setKeyBaseModalState]
	);

	if (!modalOpen) {
		return <></>;
	}

	return (
		<Modal
			modalTitle={`Today's Birthday`}
			modalId="popupDialog"
			className="dialog-sm"
			modalOpen={modalOpen}
			onModalClose={handleModalClose}
		>
			<div className="birthday-popup">
				<div className="birthday-list">
					<ul className="birthday-box">
						{data?.data && data?.data?.length > 0 ? (
							data?.data?.map((item, index) => (
								<li key={item.name}>
									<div className="birthday-list-wrapper">
										<p className="h4">
											<span>{index + 1}. </span>
											{item.name}
										</p>
										<div className="birthday-info">
											<Link to={'tel:+91' + item.phone} aria-label="call user" className="call">
												<span className="icon-call"></span>
											</Link>
										</div>
									</div>
								</li>
							))
						) : (
							<NoDataFound className="text-center" />
						)}
					</ul>
				</div>
			</div>
		</Modal>
	);
};
