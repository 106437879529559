import { ReactElement } from 'react';
import { IDashboardUserSummaryCard } from '.';

export const UserSummaryCard = (props: IDashboardUserSummaryCard): ReactElement => {
	const { name, value, icon, id } = props;

	return (
		<div key={id} className="summary-card-box">
			<div className="card-icon">
				<span className={`icon-${icon} icon`}></span>
			</div>
			<p className="card-details h5">{name}</p>
			<span className="count h3">{value}</span>
		</div>
	);
};
