import { ReactElement, useEffect } from 'react';
import { initialAllEndUsersFilter } from '.';
import { UserCard } from '../userCard/userCard';
import { useFilterStore } from '@store/index';
import { EndUserFilterStore } from '@store/memberList';
import { useInfiniteScroll } from '@components/common';
import { Wrapper } from '@components/common/Wrapper/wrapper';
import { useAllEndUserQuery } from '@services/hooks/endusers/allEndUserQuery';
import { GetMemberListData } from '@services/hooks/endusers/useMembersListRxDb';
import { useComponentLevelAccessCheck } from '../../../../authGuard/hooks/useComponentLevelAccessCheck';

export const AllEndUserList = (): ReactElement => {
	const { isFetching: queryFetching, error } = useAllEndUserQuery();
	const { updateSearch, updateSortObject, updateFilterObject, updateSortValue, updateSortActiveStatus, filterObject } =
		useFilterStore((state) => state);
	const { allEndUserFilter, updateAllEndUserFilter } = EndUserFilterStore((state) => state);
	const { fetchMore, isFetching, result } = GetMemberListData('all');
	const checkSubContractorPermission = useComponentLevelAccessCheck('sub-contractor');

	useEffect(() => {
		updateSortObject([
			{ value: 'priority', name: 'Priority Order' },
			{ value: 'glue_consumption_per_month', name: 'Glue Potential' },
			{ value: 'pwg_banking_qtd', name: 'PWG Banking in Quarter' },
		]);
		updateSortValue('');
		updateSortActiveStatus(false);
		updateSearch('');
		if (allEndUserFilter === null) {
			updateFilterObject({
				...initialAllEndUsersFilter,
				customToggle: {
					flagged: true,
					data: [
						{ value: 'OB' },
						{ value: 'ZBC' },
						{ value: 'Degrowing' },
						{ value: 'Adopted' },
						{ value: 'Disengaged' },
						...(!checkSubContractorPermission ? [{ value: 'Sub Contractor' }] : []),
					],
					toggleData: [],
				},
				largeContractor: {
					flagged: true,
					name: 'Large Contractor',
					dropDownData: '',
					value: '',
				},
			});
			return;
		}
		updateFilterObject(allEndUserFilter);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		updateAllEndUserFilter(filterObject);
	}, [filterObject, updateAllEndUserFilter]);

	useInfiniteScroll(() => {
		fetchMore();
	});

	return (
		<Wrapper isLoaderVisible={queryFetching || (isFetching && result.length === 0)} isError={error}>
			<div className="user-card-list-wrapper">
				<ul className="user-card-list row">
					{result.length > 0 ? (
						result.map((endUser, index) => {
							return <UserCard key={endUser?._data.id} userObject={endUser._data} index={index} state={'/all'} />;
						})
					) : (
						<li className="d-col d-col-2">
							<p className="h3">No Data Found!</p>
						</li>
					)}
				</ul>
			</div>
		</Wrapper>
	);
};
