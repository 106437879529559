import { AdoptedContractorObject } from '../adoptedContractorList';
import { AllEndUserObject } from '../allEndUserList';
import { LeadUserObject } from '../leadList';

export interface IUserCard {
	index: number;
	userObject: AdoptedContractorObject | LeadUserObject | AllEndUserObject;
	state: string;
}

export enum EnrollFormSection {
	BASIC_DETAILS = 'basic details',
	KYC_DETAILS = 'kyc details',
	MORE_DETAILS = 'more details',
}

enum EnrollFormPath {
	BASIC_DETAILS = '/edit-member-details/basic-details',
	KYC_DETAILS = '/edit-member-details/kyc-details',
	MORE_DETAILS = '/edit-member-details/more-details',
}

interface EnrollFormRedirection {
	path: EnrollFormPath;
	activeTabs: number[];
}

export const enrollFormRedirection: Record<EnrollFormSection, EnrollFormRedirection> = {
	[EnrollFormSection.BASIC_DETAILS]: {
		path: EnrollFormPath.BASIC_DETAILS,
		activeTabs: [0],
	},
	[EnrollFormSection.KYC_DETAILS]: {
		path: EnrollFormPath.KYC_DETAILS,
		activeTabs: [0, 1],
	},
	[EnrollFormSection.MORE_DETAILS]: {
		path: EnrollFormPath.MORE_DETAILS,
		activeTabs: [0, 1, 2],
	},
};

export const toEnrollFormSection = (status: string): EnrollFormSection | undefined => {
	return Object.values(EnrollFormSection).find((section) => section.toLowerCase() === status.toLowerCase());
};
