export * from './siteLeads';

export interface ISiteLeadConfigurations {
	OTP_FLOW: boolean;
	QR_FLOW: boolean;
}
export const SiteLeadConfigurations: ISiteLeadConfigurations = {
	OTP_FLOW: false,
	QR_FLOW: true,
};
