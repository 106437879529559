import { MoreDetails } from '@services/hooks/enduser-details';
import { FormikValues } from 'formik';

export const defaultPrimaryDetails = [{ id: 0, dealer_code: null, dealer_name: null }];
export const defaultCompetitionDetails = [{ id: 0, competition_brands: null, competition_volume: null }];
export const defaultAccordianDetails = [{ id: 0, isActive: false, name: '', number: '' }];
export interface IMoredetailIntialValue {
	primary_dealer_code: string;
	primary_dealer_name: string;
	carpenter_no: number | null;
	average_glue_potential: number | null;
	brands_competition: string;
	volume_competition: string;
	workshop_checkbox: boolean;
	factory_checkbox: boolean;
	primary_details: TPrimaryDetails[];
	competition_details: TCompetitionDetails[];
	supervisor_details: TSupervisorDetails[];
	unit_lead_details: TAccordianDetails[];
	architect_details: TAccordianDetails[];
	influencer_details: TAccordianDetails[];
	towns: string[];
	workshop_town: string | null;
	workshop_zipcode: number | null;
	workshop_street: string | null;
	workshop_city: string | null;
	workshop_state: string | null;
	workshop_address: string | null;
	competition_brands_list: CompetitionBrands[];
	factory_address: string | null;
	factory_zipcode: number | null;
	factory_city: string | null;
	factory_state: string | null;
	enroll_fnb_star?: boolean;
}

export const defaultMoreDetailFormInitialValues: IMoredetailIntialValue = {
	primary_dealer_code: '',
	primary_dealer_name: '',
	competition_brands_list: [],
	carpenter_no: null,
	average_glue_potential: null,
	brands_competition: '',
	volume_competition: '',
	workshop_checkbox: false,
	primary_details: defaultPrimaryDetails,
	competition_details: defaultCompetitionDetails,
	supervisor_details: [{ id: 0, supervisor_name: '', supervisor_number: '' }],
	unit_lead_details: defaultAccordianDetails,
	architect_details: defaultAccordianDetails,
	influencer_details: defaultAccordianDetails,
	towns: [],
	workshop_town: '',
	workshop_zipcode: null,
	workshop_street: '',
	workshop_city: '',
	workshop_state: '',
	workshop_address: '',

	factory_checkbox: false,
	factory_address: '',
	factory_zipcode: null,
	factory_city: '',
	factory_state: '',
	enroll_fnb_star: false,
};

export type DealersList = {
	id: number | string;
	value: string;
	name: string;
};
export type CompetitionBrands = {
	id: number;
	value: string;
};

export type TPrimaryDetails = {
	id: number;
	dealer_code: string | null;
	dealer_name: string | null;
};

export type TCompetitionDetails = {
	id: number;
	competition_brands: string | null;
	competition_volume: number | null;
};
export type TSupervisorDetails = {
	id: number;
	supervisor_name: string;
	supervisor_number: string;
};
export type TAccordianDetails = {
	id: number;
	name: string | null;
	number: string | null;
	isActive: boolean;
};
export type TFormik = {
	values: FormikValues;
	setFieldValue: (
		field: string,
		value: string | string[] | boolean | undefined | number,
		shouldValidate?: boolean
	) => void;
	touched: FormikValues;
	errors: FormikValues;
	handleBlur?: (e: React.FocusEvent<HTMLInputElement>) => void;
};

export interface IPrimaryDealerProps {
	formik: TFormik;
	primaryListIndex: number;
	dealer?: TPrimaryDetails;
	fieldName: string;
	isEditing: boolean;
}

export interface ICompetitionDetailsProps {
	formik: TFormik;
	competitionListIndex: number;
	competition?: TCompetitionDetails;
	fieldName: string;
	isEditing: boolean;
}

export interface ISupervisorDetailProps {
	formik: TFormik;
	supervisorListIndex: number;
	supervisor?: TSupervisorDetails;
	fieldName: string;
}

export interface IArchitectDetailProps {
	formik: TFormik;
	accordianListIndex: number;
	data?: TAccordianDetails;
	title?: string;
	accordianName: string;
	isAddition?: boolean;
	isEditing?: boolean;
}

export interface DealerDetail {
	id: number;
	dealer_code: string;
	dealer_name: string;
}

export interface DealerDetailConfig {
	id: number;
	codeKey: keyof MoreDetails;
	nameKey: keyof MoreDetails;
}

export interface CompetitionDetail {
	id: number;
	competition_brands: string | null;
	competition_volume: number | null;
}
export interface AccordianDetail {
	id: number;
	name: string | null;
	number: string | null;
	isActive: boolean;
}

export const toNumberOrNull = (value: string | number | null): number | null => {
	if (value === null || value === undefined || value === '') {
		return null;
	}

	const numValue = Number(value);
	return isNaN(numValue) ? null : numValue;
};
export interface IWorkshopFields {
	workshop_address: boolean;
	workshop_zipcode: boolean;
	workshop_city: boolean;
	workshop_street: boolean;
	workshop_state: boolean;
}

export const WorkshopFields: Array<keyof IWorkshopFields> = [
	'workshop_address',
	'workshop_zipcode',
	'workshop_city',
	'workshop_street',
	'workshop_state',
];

export interface IFactoryFields {
	factory_address: boolean;
	factory_city: boolean;
	factory_state: boolean;
	factory_zipcode: boolean;
}

export const FactoryFields: Array<keyof IFactoryFields> = [
	'factory_address',
	'factory_city',
	'factory_state',
	'factory_zipcode',
];
