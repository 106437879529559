/* eslint-disable */
import { Workbox } from 'workbox-window';

// TODO Need to remove console once code freezing is completed
// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
const promptForUpdate = () => {
	return new Promise((resolve) => {
		const confirmation = window.confirm('New update available. Do you want to update?');
		console.log('User response to update prompt:', confirmation);
		resolve(confirmation);
	});
};

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export const checkForUpdates = () => {
	if (navigator?.serviceWorker?.controller) {
		navigator.serviceWorker.controller.postMessage({ type: 'CHECK_FOR_UPDATES' });
		console.log('Sent CHECK_FOR_UPDATES message to service worker');
	}
};

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
const register = () => {
	if ('production' !== process.env.NODE_ENV) {
		return;
	}

	if ('serviceWorker' in navigator) {
		console.log('Service Worker is supported');
		const wb = new Workbox('/service-worker.js');

		let refreshing = false;

		navigator.serviceWorker.addEventListener('controllerchange', () => {
			if (!refreshing) {
				refreshing = true;
				console.log('Controller changed, reloading page');
				window.location.reload();
			}
		});

		wb.addEventListener('waiting', async (event) => {
			console.log('New service worker is waiting');
			const updateAccepted = await promptForUpdate();
			if (updateAccepted) {
				console.log('Update accepted, skipping waiting');
				wb.messageSkipWaiting();
			}
		});

		wb.addEventListener('controlling', (event) => {
			console.log('Service worker is now controlling');
		});

		wb.register()
			.then((registration) => {
				console.log('Service Worker registered successfully:', registration);
				registration.update();
			})
			.catch((error) => {
				console.error('Service Worker registration failed:', error);
			});
	}
};

export { register };
