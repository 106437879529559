import { ReactElement, useEffect, useState } from 'react';
import { IBDEOnLeaveModalProps } from '@components/checksAndVerification/index';

export const BDEOnLeaveModal = (props: IBDEOnLeaveModalProps): ReactElement => {
	const { handleModalClose } = props;
	const [disableButton, setDisableButton] = useState<boolean>(false);

	useEffect(() => {
		function applyBlockClickEvent(remove = false): void {
			if (remove) {
				document.body.classList.remove('bde-on-leave-modal-block-site');
				return;
			}
			document.body.classList.add('bde-on-leave-modal-block-site');
		}

		applyBlockClickEvent();
		return (): void => applyBlockClickEvent(true);
	}, []);

	return (
		<div className="bde-on-leave-modal">
			<div className="bde-on-leave-modal-note-content">
				<p>MPower Access is blocked for you by your RBDM, please reach out to your RBDM for any issue.</p>
			</div>
			<div className="bde-on-leave-modal-btn-wrapper">
				<button
					type="button"
					disabled={disableButton}
					className="btn btn-primary"
					onClick={() => {
						setDisableButton(true);
						handleModalClose();
					}}
				>
					Click here for logout
				</button>
			</div>
		</div>
	);
};
