import { ReactElement, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { INavigatorTab, ITabList } from '@components/common';
import { useEndUserToggle } from '@hooks/useEndUserToggle';
import { useFilterStore } from '@store/index';
import { NavigatorLink } from '@components/common/navigator-tab/components';

export const NavigatorTab = (props: INavigatorTab): ReactElement => {
	const { tabList, children, selectedFilterChild } = props;
	const { pathname } = useLocation();
	const { toggleTab } = useEndUserToggle();
	const { updateSearch } = useFilterStore();

	useEffect(() => {
		toggleTab();
		window.addEventListener('resize', toggleTab);
	}, [pathname, toggleTab]);

	const handleResetSearch = (): void => {
		updateSearch('');
	};

	return (
		<>
			<div className="tab-search-wrapper">
				<div className="tab-wrapper">
					<div className="tab-main">
						<div className="tab-inner">
							<ul className="tab">
								{tabList.map((tab: ITabList, index: number) => {
									return <NavigatorLink key={tab.key} tab={tab} index={index} handleResetSearch={handleResetSearch} />;
								})}
							</ul>
							<div className="sliding-indicator"></div>
						</div>
					</div>
				</div>
				{children}
			</div>
			{selectedFilterChild}
		</>
	);
};
