import { ReactElement, useEffect, useRef, useState } from 'react';
import { IDashboardMeeting } from '.';
import { DotSlider } from '@components/common';
import { MeetingsData, useMeetingsQuery } from '@services/hooks/dashboard/useMeetingsQuery';
import { convertTimeTo12HourFormat } from '@helper/utils';
import barcodePng from '@assets/images/barcode.png';
import barcodeWebp from '@assets/images/barcode.webp';
import { NavLink } from 'react-router-dom';
import { Modal } from '@components/common/modal/modal';
import { QrModal } from '@components/dashboard/components/meeting/qrModal';
import { Wrapper } from '@components/common/Wrapper/wrapper';
import { useComponentLevelAccessCheck } from '../../../../authGuard/hooks/useComponentLevelAccessCheck';

export const Meeting = (props: IDashboardMeeting): ReactElement => {
	const { id } = props;
	const [modalOpen, setModalOpen] = useState<boolean>(false);
	const [modalContent, setModalContent] = useState<MeetingsData | null>(null);
	const bannerSliderRef = useRef<HTMLDivElement>(null);
	const dotSliderRef = useRef<HTMLDivElement>(null);
	const leftButtonRef = useRef<HTMLButtonElement>(null);
	const rightButtonRef = useRef<HTMLButtonElement>(null);
	const activeSliderIndex = [0, 1];
	const { data, error, isPending } = useMeetingsQuery();
	const checkPermissions = useComponentLevelAccessCheck('meeting-banner');

	const onBarcodeClick = (meeting: MeetingsData): void => {
		setModalContent(meeting);
		setModalOpen(true);
	};

	useEffect(() => {
		if (!data) return;

		const powerBIEl = document.querySelector('.power-bi-button-section');
		if (!powerBIEl) return;
		powerBIEl.classList.remove('move-above');

		if (!data || data.length === 0) {
			powerBIEl.classList.add('move-above');
		}
	}, [data]);

	if (!checkPermissions) {
		return <></>;
	}

	if (!data || data?.length === 0) {
		return <></>;
	}

	return (
		<Wrapper isLoaderVisible={isPending} isError={error}>
			<>
				{data && (
					<section key={id} className="meeting-wrapper">
						<div className={`slider-wrapper ${data.length === 1 ? 'single-slider' : ''}`}>
							<div className="slider" ref={bannerSliderRef}>
								{data?.map((meeting, index) => (
									<div
										key={meeting.id + index}
										className={`meeting_slide meeting-item ${activeSliderIndex.includes(index) ? 'active' : ''}`}
										id={`meeting_slide${index}`}
									>
										<div className="meeting-left">
											<p className="h3" title={meeting.name}>
												{meeting.name}
											</p>
											<span className="meet-address" title={meeting.meeting_venue_c}>
												{meeting.meeting_venue_c}
											</span>
											<span className="meet-timing">
												{meeting.status}, {convertTimeTo12HourFormat(meeting.start_time_c)}
											</span>
										</div>
										<div className="meeting-right">
											<div className="meet-code">
												<button
													type="button"
													onClick={() => onBarcodeClick(meeting)}
													className="pointer"
													aria-label="barcode"
												>
													<picture>
														<source srcSet={barcodeWebp} type="image/webp" />
														<source srcSet={barcodePng} type="image/png" />
														<img src={barcodePng} alt="barcode" title="barcode" width="16" height="17" />
													</picture>
												</button>
											</div>
											<div className="edit-meet">
												<NavLink
													to="/today-meetings"
													aria-label="Edit Meeting"
													state={{ id: meeting.id, status: 'today-meeting' }}
												>
													Edit Meeting <span className="icon-edit" />
												</NavLink>
											</div>
										</div>
									</div>
								))}
							</div>
							{data.length > 2 && (
								<>
									<button
										type="button"
										aria-label="next-slide-button"
										className="prev-btn"
										value="next-slide-button"
										ref={leftButtonRef}
									>
										<span className="icon-arrow-left-circle"></span>
									</button>
									<button
										type="button"
										aria-label="next-slide-button"
										className="next-btn"
										value="next-slide-button"
										ref={rightButtonRef}
									>
										<span className="icon-arrow-right-circle"></span>
									</button>
									<div className="slider-nav2" ref={dotSliderRef}>
										<DotSlider
											sliderId="meeting_slide"
											bannerData={data}
											bannerSliderRef={bannerSliderRef}
											dotSliderRef={dotSliderRef}
											leftButtonRef={leftButtonRef}
											rightButtonRef={rightButtonRef}
											isMultiple={true}
										/>
									</div>
								</>
							)}
						</div>
					</section>
				)}
				{modalContent && (
					<Modal
						modalId="popupDialog"
						className="dialog-sm"
						modalOpen={modalOpen}
						onModalClose={() => setModalOpen(false)}
					>
						<QrModal meetingData={modalContent} modalClose={() => setModalOpen(false)} />
					</Modal>
				)}
			</>
		</Wrapper>
	);
};
