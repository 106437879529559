export * from './endUserOtpModal';

export interface IEndUserOtpModalProps {
	onClose: () => void;
	onSubmit: () => void;
	primaryNumber: string;
	setVerifyDetails: (isopen: boolean) => void;
	sendOtp: () => void;
}
export interface IEndUserGenarateOtpProps {
	primaryNumber: string;
	setVerifyDetails: (isopen: boolean) => void;
	openOtpModal: boolean;
	setOpenOtpModal: (isopen: boolean) => void;
}
export const initialFormikEndUserOtpFormDetails = {
	otp: '',
	phone_number: '',
};
