import { FilterObject } from '@store/memberList';
import { initialFilterObject } from '@store/index';
export interface AllEndUserData {
	data: AllEndUserObject[];
}

export interface AllEndUserObject {
	id: number;
	sfid: string;
	name: string;
	membershipno: string;
	glue_consumption_per_month: number;
	createddate: string;
	phone: string;
	verification_status: string;
	clubname: string;
	is_a_ob: boolean;
	kyc_id_proof_verification: string;
	competitionconsumption: number;
	priority: number;
	totalbankingQTD: number;
	visitstatus: string;
	dateofvisit: string;
	contractorconsumption: string;
	currenttier: string;
	lastactivitydate: string;
	pwg_banking_qtd: number;
	status: string;
	source?: string;
	contractorclass?: string;
	primarydealercode?: string;
	banking_six_month_kgs: string;
	total_points_per_kgs: string;
	banking_points_qtd: string;
	club_mapping: string;
	is_zbc: boolean;
	is_de_growing: boolean;
	clustercode_c: string;
	clustername_c: string;
	lastmodifieddate: string;
	personcontactid: string;
	is_adopted_by: boolean;
}

export interface AllEndUserFilter {
	createddate?: { $gte: string; $lte: string };
	visitstatus?: { $eq: string };
	contractorclass?: { $in: string[] };
	kyc_id_proof_verification?: { $eq: string };
	clubname?: { $in: string[] };
	contractorconsumption?: { $in: string[] };
	is_a_ob?: { $eq: boolean };
	pwg_banking_qtd?: { $eq: string };
}

export interface AllEndUserSearchFilter {
	$or?: (
		| { name: { $regex: string; $options: string } }
		| { phone: { $regex: string; $options: string } }
		| { membershipno: { $regex: string; $options: string } }
	)[];
}

export interface AllEndUserResultType {
	result: { _data: AllEndUserObject }[];
	isFetching: boolean;
	fetchMore: () => void;
}

export const CONTRACTOR_CLASS = [{ value: 'XLC' }, { value: 'VLC' }, { value: 'LC' }, { value: 'RC' }, { value: 'SC' }];

export const initialAllEndUsersFilter: FilterObject = {
	...initialFilterObject,
	dateOfVisit: { flagged: true, startDate: null, endDate: null },
	visitStatus: { flagged: true, visitStatus: '' },
	customSelect: { flagged: true, customData: [] },
	customToggle: { flagged: true, data: [{ value: 'OB' }, { value: 'ZBC' }, { value: 'Degrowing' }], toggleData: [] },
	checkSecondary: [
		{
			flagged: true,
			name: 'Contractor Class',
			data: CONTRACTOR_CLASS,
			secondaryData: [],
		},
		{
			flagged: true,
			name: 'Contractor Competition Class',
			data: [{ value: 'XLC-C' }, { value: 'VLC-C' }, { value: 'LC-C' }, { value: 'RC-C' }, { value: 'SC-C' }],
			secondaryData: [],
		},
	],

	kycStatus: { flagged: true, kycStatus: '' },
};
