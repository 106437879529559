import { create } from 'zustand';
import { getDaysBetweenDates } from '@helper/dateFormat';

interface IConformationProps {
	end_user_profiling_freq: number;
	basic_details_updated: Date;
	more_details_updated: Date;
}

interface IEndUserProfileStore {
	isConformationNeeded: boolean;
	basicDetailsRevalidate: boolean;
	moreDetailsRevalidate: boolean;
	setKeyBaseValue: <T>(key: string, value: T) => void;
	setConformationNeeded: (props: IConformationProps) => void;
}

const initialState = {
	isConformationNeeded: false,
	basicDetailsRevalidate: false,
	moreDetailsRevalidate: false,
};

export const useEndUserProfileStore = create<IEndUserProfileStore>((set) => ({
	...initialState,
	setConformationNeeded: (props: IConformationProps): void =>
		set((state) => {
			const { end_user_profiling_freq, more_details_updated, basic_details_updated } = props;
			const lastBasicUpdateDays = getDaysBetweenDates(basic_details_updated);
			const lastMoreDetailsUpdateDays = getDaysBetweenDates(more_details_updated);
			if (lastBasicUpdateDays > end_user_profiling_freq || lastMoreDetailsUpdateDays > end_user_profiling_freq) {
				return {
					...state,
					isConformationNeeded: true,
				};
			}
			return {
				...state,
				isConformationNeeded: false,
			};
		}),
	setKeyBaseValue: <T,>(key: string, value: T): void =>
		set((state) => ({
			...state,
			[key]: value,
		})),
}));
