import { ReactElement, useEffect, useState } from 'react';
import { convertTimeTo12HourFormat } from '@helper/utils';
import { IQrModal } from '@components/dashboard/components/meeting/index';
import QRCode from 'react-qr-code';
export const QrModal = (props: IQrModal): ReactElement => {
	const { meetingData, modalClose, qrId, isCloseVisible = true } = props;

	const [qrValue, setQrValue] = useState<string>((meetingData?.sfid ?? qrId) + '_' + Date.now());

	useEffect(() => {
		const interval = setInterval((): void => {
			return setQrValue((meetingData?.sfid ?? qrId) + '_' + Date.now());
		}, 5000);
		return (): void => clearInterval(interval);
	}, [meetingData?.sfid, qrId]);

	return (
		<div className="qr-code-wrapper">
			{(meetingData?.sfid || qrId) && <QRCode value={qrValue} size={200} style={{ height: '200px' }} />}

			{meetingData && <p className="h3">{meetingData?.name}</p>}

			{meetingData && (
				<span className="date">
					{meetingData?.status && `${meetingData?.status}, `}
					{convertTimeTo12HourFormat(meetingData?.start_time_c)}
				</span>
			)}

			{isCloseVisible && (
				<button className="close" onClick={modalClose}>
					Close
				</button>
			)}
		</div>
	);
};
