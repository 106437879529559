import { IRxdbModel } from '@rxdb/config/rxdbModel';
import { MemberObject } from '@services/hooks/endusers/index';
import { useRxCountQuery } from '@hooks/useCount';
import { useRxData } from 'rxdb-hooks';
import { MangoQuery, RxCollection } from 'rxdb';
import { useCallback, useMemo } from 'react';

export interface IMemberFilterProps {
	searchValue: string;
	contractorclass: string[];
	subcontractor?: boolean;
	parent_member_id_c?: string | null;
	viewMode?: boolean;
}
export const useGetMemberByFilterOptions = (props: IMemberFilterProps): IRxdbModel<MemberObject> => {
	const { searchValue, contractorclass, subcontractor = false, parent_member_id_c = null, viewMode = false } = props;

	const queryConstructor = useCallback(
		(collection: RxCollection<MemberObject>) => {
			const querySelector: MangoQuery<MemberObject> = {
				selector: {
					$or: [
						{
							name: { $regex: `.*${searchValue}.*`, $options: 'i' },
						},
						{
							phone: { $regex: `.*${searchValue}.*`, $options: 'i' },
						},
					],
					...(contractorclass?.length !== 0 && { contractorclass: { $in: contractorclass } }),
					...(subcontractor && {
						glue_consumption_per_month: { $lte: 300 },
						$and: [
							{
								$or: [
									...(!viewMode ? [{ parent_member_id_c: { $exists: false } }] : []),
									...(!viewMode ? [{ parent_member_id_c: { $eq: null } }] : []),
									{ parent_member_id_c: { $eq: parent_member_id_c } },
								],
							},
						],
					}),
				},
				sort: [{ name: 'asc' }],
			};

			return collection.find(querySelector);
		},
		[contractorclass, parent_member_id_c, searchValue, subcontractor, viewMode]
	);

	const totalCount = useRxCountQuery<MemberObject>('all', queryConstructor);

	const { result, isFetching, fetchMore, resetList } = useRxData<MemberObject>('all', queryConstructor, {
		pageSize: 20,
		pagination: 'Infinite',
	});
	const options = useMemo(() => {
		if (!result || (result.length === 0 && !subcontractor)) return [];
		if (result && subcontractor) {
			return result.map((contractor) => ({
				id: contractor.membershipno,
				value: `${contractor.name}`,
			}));
		} else {
			return result.map((contractor) => ({
				id: contractor.phone.toString() ?? '0',
				value: `${contractor.name} - ${contractor.phone.toString()}`,
			}));
		}
	}, [result]);

	return {
		result,
		isFetching,
		fetchMore,
		totalCount,
		resetList,
		modifiedResult: options,
	};
};
