import { APIService } from '@services/api/common-services';
import { UseMutateFunction, useMutation } from '@tanstack/react-query';
import { APICONFIG } from '@config/api.config';
import { ISupervisorDetailResponse, ISupervisorEditPayload, ISupervisorPayload } from '@components/supervisorDetails';

interface ISupervisorData {
	mutate: UseMutateFunction<ISupervisorDetailResponse, Error, ISupervisorPayload, unknown>;
	error: Error | null;
	isError: boolean;
	isPending: boolean;
}

export const useSupervisorMutation = (supervisorId: number): ISupervisorData => {
	const createSupervisor = async (supervisorData: ISupervisorPayload): Promise<ISupervisorDetailResponse> => {
		return await APIService.postData<ISupervisorDetailResponse>(`${APICONFIG.SUPERVISOR_ADD_UPDATE}`, supervisorData);
	};

	const updateSupervisor = async (supervisorData: ISupervisorEditPayload): Promise<ISupervisorDetailResponse> => {
		return await APIService.putData<ISupervisorDetailResponse>(
			`${APICONFIG.SUPERVISOR_LIST_BY_CONTACT}${supervisorId}/`,
			supervisorData
		);
	};

	const { mutate, error, isError, isPending } = useMutation({
		mutationFn: supervisorId ? updateSupervisor : createSupervisor,
	});

	return {
		mutate,
		error,
		isError,
		isPending,
	};
};
