import { ReactElement, useRef } from 'react';
import { BannerData, IDashboardBanner } from '.';
import { DotSlider } from '@components/common';
import { useBannerQuery } from '@services/hooks/dashboard/useBannerQuery';
import { NavLink } from 'react-router-dom';
import { onImageError } from '@helper/utils';
import { Wrapper } from '@components/common/Wrapper/wrapper';
import { useRxData } from 'rxdb-hooks';

export const Banner = (props: IDashboardBanner): ReactElement => {
	const { id } = props;
	const bannerSliderRef = useRef<HTMLDivElement>(null);
	const dotSliderRef = useRef<HTMLDivElement>(null);

	const { error } = useBannerQuery();
	const { result: data, isFetching } = useRxData<BannerData>('dashboard_banner', (collection) => collection.find());

	return (
		<section key={id} className="banner-wrapper">
			<Wrapper isLoaderVisible={isFetching} isError={error}>
				<div className="container">
					<div className="slider-wrapper">
						<div className="slider" ref={bannerSliderRef}>
							{data?.map((banner: BannerData, index: number) => (
								<NavLink
									key={banner.id}
									to={banner.redirect_link_c}
									aria-label={`${banner.name}`}
									id={'meeting_slide' + index}
									className="banner_slide"
									data-slide={`slide-${index + 1}`}
									target="_blank"
								>
									<img
										role="presentation"
										id={`slide-${banner.name}`}
										src={banner.banner_url_c}
										alt={`${banner.name}`}
										title={`${banner.name}`}
										onError={onImageError}
										width="1288"
										height="240"
									/>
								</NavLink>
							))}
						</div>
						<div className="slider-nav" ref={dotSliderRef}>
							<DotSlider
								sliderId="banner_slide"
								bannerData={data}
								bannerSliderRef={bannerSliderRef}
								dotSliderRef={dotSliderRef}
								isMultiple={false}
							/>
						</div>
					</div>
				</div>
			</Wrapper>
		</section>
	);
};
