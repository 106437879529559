import { useFilterStore } from '@store/filter';
import { ReactElement, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

export interface Sort {
	setSortOpen: () => void;
}

export interface ISortOptions {
	name: string;
	value: string;
}

export const Sort = (props: Sort): ReactElement => {
	const [sortData, setSortData] = useState<string>('');
	const { sortValue, sortObject, updateSortValue, updateSortActiveStatus } = useFilterStore((state) => state);
	const { setSortOpen } = props;
	const { pathname } = useLocation();
	const handleChildClick = (e: React.MouseEvent<HTMLDivElement, MouseEvent>): void => {
		e.stopPropagation();
	};
	const handleSubmit = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>): void => {
		e.preventDefault();
		updateSortValue(sortData);
		setSortOpen();
		sortData && updateSortActiveStatus(true);
	};

	useEffect(() => {
		const el = document.getElementById('sort-main-wrapper') as HTMLElement;
		if (el.className.includes('show')) {
			el.classList.remove('show');
		}
		setTimeout(() => {
			el.classList.add('show');
		}, 0);
		const overlay = document.getElementById('overlay') as HTMLElement;
		document.body.classList.add('overflow-hidden');
		overlay.classList.add('show');
	}, []);

	useEffect(() => {
		setSortData(sortValue);
	}, [sortValue]);

	const handleResetHandler = (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>): void => {
		e.preventDefault();
		updateSortValue('');
		updateSortActiveStatus(false);
		setSortOpen();
	};

	return (
		<div
			role="button"
			tabIndex={0}
			className="filter-drawer-wrapper filter-contractor-drawer show-overlay"
			id="sort-main-wrapper"
			onClick={setSortOpen}
		>
			<div className="container">
				<button
					type="button"
					aria-label="sort popup"
					className="filter-sort-icon icon-slider"
					onClick={setSortOpen}
				></button>
				<div
					role="button"
					tabIndex={0}
					className="filter-drawer-inner-wrapper sorting"
					onClick={(e) => handleChildClick(e)}
				>
					<div className="filter-title">
						<p className="h2">Sort</p>
						<a
							href={pathname}
							aria-label="Clear All"
							onClick={(e) => handleResetHandler(e)}
							hidden={sortData.length === 0}
						>
							Clear All
						</a>
					</div>
					<form>
						<div className="sorting-wrapper-form">
							<div>
								{sortObject.map((item, index) => {
									return (
										<div className="cmn-radio" key={item.value + '_' + index}>
											<input
												type="radio"
												id={item.value}
												value={item.value}
												onChange={(e) => {
													setSortData(e.target.id);
												}}
												checked={sortData === item.value}
											/>
											<label htmlFor={item.value}>{item.name}</label>
										</div>
									);
								})}
							</div>
						</div>
					</form>

					<div className="submit btn-sort">
						<button
							className="btn btn-primary btn-medium filter-sort-submit-btn"
							onClick={handleSubmit}
							disabled={sortData.length === 0}
						>
							Submit
						</button>
					</div>
				</div>
			</div>
		</div>
	);
};
