import { AdoptedContractorDetails, ISiteVisits, SiteSummaryDetails } from '@services/hooks/contractor360';
import { SiteLeadOverStateProps } from '@components/SiteLeadOverview';

export interface IContractorSites {
	data: AdoptedContractorDetails | SiteLeadOverStateProps | undefined;
	isGetContractorDetailFetching?: boolean;
	error: Error | null;
	allSites: SiteSummaryDetails[] | undefined;
	isSiteSummaryFetching?: boolean;
}

export interface ISite {
	site: SiteSummaryDetails;
	siteIndex: number;
	membershipno?: string | null;
	handleEndUserProfileVerificationStage?: (fn: () => void) => void;
	contractor_name?: string | null;
}

export const getSortedVisits = (visits: ISiteVisits[] = []): ISiteVisits[] => {
	return [...visits].sort((a, b) => {
		const dateA = a?.activitydatetime ? new Date(a.activitydatetime) : null;
		const dateB = b?.activitydatetime ? new Date(b.activitydatetime) : null;
		if (!dateA) return 1;
		if (!dateB) return -1;
		return dateB.getTime() - dateA.getTime();
	});
};

export const getDraftSite = (site: SiteSummaryDetails): boolean =>
	site?.current_stage_of_site_c?.toLowerCase() === 'draft' ||
	(site?.current_stage_of_site_c?.toLowerCase() === 'site visited' &&
		site?.visits?.length > 0 &&
		getSortedVisits(site.visits)?.[0].site_visit_status?.toLowerCase() === 'draft');
