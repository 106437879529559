import { ReactElement, useEffect, useMemo, useRef, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useRedemptionHistoryQuery } from '@services/hooks/contractor360/useRedemptionHistoryQuery';
import { formatDateDDMMYY } from '@helper/utils';
import { Wrapper } from '@components/common/Wrapper/wrapper';
import { BankingHistoryObject, FilterObject, PAGE_LIMIT_BANKING } from '.';
import { useInfiniteScroll } from '@components/common';
import { Value } from '@wojtekmaj/react-daterange-picker/dist/cjs/shared/types';
import { BankingHistoryFilter } from './bankingHistoryFilter';
import { BankingData } from './bankingCard';
import '@assets/styles/pages/banking-history.scss';

export const BankingHistory = (): ReactElement => {
	const [bankingHistoryData, setBankingHistoryData] = useState<BankingHistoryObject[]>([]);
	const [date, setDate] = useState<Value>([null, null]);
	const { state } = useLocation();
	const [filter, setFilter] = useState<FilterObject>({
		limit: PAGE_LIMIT_BANKING,
		offset: 0,
		search: '',
	});
	const bankingRef = useRef(null);
	const startDate = useMemo(() => (Array.isArray(date) ? date[0] : null), [date]);
	const endDate = useMemo(() => (Array.isArray(date) ? date[1] : null), [date]);

	const { data, isFetching, error } = useRedemptionHistoryQuery({
		startDate: startDate,
		endDate: endDate,
		limit: PAGE_LIMIT_BANKING,
		mobile: state?.number,
		search: filter.search,
		offset: (filter.offset + 1) * PAGE_LIMIT_BANKING - PAGE_LIMIT_BANKING,
	});

	useInfiniteScroll(() => {
		const calculateOffset = (filter.offset + 1) * PAGE_LIMIT_BANKING - PAGE_LIMIT_BANKING;
		if (data && PAGE_LIMIT_BANKING > +data?.response?.customer?.count) {
			return;
		}
		if (data && calculateOffset < +data?.response?.customer?.count) {
			setFilter((prevFilter) => ({
				...prevFilter,
				offset: prevFilter.offset + 1,
			}));
		}
	}, bankingRef || null);

	const handleSearch = (value: string): void => {
		setFilter((prevFilter) => ({
			...prevFilter,
			offset: 0,
			search: value,
		}));
		setBankingHistoryData([]);
	};

	const handleDateChange = (date: Value): void => {
		setDate(date);
		setFilter((prevFilter) => ({
			...prevFilter,
			offset: 0,
		}));
		setBankingHistoryData([]);
	};

	const ProcessBankingHistoryData = (): void => {
		const tempBankingData: BankingHistoryObject[] =
			data?.response?.customer?.transactions?.transaction?.flatMap((transaction) => {
				const customFields = new Map(transaction.custom_fields.field.map((field) => [field.name, field.value]));
				const sourceOfCode = customFields.get('source') || '-';
				const grossWeight = customFields.get('gross_weight') || '-';
				const isDebitHistory = customFields.has('order_status');
				const pointsIssued = transaction.points?.issued?.toString() || '0';

				const sourceFound = sourceOfCode !== '-' || pointsIssued !== '0';

				if (!sourceFound || isDebitHistory) return [];

				const date = formatDateDDMMYY(new Date(transaction.billing_time)) || '-';

				return transaction.line_items.line_item.map((item) => {
					let itemDescription = item.description || '-';
					if (!item.description && item.attributes?.attribute?.length) {
						const productDescription = item.attributes.attribute.find((attr) => attr.name === 'ProductDescription');
						if (productDescription) {
							itemDescription = productDescription.value;
						}
					}

					return {
						couponCode: transaction.number,
						productCode: item.item_code || '-',
						codeValue: transaction.points.issued || item.line_item_point_details[0].issued || '-',
						codeUsedOn: date,
						itemName: item.description || itemDescription || '-',
						sourceOfCode,
						kg: grossWeight,
					};
				});
			}) ?? [];

		setBankingHistoryData((prevData) => [...prevData, ...tempBankingData]);
	};
	useEffect(() => {
		ProcessBankingHistoryData();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [data?.response?.customer?.transactions?.transaction]);

	return (
		<section className="banking-history">
			<BankingHistoryFilter
				setSearch={handleSearch}
				date={date}
				setDate={handleDateChange}
				records={{
					length: bankingHistoryData.length,
					count: data?.response?.customer?.count ?? bankingHistoryData.length,
				}}
			/>
			<div className="container">
				<Wrapper isLoaderVisible={isFetching && bankingHistoryData.length == 0} isError={error}>
					<BankingData bankingHistoryData={bankingHistoryData} ref={bankingRef} cardData="banking" />
				</Wrapper>
				<Wrapper isLoaderVisible={isFetching && bankingHistoryData.length !== 0} isError={null}>
					<div></div>
				</Wrapper>
			</div>
		</section>
	);
};
