import { getTownInfo } from '@helper/utils';
import { TownList } from '@services/hooks/enduser-details';
import {
	NumberNullUndefinedUnionType,
	NumberNullUnionType,
	StringNullUndefinedUnionType,
} from '@helper/commonTypesAlias';
import { FormikProps, FormikTouched, FormikValues } from 'formik';
import { initialMoreDetailsValidateField } from '@store/endUserProfileStore';

export const stringValueCheck = (value: StringNullUndefinedUnionType): string => {
	return value ?? '';
};

export const numberValueCheck = (value: NumberNullUnionType | NumberNullUndefinedUnionType): number | null => {
	return value ?? 0;
};

export const formikTouchedCheck = (formik: FormikProps<FormikValues>): FormikTouched<FormikValues> => {
	return Object.keys(formik.values).reduce((acc, key) => {
		acc[key] = true;
		return acc;
	}, {});
};

export const getPermanentTownValueCheck = (
	town_name_c?: string | null,
	town_code_c?: string | null,
	towns?: TownList[]
): string => {
	if (town_name_c && town_code_c && towns) {
		const townInfo = getTownInfo(towns, `${town_name_c} - ${town_code_c}`);
		if (!townInfo) return '';

		return `${town_name_c} - ${town_code_c}`;
	}
	return '';
};

export const checkAllFieldsValid = (validationFields: typeof initialMoreDetailsValidateField): boolean => {
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	return Object.entries(validationFields).every(([_, value]) => {
		if (Array.isArray(value)) {
			return value.every(Boolean);
		}
		return Boolean(value);
	});
};
