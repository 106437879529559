export interface StructuredFeedbackObject {
	id: number;
	url: string;
	label: string;
}

export const StructuredFeedbackObj: StructuredFeedbackObject[] = [
	{
		id: 0,
		url: 'https://forms.office.com/r/fVnqDvZDwB',
		label: 'OB Meet',
	},

	{
		id: 1,
		url: 'https://forms.office.com/r/xE6J3VbEep',
		label: 'Redemption Centre',
	},

	{
		id: 2,
		url: 'https://forms.office.com/r/9sfrn3RqYZ',
		label: 'During User Meet',
	},

	{
		id: 3,
		url: 'https://forms.office.com/r/KaX33kcn15',
		label: 'Fevicol Loyal Users',
	},

	{
		id: 4,
		url: 'https://forms.office.com/r/ABFbnjVbet',
		label: 'Competition User',
	},

	{
		id: 5,
		url: 'https://forms.office.com/r/fCL6V9a2K2',
		label: 'Workshop owner',
	},

	{
		id: 6,
		url: 'https://forms.office.com/r/i1ib48erNa',
		label: 'Mechanized Unit Leads',
	},
	{
		id: 7,
		url: 'https://forms.office.com/r/sBa3ev89MA',
		label: 'Epoxy Usage Survey - WWC',
	},
	{
		id: 8,
		url: 'https://survey.zohopublic.in/zs/uXBgNN',
		label: 'ACR Form',
	},
];
