import { APIService } from '@services/api/common-services';
import { UseMutateFunction, useMutation } from '@tanstack/react-query';
import { APICONFIG } from '@config/api.config';
import { MeetingStatus } from '.';

export interface IMeetingMarkAttendance {
	mutate: UseMutateFunction<MeetingStatus, Error, string, unknown>;
	error: Error | null;
	isError: boolean;
	isPending: boolean;
}

export const useMarkMeetingAttendance = (): IMeetingMarkAttendance => {
	const putMeetingMarkAttendance = async (id: string): Promise<MeetingStatus> => {
		const url = `${APICONFIG.MEETINGS_MARK_ATTENDANCE}${id}/mark-attendance/`;
		return await APIService.putData<MeetingStatus>(url);
	};

	const { mutate, error, isError, isPending } = useMutation({
		mutationFn: putMeetingMarkAttendance,
	});

	return {
		mutate,
		error,
		isError,
		isPending,
	};
};
