import { useRolesPermission } from '@store/rolesPermissionStore';
import { useCallback, useMemo } from 'react';
import { IPermissionCheckForApiCallbackProps } from './index';

export const usePermissionCheckForApiCallback = (
	props?: IPermissionCheckForApiCallbackProps
): {
	permissionForApi: boolean;
	permissionCheckForApi: (props?: IPermissionCheckForApiCallbackProps) => boolean;
} => {
	const { routeName, componentName } = props || {};
	const { permissions } = useRolesPermission();

	const permissionForApi = useMemo((): boolean => {
		if (permissions && permissions.length > 0) {
			if (componentName) {
				return !!permissions.find((route) => route.name === componentName);
			}
			if (routeName) {
				return !!permissions.find((route) => route.route === routeName);
			}
		}
		return true;
	}, [permissions, routeName, componentName]);

	const permissionCheckForApi = useCallback(
		(checkProps?: IPermissionCheckForApiCallbackProps): boolean => {
			const { routeName: checkRouteName, componentName: checkComponentName } = checkProps || {};

			if (permissions && permissions.length > 0) {
				if (checkComponentName) {
					return !!permissions.find((route) => route.name === checkComponentName);
				}
				if (checkRouteName) {
					return !!permissions.find((route) => route.route === checkRouteName);
				}
			}
			return true;
		},
		[permissions]
	);

	return {
		permissionForApi,
		permissionCheckForApi,
	};
};
