import { IQueryResponse } from '@services/api';
import { IEndUserOtpProps, IEndUserOtpResponse } from '@services/hooks/enduser-details/index';
import { ToastType, useTosterStore } from '@store/toster';
import { APIService } from '@services/api/common-services';
import { APICONFIG } from '@config/api.config';
import { ErrorHandler } from '@helper/errorHandler';
import { useQuery } from '@tanstack/react-query';

export const UseEndUserOtpGenerateService = (props: IEndUserOtpProps): IQueryResponse<IEndUserOtpResponse> => {
	const { primary_number } = props;
	const { setToaster } = useTosterStore();
	const fetchEndUserOtp = async (): Promise<IEndUserOtpResponse> => {
		try {
			return await APIService.postData<IEndUserOtpResponse>(APICONFIG.GET_END_USER_OTP, {
				phone: primary_number,
			});
		} catch (error) {
			const errMessage = new Error(error as string);
			setToaster(true, ToastType.error, errMessage.message);
			ErrorHandler(error);
			throw error;
		}
	};

	const { data, error, isLoading, isPending, isFetching, refetch } = useQuery({
		queryKey: ['EndUserOtp'],
		queryFn: fetchEndUserOtp,
		enabled: false,
		refetchOnWindowFocus: false,
		gcTime: 0,
	});

	return {
		data,
		error,
		isLoading,
		isPending,
		isFetching,
		refetch,
	};
};
