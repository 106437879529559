import { IEndUserOtpVerifyProps, IEndUserOtpVerifyResponse } from '@services/hooks/enduser-details/index';
import { IQueryResponse } from '@services/api';
import { ToastType, useTosterStore } from '@store/toster';
import { APIService } from '@services/api/common-services';
import { APICONFIG } from '@config/api.config';
import { useQuery } from '@tanstack/react-query';

export const UseEndUserOtpVerifyService = (
	props: IEndUserOtpVerifyProps
): IQueryResponse<IEndUserOtpVerifyResponse> => {
	const { otp, phone_number } = props;
	const { setToaster } = useTosterStore();
	const submitOtpDetails = async (): Promise<IEndUserOtpVerifyResponse> => {
		try {
			return await APIService.getData<IEndUserOtpVerifyResponse>(APICONFIG.VERIFY_END_USER_OTP, {
				otp: otp,
				phone: phone_number,
			});
		} catch (error) {
			const errMessage = new Error(error as string);
			if (errMessage && errMessage.message.includes('mismatch')) {
				setToaster(true, ToastType.error, 'Invalid OTP');
			}
			setToaster(true, ToastType.error, errMessage.message);
			throw error;
		}
	};

	const { data, error, isLoading, isPending, isFetching, refetch } = useQuery({
		queryKey: ['otpDetails'],
		queryFn: submitOtpDetails,
		enabled: false,
		refetchOnWindowFocus: false,
		gcTime: 0,
	});

	return {
		data,
		error,
		isLoading,
		isPending,
		isFetching,
		refetch,
	};
};
