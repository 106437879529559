import { ReactElement, useState } from 'react';
import { initialFormikOtpFormDetails, IOtpModalProps, TextInput } from '@components/common';
import { UseSiteVisitOtpGenerateService } from '@services/hooks/checksAndVerification';
import useSiteLeadStore from '@store/siteLeadStore';
import { ToastType, useTosterStore } from '@store/toster';
import { useFormik } from 'formik';
import { keyDownEvent } from '@helper/utils';
import { UseOtpVerifyService } from '@services/hooks/checksAndVerification/useOtpVerifyService';
import { useGeoLocationDetails } from '@hooks/useGeoLocationDetails';

export const OtpModal = (props: IOtpModalProps): ReactElement => {
	const { onClose, hideCancelButton, submitButtonLabel = 'Submit', onSubmit } = props;
	const { siteDetails, siteVisitId } = useSiteLeadStore();
	const { setToaster } = useTosterStore();
	const [verifyDetails, setVerifyDetails] = useState<boolean>(false);
	const { locationDetails } = useGeoLocationDetails();

	const formik = useFormik({
		initialValues: initialFormikOtpFormDetails,
		onSubmit: () => {},
	});

	const { refetch: generateSiteOtp, isFetching } = UseSiteVisitOtpGenerateService({
		phone_number: siteDetails?.number ?? '',
		site_visit_id: Number(siteVisitId) ?? 0,
		membership_number: siteDetails?.membershipno ?? '',
	});

	const { refetch: verifyOtpCall, isFetching: verifyOtpFetching } = UseOtpVerifyService({
		phone_number: siteDetails?.number ?? '',
		site_visit_id: Number(siteVisitId) ?? 0,
		membership_number: siteDetails?.membershipno ?? '',
		otp: formik.values.otp ?? '',
		latitude: locationDetails?.latitude ?? '',
		longitude: locationDetails?.longitude ?? '',
	});

	const verifyOtp = (): void => {
		if (!verifyOtpCall) return;
		verifyOtpCall().then((response) => {
			if (response.error || !response.data?.success) {
				formik.setFieldError('otp', 'Please enter valid otp.');
				formik.setFieldTouched('otp', true, false);
				return;
			}
			if (response?.data) {
				setToaster(true, ToastType.success, 'OTP verified successfully!');
				onSubmit();
			}
		});
	};

	const generateOtp = (): void => {
		sendOtp();
	};

	const sendOtp = (): void => {
		if (siteDetails?.membershipno && siteDetails?.number && siteVisitId && generateSiteOtp) {
			generateSiteOtp().then((response) => {
				if (response.error) {
					setVerifyDetails(true);
					return;
				}
				if (response?.data) {
					setVerifyDetails(true);
					setToaster(true, ToastType.success, 'OTP sent successfully!');
				}
			});
			return;
		}
		setToaster(
			true,
			ToastType.error,
			`Something went wrong please contact admin with this details - Membership No: ${siteDetails?.membershipno} - Number: ${siteDetails?.number} - Site Id: ${siteVisitId}`
		);
	};

	return (
		<div className="site-lead-otp-modal conform-pop">
			{verifyDetails && (
				<form>
					<div className="otp-input form-control">
						<TextInput
							id="otp"
							name="otp"
							type="password"
							onBlur={formik.handleBlur}
							value={formik.values.otp ?? null}
							setFieldValue={formik.setFieldValue}
							label="OTP"
							error={formik.touched.otp && formik.errors.otp ? formik.errors.otp : null}
							isAutoFocus
							onKeyDown={keyDownEvent}
							maxLength={4}
							required={true}
						/>
					</div>
					<div className="button-component">
						<button
							onClick={sendOtp}
							disabled={verifyOtpFetching}
							className="opt-btn"
							type="button"
							aria-label="resend otp"
						>
							Resend OTP
						</button>
					</div>
				</form>
			)}
			<div className="button-component">
				{(!hideCancelButton || verifyDetails) && (
					<button
						disabled={verifyOtpFetching}
						className="btn btn-primary btn-primary-2"
						type="button"
						onClick={onClose}
					>
						Cancel
					</button>
				)}

				{verifyDetails && (
					<button
						disabled={verifyOtpFetching || !formik.values.otp || !formik.isValid}
						className="btn btn-primary"
						type="button"
						onClick={verifyOtp}
					>
						Verify
					</button>
				)}

				{!verifyDetails && (
					<>
						<div className="otp-model-details">
							<p>
								An OTP will be sent to <b>{siteDetails?.contractor_name}</b> on <b>{siteDetails?.number}</b>. Enter it
								to verify site.
							</p>

							<button disabled={isFetching} className="btn btn-primary" type="button" onClick={generateOtp}>
								{submitButtonLabel}
							</button>
						</div>
					</>
				)}
			</div>
		</div>
	);
};
