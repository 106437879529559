import '@assets/styles/pages/calendar.scss';
import { ReactElement, useCallback, useEffect, useRef, useState } from 'react';
import { DateSelectArg, EventClickArg, EventInput } from '@fullcalendar/core';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin from '@fullcalendar/interaction';
import { AddVisitModal } from './components/AddVisitModal/addVisit';
import { useCalendarQuery } from '@services/hooks/calendar/useCalendarQuery';
import { RenderEventContent } from './components/calendarCard/calendarCard';
import { RenderCustomDayHeader } from './components/dayHeader/dayHeader';
import { DayToggle } from './dayToggle';
import { formatDate, formatDateTime, getPathData } from '@helper/utils';
import { Modal } from '@components/common/modal/modal';
import { NavLink, useNavigate } from 'react-router-dom';
import { MeetingsData } from '@services/hooks/dashboard/useMeetingsQuery';
import { QrModal } from '@components/dashboard/components/meeting/qrModal';
import { ModalData } from '.';
import { useCalendarStore } from '@store/calendarStore';
import { ToastType, useTosterStore } from '@store/toster';
import { BirthdayPopup } from '@components/dashboard/components';

export const Calendar = (): ReactElement => {
	const navigate = useNavigate();
	const [date, setDate] = useState<{ startDate: string; endDate: string }>({ startDate: '', endDate: '' });
	const [forceUpdate, setForceUpdate] = useState<boolean>(false);
	const { data, refetch } = useCalendarQuery(date, forceUpdate);
	const { calendarDetails } = useCalendarStore((state) => state);
	const calendarRef = useRef<FullCalendar | null>(null);
	const [initialEvents, setInitialEvents] = useState<EventInput[]>([]);
	const [activeIndex, setActiveIndex] = useState<number>(0);
	const [modal, setModal] = useState(false);
	const [modalData, setModalData] = useState<ModalData | null>(null);
	const [modalOpen, setModalOpen] = useState<boolean>(false);
	const [modalContent, setModalContent] = useState<MeetingsData | null>(null);
	const { setToaster } = useTosterStore();

	useEffect(() => {
		if (forceUpdate) {
			refetch && refetch();
		}
	}, [forceUpdate, refetch]);

	useEffect(() => {
		setForceUpdate(false);
	}, [data]);

	useEffect(() => {
		setForceUpdate(false);
		if (calendarDetails?.length) {
			const updatedEvents = calendarDetails.map((item) => ({
				id: `${item.id}`,
				title: item.name,
				venu: item.meeting_venue_c,
				start: formatDateTime(item.startdate, item.start_time_c),
				end: formatDateTime(item.enddate, item.end_time_c),
				sfid: item.sfid,
				status: item.status,
				start_time_c: item.start_time_c,
			}));
			setInitialEvents(updatedEvents);
		}
	}, [calendarDetails, refetch]);

	const handleDateSelect = useCallback(
		(selectInfo: DateSelectArg) => {
			const currentDate = new Date();
			const selectedStart = new Date(selectInfo.start);

			if (selectedStart >= currentDate) {
				setModalData({
					id: '',
					title: '',
					venu: '',
					start: selectInfo.start,
					end: new Date(selectInfo.start.getTime() + 60 * 60 * 1000),
				});
				setModal(true);
			} else {
				setToaster(true, ToastType.error, 'Past Date is not allowed');
			}
		},
		[setToaster]
	);

	const handleEventClick = useCallback((clickInfo: EventClickArg) => {
		if (clickInfo?.event?._def.extendedProps.sfid) {
			getPathData(clickInfo?.event?._def.extendedProps.status);
			navigate(
				getPathData(clickInfo?.event?._def.extendedProps.status) === 'today-meeting'
					? '/today-meetings'
					: getPathData(clickInfo?.event?._def.extendedProps.status) === 'meeting-conducted'
						? '/meetings-conducted'
						: '/meeting-details',
				{
					state: {
						id: clickInfo?.event?.id,
						status: getPathData(clickInfo?.event?._def.extendedProps.status),
					},
				}
			);
		}
		setModalData({
			id: clickInfo?.event?.id || '',
			title: clickInfo?.event?.title || '',
			venu: clickInfo?.event._def.extendedProps.venu || '',
			start: clickInfo?.event?.start as Date,
			end: clickInfo?.event?.end as Date,
		});
		setModal(true);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const handleModalClose = useCallback(() => {
		setModal(false);
	}, []);

	const handlePrev = useCallback(() => {
		calendarRef?.current?.getApi()?.prev();
	}, []);

	const handleNext = useCallback(() => {
		calendarRef?.current?.getApi()?.next();
	}, []);

	const handleAddButtonHandler = useCallback((e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
		e.preventDefault();
		setModalData({
			id: '',
			title: '',
			venu: '',
			start: new Date(),
			end: new Date(new Date().getTime() + 60 * 60 * 1000),
		});
		setModal(true);
	}, []);

	const AddMonthView = (): void => {
		const buttons = document.querySelectorAll('.fc-button-primary');
		buttons.forEach((button) => {
			button.classList.add('month-view-button');
		});
	};

	const RemoveMonthView = (): void => {
		const buttons = document.querySelectorAll('.fc-button-primary');
		buttons.forEach((button) => {
			button.classList.remove('month-view-button');
		});
	};

	const handleHeader = useCallback((view: string) => {
		const calendarApi = calendarRef?.current?.getApi();
		switch (view) {
			case 'month':
				calendarApi?.changeView('dayGridMonth');
				AddMonthView();
				break;
			case 'week':
				calendarApi?.changeView('timeGridWeek');
				RemoveMonthView();
				break;
			default:
				calendarApi?.changeView('timeGridDay');
				RemoveMonthView();
				break;
		}
	}, []);

	return (
		<>
			<div className="calendar-wrapper">
				<div className="container">
					<DayToggle activeIndex={activeIndex} setActiveIndex={setActiveIndex} handleHeader={handleHeader} />
					<div className="month-details">{calendarRef?.current?.getApi()?.view?.title}</div>
				</div>

				<div className="calendar-component">
					<div className="container">
						<FullCalendar
							ref={calendarRef}
							plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
							initialView="timeGridWeek"
							headerToolbar={{
								left: 'customPrev customNext',
								center: '',
								right: '',
							}}
							editable={false}
							selectable={true}
							dayHeaderContent={(info) => RenderCustomDayHeader(info, calendarRef, setActiveIndex)}
							events={initialEvents}
							select={handleDateSelect}
							eventClick={handleEventClick}
							eventContent={(info) => RenderEventContent(info, setModalContent, setModalOpen)}
							customButtons={{
								customPrev: {
									text: '',
									click: handlePrev,
									icon: 'icon-arrow-left-circle month-view',
								},
								customNext: {
									text: '',
									click: handleNext,
									icon: 'icon-arrow-right-circle month-view',
								},
							}}
							datesSet={(dateInfo) => {
								setDate({
									startDate: formatDate(new Date(dateInfo.start)),
									endDate: formatDate(new Date(dateInfo.end)),
								});
								setForceUpdate(false);
							}}
							nowIndicator={true}
							selectMirror={true}
							dayMaxEvents={true}
							allDaySlot={false}
							eventMaxStack={3}
							lazyFetching={true}
							dayMaxEventRows={3}
							progressiveEventRendering={true}
							longPressDelay={100}
						/>
					</div>
				</div>
				<div className="container floting-component-wrapper">
					<NavLink to={'/'} aria-label="add user" className="floating-btn" onClick={handleAddButtonHandler}>
						<span className="icon-plus"></span>
					</NavLink>
				</div>
				<Modal
					modalId="calendar"
					modalOpen={modal}
					onModalClose={handleModalClose}
					className="dialog-bottom dialog-lg calendar-popup p-0"
				>
					<AddVisitModal
						dateObject={modalData}
						onClose={handleModalClose}
						refetch={() => {
							setForceUpdate(true);
						}}
						setData={setDate}
					/>
				</Modal>
			</div>
			{modalContent && (
				<Modal
					modalId="popupDialog"
					className="dialog-sm"
					modalOpen={modalOpen}
					onModalClose={() => setModalOpen(false)}
				>
					<QrModal meetingData={modalContent} modalClose={() => setModalOpen(false)} />
				</Modal>
			)}
			<BirthdayPopup />
		</>
	);
};
