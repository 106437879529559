import { ReactElement, useCallback, useEffect, useRef } from 'react';
import { IModalProps } from '@components/common/modal/index';
import { useOutsideDialogClose } from '@hooks/useOutsideDialogClose';
import { CloseButton } from '../closeButton';

export const Modal = (props: IModalProps): ReactElement => {
	const { children, className, modalOpen, modalId, onModalClose, isEscAllow = true, modalTitle } = props;
	const dialogRef = useRef<HTMLDialogElement | null>(null);
	useOutsideDialogClose({
		dialogRef,
		callBackFunction: () => props.onModalClose && props.onModalClose(),
	});

	const handleEscapeKey = useCallback(
		(event: KeyboardEvent) => {
			if (event.key === 'Escape' && !isEscAllow) {
				event.preventDefault();
			}
			if (event.key === 'Escape' && isEscAllow) {
				document.body.classList.remove('overflow-hidden');
				onModalClose && onModalClose();
			}
		},
		[isEscAllow, onModalClose]
	);

	useEffect(() => {
		const modal = dialogRef?.current;
		const overlay = document.getElementById('overlay') as HTMLElement;

		if (!modalOpen) {
			dialogRef && dialogRef?.current?.close();
			document.body.classList.remove('overflow-hidden');
			overlay?.classList?.remove('show');
			return;
		}

		overlay?.classList?.add('show');
		if (modal) {
			modal.addEventListener('keydown', handleEscapeKey);
			modal.showModal();
		}
		document.body.classList.add('overflow-hidden');

		return (): void => {
			if (modal) {
				modal.addEventListener('keydown', handleEscapeKey);
			}
		};
	}, [handleEscapeKey, isEscAllow, modalId, modalOpen]);

	return (
		<dialog id={modalId} className={`dialog ${className}`} ref={dialogRef}>
			{modalTitle && <span className="dialog-title">{modalTitle}</span>}
			{onModalClose && <CloseButton onClose={() => onModalClose && onModalClose()} />}
			{children}
		</dialog>
	);
};
