import { useRxData } from 'rxdb-hooks';
import { MangoQuery, RxCollection, RxDocument } from 'rxdb';
import { useCallback } from 'react';
import { useRxCountQuery } from '@hooks/useCount';
import { DetailsOfSubContractor } from '@services/hooks/enduser-details/useGetContractorDetailsQuery';

export type ISubContractorReturn = {
	result: RxDocument<DetailsOfSubContractor>[];
	isFetching: boolean;
	fetchMore: () => void;
	totalCount: number;
};

export interface ISubContractorListFilterProps {
	searchValue?: string;
}

export const useSubContractorListRxDb = ({ searchValue = '' }: ISubContractorListFilterProps): ISubContractorReturn => {
	const queryConstructor = useCallback(
		(collection: RxCollection<DetailsOfSubContractor>) => {
			const query: MangoQuery<DetailsOfSubContractor> = {
				selector: {
					$or: [
						{ name: { $regex: `.*${searchValue}.*`, $options: 'i' } },
						{ phone: { $regex: `.*${searchValue}.*`, $options: 'i' } },
						{ membershipno: { $regex: `.*${searchValue}.*`, $options: 'i' } },
					],
				},
			};
			return collection.find(query);
		},
		[searchValue]
	);

	const totalCount = useRxCountQuery<DetailsOfSubContractor>('sub_contractor_list', queryConstructor);

	const { result, isFetching, fetchMore } = useRxData<DetailsOfSubContractor>('sub_contractor_list', queryConstructor, {
		pageSize: 20,
		pagination: 'Infinite',
	});

	return { result, isFetching, fetchMore, totalCount };
};
