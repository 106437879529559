import { ReactElement } from 'react';
import { IDashboardSummaryDetails } from '.';
import { UserSummaryCard } from './components/userSummaryCard/userSummaryCard';
import { useEndUserNormsCount } from '@services/hooks/dashboard/useEndUserNormsCount';
import { NavLink } from 'react-router-dom';
import { useComponentLevelAccessCheck } from '../../../../authGuard/hooks/useComponentLevelAccessCheck';

export const SummaryDetails = (props: IDashboardSummaryDetails): ReactElement => {
	const { id } = props;
	const { data } = useEndUserNormsCount();
	const checkPermissions = useComponentLevelAccessCheck('user-sufficiency-summary');

	const calculateGap = (): number => {
		let gap = 0;
		if (data) {
			gap = Number(data?.end_user_norms_count || 0) - Number(data?.enroll_user || 0);
		}
		return gap < 0 ? 0 : gap;
	};

	if (!checkPermissions) {
		return <></>;
	}

	return (
		<section key={id} className="summary-details-section">
			<div className="container">
				<h1 className="h3 cmn-heading">
					<span>User Sufficiency Summary</span>
				</h1>

				<ul className="summary-details-wrapper row">
					<li className="d-col d-col-4">
						<UserSummaryCard id={1} name="End User Norms" value={data?.end_user_norms_count || 0} icon="todo" />
					</li>

					<li className="d-col d-col-4">
						<UserSummaryCard id={2} name="Current Actuals" value={data?.enroll_user || 0} icon="badge" />
					</li>

					<li className="d-col d-col-4">
						<UserSummaryCard id={3} name="Gap" value={calculateGap()} icon="gap" />
					</li>

					<li className="d-col d-col-4">
						<UserSummaryCard id={4} name="Target for Quarter" value={data?.target_for_quarter_count || 0} icon="goal" />
					</li>
				</ul>

				<div className="more-details">
					<NavLink to="/user-sufficiency-summary" aria-label="more details" className="more-details-btn">
						<span className="content">More Details</span>
						<span className="icon icon-arrow-right-circle"></span>
					</NavLink>
				</div>
			</div>
		</section>
	);
};
