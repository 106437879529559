import { ReactElement, useCallback } from 'react';
import { ISixMonthsConfirmationModalProps } from '@components/contractor360/components/endUserProfile/index';
import { useEndUserStore } from '@store/enduser';
import { useNavigate } from 'react-router-dom';
import { enrollFormRedirection, EnrollFormSection, toEnrollFormSection } from '@components/members/components/userCard';
import { useEndUserProfileStore } from '@store/endUserProfileStore';

export const SixMonthsConfirmationModal = (props: ISixMonthsConfirmationModalProps): ReactElement => {
	const { modalClose, data, message, handleConfirm = undefined } = props;
	const { setIsEditing, setActiveNavTab, setIsFormEdit, setFormId, setFormRevalidate } = useEndUserStore();
	const navigate = useNavigate();
	const { basicDetailsRevalidate } = useEndUserProfileStore();

	const navigationToDetailPage = (): void => {
		const navRedirectionData = {
			id: data?.id,
			status: 'Basic Details',
			activeFormTabs: [0, 1, 2],
			isEdit: true,
			membershipNumber: data?.membershipno,
		};
		const section = toEnrollFormSection(
			basicDetailsRevalidate ? EnrollFormSection.BASIC_DETAILS : EnrollFormSection.MORE_DETAILS
		);

		if (section && enrollFormRedirection[section]) {
			navigate(enrollFormRedirection[section].path, {
				state: navRedirectionData,
			});
			setActiveNavTab(enrollFormRedirection[section].activeTabs);
		}
	};

	const handleNavClick = useCallback((): void => {
		modalClose();
		if (handleConfirm) {
			handleConfirm();
			return;
		}

		if (data?.id) {
			setIsEditing(false);
			setFormId(data.id);
			setIsFormEdit(true);
			setFormRevalidate(true);

			navigationToDetailPage();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [data, setIsEditing, setActiveNavTab, setFormId, setIsFormEdit, modalClose]);

	return (
		<div className="profiling-confirmation summary-pop-content">
			<div className="confirmation-message">
				<p>{message}</p>
			</div>
			<div className="btn-wrapper">
				<button
					type="button"
					aria-label="conformation button"
					className="cancel-btn btn-primary btn close"
					onClick={modalClose}
				>
					Cancel
				</button>
				<button type="button" onClick={handleNavClick} aria-label="conformation button" className="btn-primary btn">
					Confirm
				</button>
			</div>
		</div>
	);
};
