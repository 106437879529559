import { ReactElement, useEffect } from 'react';
import { initialFormikEndUserOtpFormDetails, TextInput } from '@components/common';
import { IEndUserOtpModalProps } from '.';
import { ToastType, useTosterStore } from '@store/toster';
import { useFormik } from 'formik';
import { keyDownEvent } from '@helper/utils';
import { UseEndUserOtpVerifyService } from '@services/hooks/enduser-details/useEndUserOtpVerifyService';
import useOtpTimer from '@hooks/useTimer';

export const EndUserOtpModal = (props: IEndUserOtpModalProps): ReactElement => {
	const { onClose, onSubmit, primaryNumber, setVerifyDetails, sendOtp } = props;
	const { setToaster } = useTosterStore();
	const { timeLeft, isActive, startTimer } = useOtpTimer();

	const formik = useFormik({
		initialValues: initialFormikEndUserOtpFormDetails,
		onSubmit: () => {},
	});

	const { refetch: verifyOtpCall, isFetching: verifyOtpFetching } = UseEndUserOtpVerifyService({
		otp: formik.values.otp,
		phone_number: primaryNumber,
	});

	const verifyOtp = (): void => {
		if (!verifyOtpCall) return;
		verifyOtpCall()
			.then((response) => {
				if (response.error) {
					formik.setFieldError('otp', 'Please enter valid otp.');
					formik.setFieldTouched('otp', true, false);
				}
				if (response?.data) {
					setVerifyDetails(true);
					setToaster(true, ToastType.success, 'OTP verified successfully!');
					onSubmit();
				}
			})
			.catch((error) => {
				setToaster(true, ToastType.error, error);
			});
	};

	const reSendOtp = (): void => {
		sendOtp();
		startTimer();
	};

	useEffect(() => {
		if (timeLeft === 0) {
			return;
		}
		if (timeLeft === 60) {
			startTimer();
		}
	}, [startTimer, timeLeft]);
	return (
		<div className="site-lead-otp-modal conform-pop">
			<form>
				<div className="otp-input form-control">
					<TextInput
						id="otp"
						name="otp"
						type="password"
						onBlur={formik.handleBlur}
						value={formik.values.otp ?? null}
						setFieldValue={formik.setFieldValue}
						label="OTP"
						error={formik.touched.otp && formik.errors.otp ? formik.errors.otp : null}
						isAutoFocus
						onKeyDown={keyDownEvent}
						maxLength={4}
						required={true}
					/>
				</div>
				<div className="otp-model-details">
					{!isActive ? (
						<button
							onClick={() => reSendOtp()}
							className="opt-btn"
							type="button"
							aria-label="resend otp"
							disabled={isActive}
						>
							Resend OTP
						</button>
					) : (
						<button className="timeleft" type="button" aria-label="resend otp">
							Resend OTP In: {timeLeft}s
						</button>
					)}
				</div>
			</form>
			<div className="button-component">
				<button disabled={verifyOtpFetching} className="btn btn-primary btn-primary-2" type="button" onClick={onClose}>
					Cancel
				</button>

				<button
					disabled={verifyOtpFetching || !formik.values.otp || !formik.isValid}
					className="btn btn-primary"
					type="button"
					onClick={verifyOtp}
				>
					Verify
				</button>
			</div>
		</div>
	);
};
