import { APICONFIG } from '@config/api.config';
import { ErrorHandler } from '@helper/errorHandler';
import { setRxdb } from '@helper/utils';
import { useCheckRxdb } from '@hooks/checkRxdb';
import { IQueryResponse } from '@services/api';
import { APIService } from '@services/api/common-services';
import { useQuery } from '@tanstack/react-query';

export interface DetailsOfSubContractor {
	membershipno: string | number | null | undefined;
	name: string | null;
	phone?: string | null;
	id?: string | number;
	createddate?: string | null;
	ytd_banking?: string | null;
}
export interface SubcontractorsDetails {
	count: number;
	data: DetailsOfSubContractor[];
}

export const useGetContractorDetails = (
	memberShipNo: string | null | number | undefined
): IQueryResponse<SubcontractorsDetails> => {
	const { isDbChecked, shouldFetch } = useCheckRxdb('sub_contractor_list');
	const fetchContractorDetail = async (): Promise<SubcontractorsDetails> => {
		try {
			const response = await APIService.getData<SubcontractorsDetails>(
				APICONFIG.GET_CONTRACTORS_FPC_LIST + `?membership_no=${memberShipNo}`
			);
			if (response?.data) {
				const data = response.data.map((item) => ({
					...item,
					id: item.id?.toString(), // Convert id to string
					membershipno: item.membershipno ? String(item.membershipno) : null,
					name: item.name ? String(item.name) : null,
					phone: item.phone ? String(item.phone) : null,
				}));
				await setRxdb('sub_contractor_list', data);
			}
			return response;
		} catch (error) {
			ErrorHandler(error);
			return { count: 0, data: [] };
		}
	};

	const { data, error, isLoading, isPending, refetch } = useQuery({
		queryKey: ['SubcontractorsDetails'],
		queryFn: fetchContractorDetail,
		enabled: (isDbChecked && shouldFetch) || !!memberShipNo,
		gcTime: 0,
		staleTime: 0,
	});

	return {
		data,
		error,
		isLoading,
		isPending,
		refetch,
	};
};
