import { ReactElement, ReactNode } from 'react';
import { NoDataFound } from '@components/common/no-data-found/noDataFound';
import { ISiteVisitsProps } from '@components/siteSummaryDetails/components/siteVisits/index';
import { NavLink } from 'react-router-dom';
import { formatDateInWord } from '@helper/dateFormat';
import { useSiteAllDetailsStore } from '@store/siteAllDetailsStore';
import { ISiteLeadVisits } from '@components/SiteLeadOverview';
import useSiteLeadStore from '@store/siteLeadStore';
import useStepperStore from '@store/stepperStore';

// TODO:: Need to add it in the site lead overview page same component.
export const SiteVisits = (props: ISiteVisitsProps): ReactElement => {
	const { visits, contractorDetails, siteLeadId } = props;
	const { setKeyBaseValue } = useSiteAllDetailsStore();
	const { setCurrentStep } = useStepperStore();
	const { setSiteLeadId, setSiteVisitId } = useSiteLeadStore();
	const routeVisit = (visit?: ISiteLeadVisits): void => {
		setKeyBaseValue('visit', visit);
	};

	const handleDraftSiteRoute = (visitId?: number): void => {
		setSiteLeadId(siteLeadId);
		setSiteVisitId(visitId);
		setCurrentStep(2);
	};

	const visitButton = (visit?: ISiteLeadVisits): ReactNode => {
		if (visit?.site_visit_status && visit?.site_visit_status?.toLowerCase() === 'draft') {
			return (
				<NavLink
					to="/add-new-site"
					onClick={() => handleDraftSiteRoute(visit?.id)}
					state={contractorDetails}
					aria-label="add new site"
					className="add-site-icon"
				>
					<span className="icon-plus"></span>
				</NavLink>
			);
		}
		return (
			<NavLink
				to={'/site-lead-overview/visit-details'}
				onClick={() => routeVisit(visit)}
				className="view-site h5"
				aria-label="view site"
			>
				View
			</NavLink>
		);
	};

	return (
		<div className="visit-box-wrapper">
			<ul className="visit-box-list row checktion-box">
				{visits?.length !== 0 ? (
					visits?.map((visit, index) => (
						<li
							key={visit?.id}
							className={`d-col d-col-2 ${visit?.site_visit_status?.toLowerCase() === 'draft' ? 'active' : ''}`}
						>
							<div className="visit-record-box">
								<span className="new-site-icon">
									<span className="icon-hammer"></span>
									<span className="site-count h5">{index + 1}</span>
								</span>

								<span className="site-content">
									<span className="content-title">{visit?.site_name_c}</span>
									{visit?.verification_status_c && (
										<span
											className={`site-status h4 ${visit?.verification_status_c.toLowerCase() !== 'unverified' ? 'verified' : ''}`}
										>
											<span className="status-circul">
												<span></span>
											</span>
											{visit?.verification_status_c}
										</span>
									)}
									<span className="date-time h5">
										{formatDateInWord(new Date(visit?.activitydatetime || new Date()))}
									</span>
									{visit?.site_visit_status?.toLowerCase() === 'draft' && (
										<span className="site-visit-recored">New site added. Record Visit</span>
									)}
								</span>

								{visitButton(visit)}
							</div>
						</li>
					))
				) : (
					<NoDataFound />
				)}
			</ul>
		</div>
	);
};
