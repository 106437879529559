import { ReactElement, useCallback } from 'react';
import { getDraftSite, getSortedVisits, ISite } from '.';
import { NavLink, useNavigate } from 'react-router-dom';
import useStepperStore from '@store/stepperStore';
import useSiteLeadStore from '@store/siteLeadStore';
import { ISiteVisits, SiteSummaryDetails } from '@services/hooks/contractor360';

export const Site = (props: ISite): ReactElement => {
	const { site, siteIndex, membershipno, handleEndUserProfileVerificationStage, contractor_name } = props;
	const navigate = useNavigate();
	const { setCurrentStep } = useStepperStore();
	const { setSiteVisitId, setSiteLeadId, setSiteSfid, setKeyBaseValue } = useSiteLeadStore();

	const getSiteStatus = (site: SiteSummaryDetails, latestVisit: ISiteVisits): string => {
		const currentStage = site?.current_stage_of_site_c?.toLowerCase();
		const visitStatus = latestVisit?.site_visit_status?.toLowerCase();
		const hasVisits = site?.visits?.length > 0;

		if (currentStage === 'draft' && !hasVisits && !site.visit_recorded) return 'NEW_DRAFT';
		if (currentStage === 'site visited' && hasVisits && visitStatus === 'open') return 'VISITED_OPEN';
		if (currentStage === 'draft' && ((hasVisits && visitStatus === 'draft') || (!hasVisits && site.visit_recorded)))
			return 'DRAFT_WITH_VISIT';
		if (currentStage === 'site visited' && hasVisits && visitStatus === 'draft') return 'VISITED_DRAFT';
		if (currentStage === 'new lead' && hasVisits && visitStatus === 'draft') return 'NEW_LEAD_DRAFT';
		return 'DEFAULT';
	};

	const setNavigationState = useCallback(
		(siteStatus: string, latestVisit: ISiteVisits) => {
			switch (siteStatus) {
				case 'NEW_DRAFT':
				case 'VISITED_OPEN':
					setCurrentStep(0);
					break;
				case 'DRAFT_WITH_VISIT':
				case 'VISITED_DRAFT':
					setSiteLeadId(site.id);
					setSiteVisitId(latestVisit?.id);
					setCurrentStep(2);
					break;
				case 'NEW_LEAD_DRAFT':
					setSiteVisitId(latestVisit?.id);
					setCurrentStep(2);
					break;
				default:
					setCurrentStep(0);
			}
		},
		[setCurrentStep, setSiteLeadId, setSiteVisitId, site.id]
	);

	const handleNavigateCheck = (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>): void => {
		e.preventDefault();
		if (handleEndUserProfileVerificationStage) {
			handleEndUserProfileVerificationStage(() => {
				handleNavigate();
			});
			return;
		}
		handleNavigate();
	};

	const handleNavigate = useCallback((): void => {
		const sortedVisits = getSortedVisits(site?.visits);
		const latestVisit = sortedVisits[0];
		const siteStatus = getSiteStatus(site, latestVisit);

		setNavigationState(siteStatus, latestVisit);
		setSiteSfid(site.sfid);
		setKeyBaseValue('siteDetails', {
			siteId: site.id,
			membershipno: membershipno,
			name: site.name,
			number: site.contract_phone,
		contractor_name: contractor_name,});
		setKeyBaseValue('siteId', site.id);

		navigate('/add-new-site', {
			state: {
				siteId: site.id,
			},
		});
	}, [site, setNavigationState, setSiteSfid, setKeyBaseValue, membershipno, navigate]);

	return (
		<li key={site?.id} className={`d-col d-col-2 ${getDraftSite(site) ? 'active' : ''}`}>
			<NavLink to="/" aria-label="sites" id="visit-user-form" onClick={handleNavigateCheck}>
				<span className="new-site-icon">
					<span className="icon-hammer icon"></span>
					<span className="site-count h5">{siteIndex + 1}</span>
				</span>
				<span className="site-content">
					{site?.name && (
						<span className="h4" title={site?.name}>
							{site?.name}
						</span>
					)}
					{site?.site_location_c && (
						<address className="site-address h5" title={site?.site_location_c}>
							{site?.site_location_c}
						</address>
					)}

					{site?.current_stage_of_site_c?.toLowerCase() === 'draft' && (
						<span className="site-address h5 new-site-add">New site added. Record Visit</span>
					)}
				</span>
				<span className="add-site-icon">
					<span className="icon-plus"></span>
				</span>
			</NavLink>
		</li>
	);
};
