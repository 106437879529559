import { TextInput } from '@components/common';
import { ReactElement, useEffect } from 'react';
import { BasicDetailsCommonFormSectionProps } from '.';
import { keyDownEvent } from '@helper/utils';
import { useGluePotentialQuery } from '@services/hooks/enduser-details/useGluePotentialQuery';
import { useDebounce } from '@hooks/useDebounce';
import { useLocation } from 'react-router-dom';
import { ToastType, useTosterStore } from '@store/toster';
import { useEndUserProfileStore } from '@store/endUserProfileStore';

export const UpperFormSection = (props: BasicDetailsCommonFormSectionProps): ReactElement => {
	const { formik, isEditing, isFormEdit, getBasicFormData, formId } = props;
	const { state } = useLocation();
	const { setToaster } = useTosterStore();
	const { basicDetailsRevalidate } = useEndUserProfileStore();

	const { data: gluePotentialData, refetch: refetchGluePotential } = useGluePotentialQuery(
		formik?.values?.sites_worked?.toString(),
		formik?.values?.sites_competition?.toString(),
		formik?.values?.average_glue?.toString(),
		state?.membershipNumber
	);

	// TODO:: The calculation is stop for now for details refer ticket: 402189
	// eslint-disable-next-line @typescript-eslint/ban-ts-comment
	// @ts-expect-error
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	const handleGluePotentialChange = useDebounce((e: React.ChangeEvent<HTMLInputElement>) => {
		const valid: Array<string | undefined> = [];
		['sites_worked', 'sites_competition', 'average_glue'].forEach((value) => {
			if (value.includes(e.target.name)) {
				valid.push(e.target.value);
				return;
			}
			valid.push(formik?.values[value]);
		});

		if (isFormEdit && e.target.value.trim() !== '' && !valid.includes('')) {
			refetchGluePotential?.();
		}
	}, 1500);

	const calculateGlueValue = (glue_potential?: number | null): number | null => {
		if (isFormEdit && glue_potential !== undefined && glue_potential) {
			return Number.isInteger(glue_potential) ? glue_potential : +glue_potential.toFixed(2);
		}
		// if (isFormEdit && getBasicFormData?.glue_consumption_per_month) {
		// 	return getBasicFormData.glue_consumption_per_month;
		// }

		return formik.values.average_glue;
	};

	// TODO:: The calculation is stop for now for details refer ticket: 402189
	useEffect(() => {
		return;
		const { glue_potential, pop_up, popup_message } = gluePotentialData?.data || {};

		formik.setFieldValue('calculated_glue', calculateGlueValue(glue_potential));

		if (pop_up && popup_message) {
			setToaster(true, ToastType.error, popup_message as string);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [gluePotentialData, isFormEdit, getBasicFormData, formik.values.average_glue, setToaster]);

	return (
		<>
			<li className="form-control d-col d-col-2">
				<TextInput
					id="first_name"
					name="first_name"
					type="text"
					onBlur={formik.handleBlur}
					value={formik.values.first_name ?? ''}
					label="First Name"
					error={formik.touched.first_name && formik.errors.first_name ? formik.errors.first_name : null}
					setFieldValue={formik.setFieldValue}
					disabled={isEditing || !!(formId && getBasicFormData?.firstname && isFormEdit)}
					isAutoFocus
					required
				/>
			</li>

			<li className="form-control d-col d-col-2">
				<TextInput
					id="middle_name"
					name="middle_name"
					type="text"
					label="Middle Name"
					onBlur={formik.handleBlur}
					value={formik.values.middle_name ?? ''}
					setFieldValue={formik.setFieldValue}
					error={formik.touched.middle_name && formik.errors.middle_name ? formik.errors.middle_name : null}
					disabled={isEditing}
				/>
			</li>
			<li className="form-control d-col d-col-2">
				<TextInput
					id="last_name"
					name="last_name"
					type="text"
					label="Last Name"
					onBlur={formik.handleBlur}
					value={formik.values.last_name ?? ''}
					setFieldValue={formik.setFieldValue}
					error={formik.touched.last_name && formik.errors.last_name ? formik.errors.last_name : null}
					disabled={isEditing || !!(formId && getBasicFormData?.lastname && isFormEdit)}
					required
				/>
			</li>

			<li className="form-control two-line-lable d-col d-col-2">
				<TextInput
					id="sites_worked"
					name="sites_worked"
					type="text"
					onBlur={formik.handleBlur}
					value={formik.values.sites_worked ?? ''}
					setFieldValue={formik.setFieldValue}
					label="How many sites have you worked on in last 6 months (Completed + In-Progress)"
					error={formik.touched.sites_worked && formik.errors.sites_worked ? formik.errors.sites_worked : null}
					onKeyDown={keyDownEvent}
					disabled={isEditing}
					required
				/>
			</li>

			<li className="form-control two-line-lable md-sigle d-col d-col-2">
				<TextInput
					id="sites_competition"
					name="sites_competition"
					type="text"
					onBlur={formik.handleBlur}
					value={formik.values.sites_competition ?? ''}
					setFieldValue={formik.setFieldValue}
					label="No of competition sites worked on in last 6 months"
					error={
						formik.touched.sites_competition && formik.errors.sites_competition ? formik.errors.sites_competition : null
					}
					onKeyDown={keyDownEvent}
					disabled={isEditing}
					required
				/>
			</li>

			<li className={`form-control d-col d-col-2`}>
				<TextInput
					id="average_glue"
					name="average_glue"
					type="text"
					className={`${basicDetailsRevalidate ? 'end-user-verification-highlight' : ''}`}
					onBlur={formik.handleBlur}
					value={formik.values.average_glue ?? ''}
					setFieldValue={(field: string, value: string, shouldValidate?: boolean) => {
						formik.setFieldValue(field, value, shouldValidate);
						formik.setFieldValue('calculated_glue', value);
						formik.setFieldTouched?.('competitionConsumption', true, false);
					}}
					label="Avg Glue used in a month"
					error={formik.touched.average_glue && formik.errors.average_glue ? formik.errors.average_glue : null}
					onKeyDown={keyDownEvent}
					disabled={isEditing}
					required
				/>
				{isFormEdit && (
					<b className={`info-message show`}>
						Average Monthly Banking of Last 6 Months was {formik.values.avg_monthly_banking_six_months} Kg
					</b>
				)}
			</li>

			<li className="form-control d-col d-col-2">
				<TextInput
					id="calculated_glue"
					name="calculated_glue"
					type="text"
					value={formik.values.calculated_glue && `${formik.values.calculated_glue} kg`}
					setFieldValue={formik.setFieldValue}
					error={formik.errors.calculated_glue ? formik.errors.calculated_glue : null}
					label="Calculated Glue Potential"
					disabled
				/>
			</li>

			<li className="form-control d-col d-col-2">
				<TextInput
					id="competitionConsumption"
					name="competitionConsumption"
					type="text"
					onBlur={formik.handleBlur}
					value={formik.values.competitionConsumption ?? ''}
					setFieldValue={formik.setFieldValue}
					label="Monthly Competition Consumption (Kgs)"
					error={
						formik.touched.competitionConsumption && formik.errors.competitionConsumption
							? formik.errors.competitionConsumption
							: null
					}
					onKeyDown={keyDownEvent}
					disabled={isEditing}
					required
				/>
			</li>

			<li className="form-control d-col d-col-2">
				<TextInput
					id="email"
					name="email"
					type="text"
					onBlur={formik.handleBlur}
					value={formik.values.email ?? ''}
					setFieldValue={formik.setFieldValue}
					label="Email"
					error={formik.touched.email && formik.errors.email ? formik.errors.email : null}
					disabled={isEditing}
				/>
			</li>
		</>
	);
};
