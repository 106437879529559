import { ReactElement } from 'react';
import { IEndUserSearch } from '.';
import { useFilterStore } from '@store/index';
import { handlePreventDefault } from '@helper/utils';

export const EndUserSearch = (props: IEndUserSearch): ReactElement => {
	const { setFilterOpen, setSortOpen, placeHolder } = props;
	const { search, updateSearch, filterObject, sortActiveStatus } = useFilterStore((state) => state);

	const {
		dateOfVisit,
		kycStatus,
		visitStatus,
		customToggle,
		checkSecondary,
		customSelect,
		singleDropDown,
		ftqVisitStatus,
	} = filterObject;

	const customData = customToggle.toggleData;
	const secondaryData = checkSecondary;
	const customSelectData = customSelect.customData;

	const allStatesPresent =
		visitStatus.visitStatus.length ||
		kycStatus.kycStatus.length ||
		customData.length ||
		secondaryData.some((item) => item.secondaryData.length > 0) ||
		customSelectData.length ||
		dateOfVisit.startDate ||
		dateOfVisit.endDate ||
		singleDropDown.dropDownData.length ||
		ftqVisitStatus.ftqVisitStatus.length;

	return (
		<div className="filter-section">
			<div className="search-filter-wrapper">
				<div className="search-with-filter">
					<div className="search">
						<form onSubmit={handlePreventDefault}>
							<div className="search-row">
								<div className="input-col">
									<label htmlFor="search-top" className="hide">
										search
									</label>
									<input
										value={search}
										onChange={(e) => updateSearch(e.target.value)}
										type="text"
										name="search"
										id="search-top"
										placeholder={placeHolder}
										className="search-bar"
										autoComplete="off"
									/>
								</div>

								<button className="search-button" aria-label="search user" onClick={handlePreventDefault}>
									<span className="icon-search"></span>
								</button>
							</div>
						</form>
					</div>

					<div className="filters">
						<button
							className={`filter-button ${sortActiveStatus && 'active'}`}
							aria-label="filter for user"
							id="sort-lead-btn"
							onClick={setSortOpen}
						>
							<span className="icon-slider"></span>
						</button>

						<button
							className={`filter-button ${!!allStatesPresent && 'active'}`}
							aria-label="filter"
							id="filter-contractor"
							onClick={setFilterOpen}
						>
							<span className="icon-filter"></span>
						</button>
					</div>
				</div>
			</div>
		</div>
	);
};
