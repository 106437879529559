export * from './otpModal';

export interface IOtpModalProps {
	hideCancelButton?: boolean;
	onClose: () => void;
	submitButtonLabel: string;
	onSubmit: () => void;
}

export const initialFormikOtpFormDetails = {
	otp: '',
};
