import { create } from 'zustand';
interface ICommonModalPopUpModal {
	isBirthDayModalOpen: boolean;
	setKeyBaseModalState: (key: string, state: boolean) => void;
}

interface ICommonModalPopUpInitialState {
	isBirthDayModalOpen: boolean;
}

const initialState: ICommonModalPopUpInitialState = {
	isBirthDayModalOpen: false,
};

export const useCommonModalPopUpStore = create<ICommonModalPopUpModal>((set) => ({
	...initialState,
	setKeyBaseModalState: (key: string, item: boolean): void => set((state) => ({ ...state, [key]: item })),
}));
