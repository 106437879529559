import { BannerData } from '@components/dashboard/components/banner';
import { APICONFIG } from '@config/api.config';
import { APIService } from '@services/api/common-services';
import { useQuery } from '@tanstack/react-query';
import { IListAPIResponse, IQueryResponse } from '@services/api';
import { useCheckRxdb } from '@hooks/checkRxdb';
import { setRxdb } from '@helper/utils';
import { ErrorHandler } from '@helper/errorHandler';

export const useBannerQuery = (): IQueryResponse<IListAPIResponse<Array<BannerData>>> => {
	const { isDbChecked, shouldFetch } = useCheckRxdb('dashboard_banner');
	const fetchBannerData = async (): Promise<IListAPIResponse<Array<BannerData>>> => {
		try {
			const response = await APIService.getData<IListAPIResponse<Array<BannerData>>>(
				APICONFIG.BANNER_API + `?page=${1}&limit=${5}&version=v2`
			);
			if (response) {
				const updatedResponse = response?.data?.map((banner) => {
					return {
						...banner,
						id: banner.id.toString(),
					};
				});
				if (updatedResponse) {
					await setRxdb('dashboard_banner', updatedResponse);
				}
			}
			return response;
		} catch (error) {
			ErrorHandler(error);
			return {};
		}
	};

	const { data, error, isLoading, isPending } = useQuery({
		queryKey: ['banner'],
		queryFn: fetchBannerData,
		enabled: isDbChecked && shouldFetch,
		refetchOnWindowFocus: false,
		retry: false,
	});

	return {
		data,
		error,
		isLoading,
		isPending,
	};
};
