import { ReactElement } from 'react';
import { NavLink } from 'react-router-dom';
import { ICurrentSlab } from '.';
import CurrentSlabEmoji from '@assets/images/emoji.png';
import { useCurrentSlabQuery } from '@services/hooks/contractor360/useCurrentSlabQuery';

export const CurrentSlab = (props: ICurrentSlab): ReactElement => {
	const { membershipNumber } = props;
	const { data: currentSlab } = useCurrentSlabQuery(membershipNumber);

	return (
		<section className="current-slab-wrapper">
			<div className="container">
				<div className="current-slab-inner">
					<span className="current-slab-emoji">
						<picture>
							<img src={CurrentSlabEmoji} alt="Emoji" title="Emoji" width="10" height="10" />
						</picture>
					</span>
					<span className="current-slab-content h5">
						Current slab {currentSlab?.data?.slab ?? 'NA'}
						{currentSlab?.data?.slab && currentSlab?.data?.slab !== 'NA' && 'KGs'}.{' '}
						{currentSlab?.data?.remaining_kgs ?? '100'} KGs to go for {currentSlab?.data?.next_slab ?? '100-200'} KG
						slab.
					</span>
					<span className="current-slab-detail">
						<NavLink
							to="/contractor/calculator"
							state={{
								membershipNumber,
							}}
							aria-label="Calculator"
						>
							View Calculator
						</NavLink>
					</span>
				</div>
			</div>
		</section>
	);
};
