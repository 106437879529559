import { APICONFIG } from '@config/api.config';
import { APIService } from '@services/api/common-services';
import { useQuery } from '@tanstack/react-query';
import { IQueryResponse } from '@services/api';
import { IEndUserNormsCount } from '@components/dashboard/components/summaryDetails';
import { useGetRxDb } from '@hooks/getRxdbData';
import { ProfileData } from '@services/hooks/profile';

export const useEndUserNormsCount = (): IQueryResponse<IEndUserNormsCount> => {
	const { result: profile } = useGetRxDb<ProfileData>('profile');
	const fetchEndUserNormsCount = async (): Promise<IEndUserNormsCount> => {
		return await APIService.getData<IEndUserNormsCount>(APICONFIG.END_USER_NORMS_COUNT, {
			divisioncode_c: '' + profile[0].divisionid_c,
			salesgroupcode_c: profile[0].salesgroupid_c,
		});
	};

	const { data, error, isLoading, isPending, isFetching } = useQuery({
		queryKey: ['endUserNormsCount'],
		queryFn: fetchEndUserNormsCount,
		enabled: profile.length !== 0,
		refetchOnWindowFocus: false,
	});

	return {
		data,
		error,
		isLoading,
		isPending,
		isFetching,
	};
};
