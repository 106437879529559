import { Checkbox, Dropdown, SingleDatePicker, TextInput, GenerateOTP } from '@components/common';
import { ReactElement, useEffect, useState } from 'react';
import { BasicDetailsCommonFormSectionProps } from '.';
import { get18YearsAgo, keyDownEvent } from '@helper/utils';
import { useDebounce } from '@hooks/useDebounce';
import { usePhoneNumberValidateQuery } from '@services/hooks/enduser-details/usePhoneNumberValidateQuery';
import { languages, religions } from '@config/constant';
import CheckTick from '@assets/images/check-circle 1.png';

export const BottomFormSection = (props: BasicDetailsCommonFormSectionProps): ReactElement => {
	const [showButton, setShowButton] = useState<boolean>(false);
	const [openOtpModal, setOpenOtpModal] = useState<boolean>(false);
	const [verifyDetails, setVerifyDetails] = useState<boolean>(false);
	const { formik, isEditing, formId, getBasicFormData } = props;
	const { data: phoneValidationData, refetch: refetchPhoneValidation } = usePhoneNumberValidateQuery(
		formik.values.primary_number,
		'primary_number'
	);
	const { refetch: refetchSecondaryPhoneValidation } = usePhoneNumberValidateQuery(
		formik.values.secondary_number,
		'secondary_number'
	);
	const handlePrimaryNumberChange = useDebounce((e: React.ChangeEvent<HTMLInputElement>) => {
		setShowButton(false);
		if (e.target.value.length === 10) {
			refetchPhoneValidation?.();
		}
	}, 100);

	const handleSecondaryNumberChange = useDebounce((e: React.ChangeEvent<HTMLInputElement>) => {
		if (e.target.value.length === 10) {
			refetchSecondaryPhoneValidation?.();
		}
	}, 100);

	const handleOpenOtpPopup = (): void => {
		setOpenOtpModal(true);
	};

	const effectiveBirthDate = formik.values.birth_date > get18YearsAgo() ? formik.values.birth_date : get18YearsAgo();

	useEffect(() => {
		if (!verifyDetails && !formik.errors.primary_number) {
			formik.setFieldError?.('primary_number', 'Verification Required!');
		}
		if (verifyDetails && formik.errors.primary_number === 'Verification Required!') {
			formik.setFieldError?.('primary_number', undefined);
		}
	}, [formik.errors.primary_number, verifyDetails]);

	useEffect(() => {
		if (verifyDetails) {
			setVerifyDetails(false);
		}
	}, [formik.values.primary_number]);

	useEffect(() => {
		if (
			!phoneValidationData?.data?.exists &&
			phoneValidationData?.data?.message &&
			formik.errors.primary_number === 'Verification Required!'
		) {
			setShowButton(true);
		}
	}, [phoneValidationData]);

	return (
		<>
			<li className="form-control d-col d-col-2">
				<TextInput
					id="primary_number"
					name="primary_number"
					type="text"
					onBlur={formik.handleBlur}
					value={formik.values.primary_number ?? ''}
					setFieldValue={formik.setFieldValue}
					label="Primary Mobile No"
					error={formik.touched.primary_number && formik.errors.primary_number ? formik.errors.primary_number : null}
					onKeyDown={keyDownEvent}
					disabled={isEditing || !!(formId && getBasicFormData?.phone)}
					maxLength={10}
					onChange={handlePrimaryNumberChange}
					required
				/>
				{showButton && !verifyDetails && (
					<button
						type="button"
						id="get-otp"
						aria-label="Get Otp"
						className="btn btn-primary btn-primary-2"
						onClick={handleOpenOtpPopup}
					>
						Get OTP
					</button>
				)}
				{verifyDetails && (
					<img src={CheckTick} alt="verified" title="verified" className="verified" width="25" height="25" />
				)}
			</li>

			<li className="form-control d-col d-col-2">
				<TextInput
					id="secondary_number"
					name="secondary_number"
					type="text"
					onBlur={formik.handleBlur}
					value={formik.values.secondary_number ?? ''}
					setFieldValue={formik.setFieldValue}
					label="Secondary Mobile No"
					error={
						formik.touched.secondary_number && formik.errors.secondary_number ? formik.errors.secondary_number : null
					}
					onKeyDown={keyDownEvent}
					disabled={isEditing}
					maxLength={10}
					onChange={handleSecondaryNumberChange}
				/>
			</li>

			<li className="d-col d-col-2">
				<Dropdown
					id="religion"
					name="religion"
					label="Select Religion"
					onBlur={formik.handleBlur}
					title={formik.values.religion ?? ''}
					error={formik.touched.religion && formik.errors.religion ? formik.errors.religion : null}
					options={religions}
					setFieldValue={formik.setFieldValue}
					disabled={isEditing}
					required
				/>
			</li>

			<li className="d-col d-col-2">
				<Dropdown
					id="language"
					name="language"
					label="Language"
					title={formik.values.language ?? ''}
					error={formik.touched.language && formik.errors.language ? formik.errors.language : null}
					options={languages}
					onBlur={formik.handleBlur}
					setFieldValue={formik.setFieldValue}
					disabled={isEditing}
					required
				/>
			</li>

			<li className="d-col d-col-2">
				<SingleDatePicker
					className="single-date-picker"
					id="birth_date"
					name="birth_date"
					value={formik.values.birth_date}
					maxDate={effectiveBirthDate}
					label="Date of Birth"
					formik={formik}
					disabled={isEditing}
					required
					error={formik.errors.birth_date ? formik.errors.birth_date : null}
				/>
			</li>

			<li className="d-col d-col-2">
				<SingleDatePicker
					className="single-date-picker"
					id="wedding_anniversary"
					name="wedding_anniversary"
					value={formik.values.wedding_anniversary}
					label="Wedding Anniversary"
					formik={formik}
					disabled={formik.values.unmarried_chekbox || isEditing}
					maxDate={new Date()}
					error={
						formik.touched.wedding_anniversary && formik.errors.wedding_anniversary
							? formik.errors.wedding_anniversary
							: null
					}
				/>
			</li>

			<li className="d-col">
				<Checkbox
					id="unmarried_chekbox"
					name="unmarried_chekbox"
					type="checkbox"
					checked={formik.values.unmarried_chekbox}
					onChange={formik.handleChange}
					label="Unmarried"
					disabled={isEditing}
				/>
			</li>

			<li className="d-col">
				<div className="multi-input-box">
					<div className="inputs-wrap">
						<div className="form-control">
							<TextInput
								id="samaj"
								name="samaj"
								type="text"
								onBlur={formik.handleBlur}
								value={formik.values.samaj ?? ''}
								setFieldValue={formik.setFieldValue}
								label="Samaj"
								disabled={formik.values.samaj_checkbox || isEditing}
								error={formik.touched.samaj && formik.errors.samaj ? formik.errors.samaj : null}
							/>
						</div>
					</div>
					<div className="add-multi-input">
						<Checkbox
							id="samaj_checkbox"
							name="samaj_checkbox"
							type="checkbox"
							checked={formik.values.samaj_checkbox}
							onChange={formik.handleChange}
							label="N/A"
							disabled={isEditing}
						/>
					</div>
				</div>
			</li>
			<GenerateOTP
				primaryNumber={formik.values.primary_number}
				setVerifyDetails={() => setVerifyDetails(true)}
				openOtpModal={openOtpModal}
				setOpenOtpModal={setOpenOtpModal}
			/>
		</>
	);
};
