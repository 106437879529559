import { RedemptionHistoryDetails } from '@services/hooks/contractor360';
import { Value } from '@wojtekmaj/react-daterange-picker/dist/cjs/shared/types';

export interface BankingHistoryObject {
	couponCode: string;
	productCode: string;
	codeValue: string;
	codeUsedOn: string;
	itemName: string;
	sourceOfCode: string;
	bonusPoint?: string;
	kg: string;
	supervisor_name?: string;
	supervisor_phone?: string;
}

export interface FilterObject {
	limit: number;
	offset: number;
	search: string;
}

export interface IRedemptionHeaderQuery {
	data: RedemptionHistoryDetails | undefined;
	bankingHistoryData: BankingHistoryObject[];
}
export interface IBankingHistoryFilter {
	setSearch: (value: string) => void;
	date: Value;
	setDate: (value: Value) => void;
	records?: { length: string | number; count: string | number };
}

export const PAGE_LIMIT_BANKING = 50;
