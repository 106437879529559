export const formatDateInWord = (now: Date): string => {
	const day: string = String(now.getDate()).padStart(2, '0');
	const month: string = now.toLocaleString('default', { month: 'short' });
	const year: string = String(now.getFullYear()).slice(-2);

	let hours: number = now.getHours();
	const minutes: string = String(now.getMinutes()).padStart(2, '0');
	const ampm: string = hours >= 12 ? 'PM' : 'AM';

	hours = hours % 12;
	hours = hours || 12;
	const formattedHours: string = String(hours).padStart(2, '0');

	return `${day} ${month} '${year}, ${formattedHours}:${minutes} ${ampm}`;
};
export const getDaysBetweenDates = (startDate: Date, endDate = new Date()): number => {
	const start = new Date(startDate);
	const end = new Date(endDate);

	start.setHours(0, 0, 0, 0);
	end.setHours(0, 0, 0, 0);

	const differenceMs = Math.abs(end.getTime() - start.getTime());

	return Math.ceil(differenceMs / (1000 * 60 * 60 * 24));
};
