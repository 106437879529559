import { ReactElement, useCallback, useEffect, useMemo } from 'react';
import { IQuickAddEndUserForm, IQuickAddEndUserProps, quickEndUserFormInitialValues } from '.';
import { Dropdown, TextInput } from '@components/common';
import { FormikValues, useFormik } from 'formik';
import { useValidation } from '@hooks/validations';
import { keyDownEvent } from '@helper/utils';
import usePincodeQuery from '@services/hooks/enduser-details/usePincodeQuery';
import { useFormError } from '@hooks/useFormError';
import { useGetRxDb } from '@hooks/getRxdbData';
import { IEndUserCategoryList, IPrimaryDealerList } from '@components/onetoonemeetings';
import { DropdownOptions } from '@components/common/formControl/dropdown';
import { usePhoneNumberValidateQuery } from '@services/hooks/enduser-details/usePhoneNumberValidateQuery';
import { useDebounce } from '@hooks/useDebounce';
import { useAddQuickEndUser } from '@services/hooks/oneToOneMeetings';
import { ToastType, useTosterStore } from '@store/toster';
import { overlayRemoveFadeAction } from '@components/common/sort';

export const QuickAddEndUser = (props: IQuickAddEndUserProps): ReactElement => {
	const { setIsQuickEndModalOpen } = props;
	const { setToaster } = useTosterStore();
	const { result: primaryDealersList } = useGetRxDb<IPrimaryDealerList>('dealers_list');
	const { result: endUserCategoryList } = useGetRxDb<IEndUserCategoryList>('enduser_category_list');
	const { QuickAdEndUserValidationSchema } = useValidation();
	const {
		setPincode,
		state,
		city,
		isFetching: isPincodeDetailsFetching,
		isError,
		isValidatingZipcode,
	} = usePincodeQuery();
	const { mutate: addQuickEndUser, isPending } = useAddQuickEndUser();

	const formik = useFormik({
		initialValues: quickEndUserFormInitialValues,
		onSubmit: () => {},
		validationSchema: QuickAdEndUserValidationSchema,
		validate: async () => {
			const errors: Partial<FormikValues> = {};
			if (phoneValidationData?.data?.exists && phoneValidationData?.data?.message) {
				const phoneNumberError = phoneValidationData?.data?.message;
				errors.mobileNumber = phoneNumberError;
			}

			return errors;
		},
	});
	const getErrorMessage = useFormError<IQuickAddEndUserForm>(formik);
	const {
		data: phoneValidationData,
		refetch: refetchPhoneValidation,
		isFetching: isPhoneDetailsFetching,
	} = usePhoneNumberValidateQuery(formik.values.mobileNumber);

	const primaryDealers = useMemo((): DropdownOptions[] => {
		if (primaryDealersList?.length) {
			return [...primaryDealersList]
				.sort((a, b) => a.dealername_c.localeCompare(b.dealername_c))
				.map((d) => ({
					id: d?.dealercode_c,
					value: `${d?.dealername_c} - ${d?.dealercode_c}`,
				}));
		}
		return [];
	}, [primaryDealersList]);

	const enduserCategories = useMemo((): DropdownOptions[] => {
		if (endUserCategoryList?.length) {
			return endUserCategoryList.map((d, index) => ({
				id: index,
				value: d?.name,
			}));
		}
		return [];
	}, [endUserCategoryList]);

	useEffect(() => {
		if (state && city) {
			formik.setFieldValue('state', state);
			formik.setFieldValue('city', city);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [state, city]);

	const handleZipcodeChange = useCallback(
		(event: React.ChangeEvent<HTMLInputElement>, field?: string): void => {
			setPincode(event.target.value);
			if (event.target.value.length < 6) {
				formik.setFieldValue('state', '');
				formik.setFieldValue('city', '');
				return;
			}
			field && formik.setFieldValue(field, event.target.value);
		},
		[formik, setPincode]
	);

	const handleDealerChange = useCallback(
		(e: DropdownOptions): void => {
			const dealer = primaryDealersList?.find((d) => d.dealercode_c === e?.id);
			if (dealer) {
				formik.setFieldValue(
					'wssTerritory',
					`${dealer?.hierarchylevel1wssterritory_c} - ${dealer?.descriptionofhierarchylevel1_c}`
				);
				formik.setFieldValue('tsiTerritory', `${dealer?.tsicode_c} - ${dealer?.tsiname_c}`);
				formik.setFieldValue('town', `${dealer?.citycode_c} - ${dealer?.towndescription_c}`);
			}
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[primaryDealersList]
	);

	const handleMobileNumberChange = useDebounce((e: React.ChangeEvent<HTMLInputElement>) => {
		if (e.target.value.length === 10) {
			refetchPhoneValidation?.();
		}
	}, 100);

	const handleClose = (): void => {
		overlayRemoveFadeAction(() => {});
		setIsQuickEndModalOpen(false);
		formik.resetForm();
	};

	const handleSubmit = useCallback(
		(e: React.MouseEvent<HTMLButtonElement, MouseEvent>): void => {
			e.preventDefault();
			const dealer = primaryDealersList?.find(
				(d) => d?.dealercode_c === formik.values.primaryDealer.split('-')[1].trim()
			);
			const payload = {
				firstname: formik.values.firstName,
				lastname: formik.values.lastName,
				phone: formik.values.mobileNumber,
				segment_c: formik.values.endUserCategory,
				primarydealercode: dealer?.dealercode_c,
				primarydealername: dealer?.dealername_c,
				wss_terri_code_c: dealer?.hierarchylevel1wssterritory_c,
				tsi_territory_c: dealer?.tsicode_c,
				town_name_c: dealer?.towndescription_c,
				town_code_c: dealer?.citycode_c,
				permanentcity_c: formik.values.city,
				permanentstate_c: formik.values.state,
				permanentzipcode_c: formik.values.zipcode,
			};

			addQuickEndUser?.(payload, {
				onSuccess: () => {
					setToaster(true, ToastType.success, `Quick add end user created successfully`);
					setIsQuickEndModalOpen(false);
					formik.resetForm();
				},
				onError: () => {
					setToaster(true, ToastType.error, `Error adding quick end user!`);
				},
			});
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[formik.values, primaryDealersList, addQuickEndUser]
	);

	return (
		<div className="quick-add-user-wrapper">
			<div className="quick-add-heading">Quick Add End User</div>
			<form>
				<div className="quick-add-user-form">
					<ul className="row">
						<li className="form-control d-col ">
							<TextInput
								id="firstName"
								name="firstName"
								type="text"
								onBlur={formik.handleBlur}
								value={formik.values.firstName}
								label="First Name"
								setFieldValue={formik.setFieldValue}
								error={getErrorMessage('firstName')}
								required
							/>
						</li>
						<li className="form-control d-col ">
							<TextInput
								id="lastName"
								name="lastName"
								type="text"
								onBlur={formik.handleBlur}
								value={formik.values.lastName}
								label="Last Name"
								setFieldValue={formik.setFieldValue}
								error={getErrorMessage('lastName')}
								required
							/>
						</li>
						<li className="form-control d-col ">
							<TextInput
								id="mobileNumber"
								name="mobileNumber"
								type="text"
								onBlur={formik.handleBlur}
								value={formik.values.mobileNumber}
								label="Mobile Number"
								setFieldValue={formik.setFieldValue}
								error={getErrorMessage('mobileNumber')}
								onKeyDown={keyDownEvent}
								onChange={handleMobileNumberChange}
								maxLength={10}
								required
							/>
						</li>
						<li className="form-control d-col ">
							<Dropdown
								id="endUserCategory"
								name="endUserCategory"
								label="End User Category"
								onBlur={formik.handleBlur}
								title={formik.values.endUserCategory ?? ''}
								options={enduserCategories}
								setFieldValue={formik.setFieldValue}
								error={getErrorMessage('endUserCategory')}
								blurValidate
								required
							/>
						</li>
						<li className="form-control d-col ">
							<Dropdown
								id="primaryDealer"
								name="primaryDealer"
								label="Primary Dealer"
								onBlur={formik.handleBlur}
								title={formik.values.primaryDealer ?? ''}
								options={primaryDealers}
								setFieldValue={formik.setFieldValue}
								error={getErrorMessage('primaryDealer')}
								onChange={handleDealerChange}
								blurValidate
								required
							/>
						</li>
						<li className="form-control d-col ">
							<TextInput
								id="wssTerritory"
								name="wssTerritory"
								type="text"
								onBlur={formik.handleBlur}
								value={formik.values.wssTerritory}
								label="WSS Territory"
								setFieldValue={formik.setFieldValue}
								error={getErrorMessage('wssTerritory')}
								disabled
								required
							/>
						</li>
						<li className="form-control d-col ">
							<TextInput
								id="tsiTerritory"
								name="tsiTerritory"
								type="text"
								onBlur={formik.handleBlur}
								value={formik.values.tsiTerritory}
								label="TSI Territory"
								setFieldValue={formik.setFieldValue}
								error={getErrorMessage('tsiTerritory')}
								disabled
							/>
						</li>
						<li className="form-control d-col ">
							<TextInput
								id="town"
								name="town"
								type="text"
								onBlur={formik.handleBlur}
								value={formik.values.town}
								label="Town"
								setFieldValue={formik.setFieldValue}
								error={getErrorMessage('town')}
								disabled
								required
							/>
						</li>
						<li className="form-control d-col ">
							<TextInput
								id="zipcode"
								name="zipcode"
								type="text"
								onBlur={formik.handleBlur}
								value={formik.values.zipcode}
								label="Zipcode"
								setFieldValue={formik.setFieldValue}
								onChange={handleZipcodeChange}
								error={getErrorMessage('zipcode')}
								onKeyDown={keyDownEvent}
								maxLength={6}
								required
							/>
						</li>
						<li className="form-control d-col ">
							<TextInput
								id="city"
								name="city"
								type="text"
								onBlur={formik.handleBlur}
								value={formik.values.city}
								label="City"
								setFieldValue={formik.setFieldValue}
								error={getErrorMessage('city')}
								disabled
							/>
						</li>
						<li className="form-control d-col ">
							<TextInput
								id="state"
								name="state"
								type="text"
								onBlur={formik.handleBlur}
								value={formik.values.state}
								label="State"
								setFieldValue={formik.setFieldValue}
								error={getErrorMessage('state')}
								disabled
							/>
						</li>
					</ul>
					<div className="button-component">
						<button className="btn btn-primary btn-primary-2" type="button" onClick={handleClose}>
							Cancel
						</button>

						<button
							className="btn btn-primary"
							disabled={
								!formik.isValid ||
								!formik.dirty ||
								isPhoneDetailsFetching ||
								isPending ||
								isPincodeDetailsFetching ||
								isError ||
								isValidatingZipcode
							}
							type="submit"
							onClick={handleSubmit}
						>
							Submit
						</button>
					</div>
				</div>
			</form>
		</div>
	);
};
