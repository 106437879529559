import { ReactElement, useEffect, useState } from 'react';
import { BankingHistoryFilter } from '@components/contractor360/components/bankingHistory/bankingHistoryFilter';
import { Wrapper } from '@components/common/Wrapper/wrapper';
import { BankingData } from '@components/contractor360/components/bankingHistory/bankingCard';
import { useGetSupervisorBankingDetails } from '@services/hooks/supervisor/useGetSupervisorBankingDetails';
import { useLocation } from 'react-router-dom';
import { Value } from '@wojtekmaj/react-daterange-picker/dist/cjs/shared/types';
import { BankingHistoryObject } from '@components/contractor360/components/bankingHistory';
import { useSupervisorBankingHistoryRxDb } from '@services/hooks/supervisor';
import { useInfiniteScroll } from '@components/common';
import { formatDateDDMMYY } from '@helper/utils';

export const SupervisorBankingHistory = (): ReactElement => {
	const [bankingHistoryData, setBankingHistoryData] = useState<BankingHistoryObject[]>([]);
	const [search, setSearch] = useState<string>('');
	const [date, setDate] = useState<Value>([null, null]);
	const { state } = useLocation();
	const { isLoading, error } = useGetSupervisorBankingDetails(state.membershipNumber, state.number, true);

	const {
		result: supervisorBankingDetails,
		isFetching,
		fetchMore,
		totalCount,
	} = useSupervisorBankingHistoryRxDb({
		searchValue: search,
		createddate: date,
	});

	useInfiniteScroll(() => {
		fetchMore();
	});

	const handleDateChange = (date: Value): void => {
		setDate(date);
	};

	useEffect(() => {
		if (supervisorBankingDetails && supervisorBankingDetails.length !== 0) {
			setBankingHistoryData(
				supervisorBankingDetails.map((item) => ({
					couponCode: item.barcode_no ?? '-',
					productCode: item.item_code ?? '-',
					codeValue: item.code_of_value ?? '-',
					codeUsedOn: item.billing_time ? formatDateDDMMYY(new Date(item.billing_time)) : '-',
					itemName: item.item_name ?? '-',
					sourceOfCode: item.source_of_code ?? '-',
					kg: item.weights ?? '-',
					supervisor_name: item.supervisor_name ?? '-',
					supervisor_phone: item.supervisor_phone ?? '-',
				}))
			);
			return;
		}
		setBankingHistoryData([]);
	}, [supervisorBankingDetails]);

	return (
		<section className="banking-history">
			<BankingHistoryFilter
				setSearch={setSearch}
				date={date}
				setDate={handleDateChange}
				records={{
					length: bankingHistoryData.length,
					count: totalCount,
				}}
			/>
			<div className="container">
				<Wrapper isLoaderVisible={isLoading || (isFetching && supervisorBankingDetails.length === 0)} isError={error}>
					<BankingData isSupervisor={true} bankingHistoryData={bankingHistoryData} cardData="banking" />
				</Wrapper>
			</div>
		</section>
	);
};
