import { useCloseLeadsPopup } from '@store/closeLeadsPopupStore';
import { Dropdown } from '../formControl';
import { ReactElement, useEffect } from 'react';
import { useFormik } from 'formik';
import { useCloseLeadsMutation } from '@services/hooks/enduser-details/useCloseleadsMutation';
import { useLeadUserQuery } from '@services/hooks/endusers/leadUserQuery';
import { useRxData } from 'rxdb-hooks';
import { AllEndUserObject } from '@components/members/components/allEndUserList';
import { UserCode } from '@services/hooks/enduser-details';
import { InitialCloseLeads } from '.';
import { useUserQuery } from '@services/hooks/enduser-details/useUserQuery';
import { ToastType, useTosterStore } from '@store/toster';

export const CloseLeadsModal = (): ReactElement => {
	const { data, closeLeadObject, setModal, isOpen } = useCloseLeadsPopup((state) => state);
	const { mutate, isPending } = useCloseLeadsMutation(`${closeLeadObject.id}`);
	const { refetch } = useLeadUserQuery();
	const { result } = useRxData<AllEndUserObject>('all', (collection) => collection.find());
	useUserQuery();
	const { setToaster } = useTosterStore();
	const { result: endUserList } = useRxData<UserCode>('user_list', (collection) => collection.find());

	const initialValues: InitialCloseLeads = {
		closeLeads: '',
		alternate_number: '',
		user_mdiCode: '',
	};

	const formik = useFormik({
		initialValues: initialValues,
		onSubmit: () => {
			handleSubmitForm();
		},
	});

	useEffect(() => {
		formik.resetForm();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isOpen]);

	const handleSubmitForm = (): void => {
		const segment = data.find((segment) => segment.value === formik.values.closeLeads)?.name || '';
		const { firstIndex: name } = extractNameAndMobile(formik.values.alternate_number);
		const selectedOptionData = result.find((option) => option._data.name === name);
		const id = selectedOptionData ? selectedOptionData._data.id : null;

		const { firstIndex: mdiCode } = extractNameAndMobile(formik.values.user_mdiCode);
		const selectedData = endUserList.find((option) => option._data.mdicode_c === mdiCode);
		const sfid = selectedData ? selectedData._data.sfid : null;

		let basicFormData = null;

		switch (segment) {
			case 'existing_contractor':
				basicFormData = { alternate_mobile_number: closeLeadObject.number, account_id: id };
				break;
			case 'lead_transfer':
				basicFormData = {
					assigned_imr_c: sfid,
					imr_code_c: mdiCode,
				};
				break;
		}

		mutate(
			{ segment, ...(basicFormData && { basicFormData }) },
			{
				onSuccess: () => {
					setToaster(true, ToastType.success, 'LeadTransfer Successfully');
					if (refetch) {
						refetch();
					}
					setModal(false);
				},
				onError: (error) => {
					setToaster(true, ToastType.error, error.message);
				},
			}
		);
	};

	const extractNameAndMobile = (value: string): { firstIndex: string; lastIndex: string } => {
		const lastHyphenIndex = value.lastIndexOf('-');
		const firstIndex = value.slice(0, lastHyphenIndex).trim();
		const lastIndex = value.slice(lastHyphenIndex + 1).trim();
		return { firstIndex, lastIndex };
	};

	return (
		<div className="summary-pop-content">
			<form onSubmit={formik.handleSubmit}>
				<span className="title h3">{closeLeadObject.name}</span>
				<span className="select-title h4">Please select a reason for closure of site.</span>
				<div className="closer-reason-wrapper">
					<div className="closer-reason">
						<Dropdown
							title={formik.values.closeLeads}
							options={data}
							name="closeLeads"
							id="closeLeads"
							setFieldValue={formik.setFieldValue}
							onBlur={formik.handleBlur}
							blurValidate={true}
						/>

						{formik.values.closeLeads !== 'Lead Transfer' && formik.values.closeLeads.length > 0 && (
							<p className="error-message show">Note: We will mark status as Invalid Lead</p>
						)}
					</div>

					{formik.values.closeLeads === 'Lead Transfer' && (
						<div className="closer-reason">
							<Dropdown
								id="user_mdiCode"
								name="user_mdiCode"
								label="Select BDE"
								title={formik.values.user_mdiCode ?? ''}
								options={endUserList?.map((item) => ({
									id: item._data?.sfid,
									value: item._data.mdicode_c + ` - ${item._data.name}`,
								}))}
								setFieldValue={formik.setFieldValue}
								onBlur={formik.handleBlur}
								blurValidate={true}
							/>
						</div>
					)}

					{formik.values.closeLeads === 'Existing Contractor' && (
						<div className="closer-reason">
							<Dropdown
								id="alternate_number"
								label="Select End User"
								title={formik.values.alternate_number}
								options={result?.map((item) => ({
									id: item._data?.id,
									value: item._data.name + ` - ${item._data.phone}`,
								}))}
								name="alternate_number"
								setFieldValue={formik.setFieldValue}
								onBlur={formik.handleBlur}
								blurValidate={true}
							/>
							{formik.values.alternate_number.length > 0 && (
								<p className="error-message show">
									Note: Current mobile no. will be merged with secondary mobile no. of selected end user
								</p>
							)}
						</div>
					)}
				</div>
				<div className="btn-wrapper">
					<button
						type="button"
						aria-label="conformation button"
						className="cancel-btn btn-primary btn close"
						onClick={() => setModal(false)}
					>
						Cancel
					</button>
					<button
						type="submit"
						aria-label="conformation button"
						className="btn-primary btn"
						disabled={
							!formik.values.closeLeads ||
							(formik.values.closeLeads === 'Existing Contractor' && !formik.values.alternate_number) ||
							(formik.values.closeLeads === 'Lead Transfer' && !formik.values.user_mdiCode) ||
							isPending
						}
					>
						Confirm
					</button>
				</div>
			</form>
		</div>
	);
};
