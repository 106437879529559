import { DropdownOptions } from '@components/common/formControl/dropdown';
import { FormikValues } from 'formik';

export interface IdefaultAddSiteLeadFormInitialValues {
	siteType: string;
	dealerName: string;
	gluePotentialOfSite: string | null;
	owner_details: TAccordianDetails[];
	architect_details: TAccordianDetails[];
	demoedProducts?: string | null;
	discussedProducts?: string | null;
	availableProducts: DropdownOptions[];
	demoedProductDetails: TProductDetails[];
	discussedProductDetails: TProductDetails[];
	productImages: File[];
	veneer: string | null;
	isContractorAvailable: string | null;
}

export const defaultSiteAddressFormInitialValues: IdefaultAddSiteLeadFormInitialValues = {
	siteType: 'Residential',
	dealerName: '',
	gluePotentialOfSite: '',
	architect_details: [{ id: 0, isActive: true, name: '', number: '' }],
	owner_details: [{ id: 0, isActive: true, name: '', number: '' }],
	demoedProducts: '',
	discussedProducts: '',
	availableProducts: [],
	demoedProductDetails: [{ id: 0, product: null }],
	discussedProductDetails: [{ id: 0, product: null }],
	productImages: [],
	veneer: '',
	isContractorAvailable: '',
};

export type TAccordianDetails = {
	id: number;
	name: string;
	number: string;
	isActive: boolean;
};

export type TProductDetails = {
	id: number;
	product: string | null;
};

export interface IDemoedProductProps {
	formik: TFormik;
	productIndex: number;
	fieldName: string;
}
export interface IDiscussedProductsProps {
	formik: TFormik;
	productIndex: number;
	fieldName: string;
}

export type TFormik = {
	values: FormikValues;
	setFieldValue: (field: string, value: string | string[] | boolean, shouldValidate?: boolean) => void;
	touched: FormikValues;
	errors: FormikValues;
	handleBlur?: (e: React.FocusEvent<HTMLInputElement>) => void;
};

export interface IProductImagesProps {
	fieldName: string;
	formik: TFormik;
	handleAddImage: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

export interface IRemoveImageProps {
	fieldName: string;
	formik: TFormik;
	imgIndex: number;
}

export const veneerOptions: DropdownOptions[] = [
	{ id: 1, value: 'Yes' },
	{ id: 2, value: 'No' },
];
