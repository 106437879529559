import { ReactElement, useMemo } from 'react';
import { IProductUsage, productUsage } from '.';

export const ProductUsage = (props: IProductUsage): ReactElement => {
	const { productUsageData } = props;

	const data = useMemo(() => {
		return productUsageData?.sort((a, b) => {
			if (a.product_code.toLowerCase() === 'others') return 1;
			if (b.product_code.toLowerCase() === 'others') return -1;
			return b.percentage - a.percentage;
		});
	}, [productUsageData]);

	return (
		<section className="product-usage-wrapper">
			<div className="container">
				<h2 className="h3">Product Usage</h2>
				<div className="product-usage-inner">
					{data?.map((product, index) => {
						return (
							<div
								key={`${product.product_code}-${index}`}
								className="progress"
								role="progressbar"
								aria-label={`Segment ${index + 1}`}
								aria-valuenow={product.percentage}
								aria-valuemin={0}
								aria-valuemax={100}
								style={{ width: `${product.percentage}%` }}
							>
								<div className={`progress-bar primary-${productUsage[index]?.classname ?? 'transparent'}`}></div>
							</div>
						);
					})}
				</div>
				<div className="progress-labels">
					{productUsageData?.map((product, index) => {
						return (
							<span key={`${product.product_code}-${index}`} className="progress-label-wrapper">
								<span className={`progress-circle primary-${productUsage[index]?.classname ?? 'transparent'}`}></span>
								<span className="h4">
									{product.product_name}
									<span className="progress-value h5">({Math.round(product.percentage)}%)</span>
								</span>
							</span>
						);
					})}
				</div>
			</div>
		</section>
	);
};
