import { ITabList } from '@components/common/navigator-tab';
import { IRedirectUrl } from '@components/common';
export const endUserPaths = ['/end-users', '/end-users/new-leads', '/end-users/all'];
export const MemberTabList: ITabList[] = [
	{
		key: 1,
		name: 'Adopted Contractor',
		pathUrl: '/end-users',
		tabIcon: 'icon-user',
	},
	{
		key: 2,
		name: 'New Leads',
		pathUrl: '/end-users/new-leads',
		tabIcon: 'icon-user',
	},
	{
		key: 3,
		name: 'All',
		pathUrl: '/end-users/all',
		tabIcon: 'icon-user',
	},
];

export const AddNewOptionList: IRedirectUrl[] = [
	{
		key: 'add-new-lead',
		ariaLabel: 'Add New Lead',
		route: '/add-member-details/basic-details',
		icon: 'icon-user',
	},
	{
		key: 'add-new-supervisor',
		ariaLabel: 'Add New Supervisor',
		route: '/supervisor/add-supervisor',
		icon: 'icon-user',
	},
];

export * from './endUserList';
