import { Dropdown, SingleDatePicker, SubmitButton, TextInput } from '@components/common';
import { useFormik } from 'formik';
import { ReactElement, useCallback, useEffect, useMemo, useState } from 'react';
import { useValidation } from '@hooks/validations';
import { Wrapper } from '@components/common/Wrapper/wrapper';
import '@assets/styles/pages/addNewMeetings.scss';
import { defaultAddMeetingInitialValues, TSIMeetingTypes } from '.';
import { TimePicker } from '@components/common/time-picker/time-picker';
import {
	calculateEndTimeAndDate,
	compareDatePartsOnly,
	convertTime,
	formatDate,
	formatTime,
	generateTimeOptions,
	getCoordinatesFromTownName,
	isTimePast,
	keyDownEvent,
	RoleType,
	roundToNearestQuarterHour,
} from '@helper/utils';
import { useMeetingMutation } from '@services/hooks/meetings/useMeetingMutation';
import { ClubDetails, IMRCode, TownList } from '@services/hooks/enduser-details';
import { useGetRxDb } from '@hooks/getRxdbData';
import { useTownDetailsQuery } from '@services/hooks/enduser-details/useTownDetailsQuery';
import { ProfileData } from '@services/hooks/profile';
import { useProfileQuery } from '@services/hooks/profile/useProfileQuery';
import { meetFormate, meetingTheme } from '@config/constant';
import { NavLink, useLocation, useNavigate } from 'react-router-dom';
import MultiselectDropdown from '@components/common/formControl/multiselect-dropdown/multiselectDropdown';
import { useMeetingsTypesQuery } from '@services/hooks/meetings/useMeetingTypesQuery';
import { MeetingDetails, MeetingType } from '@services/hooks/meetings';
import { useNonAttendedMembersStore } from '@store/nonAttendedMembersStore';
import { AddProductsFields } from '../productFields/addProductFileds';
import { useMeetingsListQuery } from '@services/hooks/meetings/useMeetingsListQuery';
import { ToastType, useTosterStore } from '@store/toster';
import { useGetMeetingQuery } from '@services/hooks/meetings/useGetMeetingQuery';
import { useSetMainHeader } from '@hooks/useSetMainHeader';
import { Modal } from '@components/common/modal/modal';
import { useGetClubRecommendationsMutation } from '@services/hooks/meetings/useGetClubRecomdationsQuery';
import { ClubRecommendation } from '../clubRecommendations/clubRecommendationModal';
import { ClubRecommendationResponse } from '../clubRecommendations';
import { IPrimaryDealerList } from '@components/onetoonemeetings';
import { useComponentLevelAccessCheck } from '../../../../authGuard/hooks/useComponentLevelAccessCheck';
import { useIMRCodeListQuery } from '@services/hooks/enduser-details/useIMRCodeListQuery';
import { DropdownOptions } from '@components/common/formControl/dropdown';

export const AddNewMeeting = (): ReactElement => {
	const { state } = useLocation();
	const { AddMeetingValidationSchema } = useValidation();
	const { setNonAttendedMembers, nonAttendedMembers, resetMembersStore } = useNonAttendedMembersStore();
	const { mutate, isPending: isMeetingFormPending } = useMeetingMutation(state?.id);
	const { refetch } = useMeetingsListQuery();
	const { setToaster } = useTosterStore();
	const [isCoordinatesFetching, setIsCoordinatesFetching] = useState(false);
	const { data, isFetching, error } = useGetMeetingQuery(state?.id);
	useSetMainHeader(state?.id ? data?.name : 'Add New Meeting');

	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	const [_, setRecommendedData] = useState<ClubRecommendationResponse>();

	const { mutate: clubRecommendationMutate } = useGetClubRecommendationsMutation();
	const { result: primaryDealersList } = useGetRxDb<IPrimaryDealerList>('dealers_list');
	const checkTSIPermissions = useComponentLevelAccessCheck('bde-meeting-fields');

	const formik = useFormik({
		initialValues: nonAttendedMembers || defaultAddMeetingInitialValues,
		onSubmit: () => {
			handleSubmit();
		},
		validationSchema: AddMeetingValidationSchema,
	});
	const navigate = useNavigate();
	const [clubRecommendation, setClubRecommendation] = useState<boolean>(false);
	const checkBdeIMR = useComponentLevelAccessCheck('imr-dropdown');

	const { result: clubOptions } = useGetRxDb<ClubDetails>('club');

	useProfileQuery();
	const { result: profile } = useGetRxDb<ProfileData>('profile');

	useMeetingsTypesQuery();
	const { result: meetingTypes } = useGetRxDb<MeetingType>('meeting_types');
	const meetingTypesFormatted = useMemo(
		() =>
			meetingTypes.map((item) => ({
				id: item.id,
				value: item.name ?? '',
			})),
		[meetingTypes]
	);

	useIMRCodeListQuery();
	const { result: IMRCodeList } = useGetRxDb<IMRCode>('imr_code_list');
	const IMRCodeListData = useMemo((): DropdownOptions[] => {
		if (IMRCodeList?.length) {
			return IMRCodeList?.map((imrCode) => ({
				id: imrCode.imr_code_c,
				value: imrCode.imr_code_c,
			}));
		}
		return [];
	}, [IMRCodeList]);

	useEffect(() => {
		if (nonAttendedMembers === null) {
			formik.setFieldValue('mdi', profile[0]?.mdicode_c);
			formik.setFieldValue('name', profile[0]?.name);
			formik.setFieldValue('contact', profile[0]?.phone_number_c);
			formik.setFieldValue('role', profile[0]?.usertype_c);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [nonAttendedMembers, profile]);

	const clubOptionsFormatted = useMemo(
		() =>
			clubOptions.map((item) => ({
				id: item?.sfid ? item.sfid.toString() : item.id.toString(),
				value: item.name ?? '',
			})),
		[clubOptions]
	);

	useTownDetailsQuery();
	const { result: towns } = useGetRxDb<TownList>('towns');

	const townsList = useMemo(() => {
		if (towns?.length) {
			return towns?.map((t) => ({
				id: t.id,
				value: t.towncode_c + ' - ' + t.towndescription_c,
			}));
		}
		return [];
	}, [towns]);

	useEffect(() => {
		if (data && nonAttendedMembers === null) {
			const foundTown = towns.find((item) => item.towncode_c?.trim() === data?.town_venue_c?.trim());
			formik.setValues(
				{
					name: data?.name,
					meetingType: data?.meeting_type_c,
					mdi: profile[0]?.mdicode_c,
					startdate: data?.startdate ? new Date(data.startdate).toISOString() : '',
					enddate: data?.enddate ? new Date(data.enddate).toISOString() : '',
					start_time_c: convertTime(data?.start_time_c),
					end_time_c: convertTime(data?.end_time_c),
					club:
						data?.club_name_c?.split(',').map((club, index) => ({
							value: club,
							id: data?.club_name_id_c?.split(',')[index] || '',
						})) ?? [],
					meetingVenue: data?.meeting_venue_c,
					meetFormat: 'Physical',
					product1Discussed: data?.focus_product_1,
					product2Discussed: data?.focus_product_2,
					primaryFocusProduct: data?.focus_product_4,
					secondaryFocusProduct: data?.focus_product_5 ?? '',
					participants: data?.members.map((item) => ({
						club_name_c: item.club_name_c,
						contactid: item.contactid,
						membershipno_c: item.membershipno_c,
					})),
					contact: data?.mobileno_c ? parseInt(data.mobileno_c) : '',
					town: foundTown?.towncode_c ? `${foundTown.towncode_c} - ${foundTown.towndescription_c}` : data?.town_venue_c,
					status: data?.status ?? '',
					meetingTheme: data?.meeting_theme_c ?? '',
					fccClub: data?.meeting_type_c === 'FCC PRODUCT AWARENESS MEET' ? data?.club_name_c : '',
					imr_code: [
						{ id: data.imr_code, value: data.imr_code },
						{ id: data.imr_code_2, value: data.imr_code_2 },
					].filter((item) => item.value != null),
					dealer: `${data?.dealer_name_c} - ${data?.dealer_code_c}`,
				},

				true
			);
			formik.setFieldTouched('startdate', true);
			formik.setFieldTouched('endate', true);
			formik.setFieldTouched('start_time_c', true);
			formik.setFieldTouched('end_time_c', true);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [data, nonAttendedMembers, towns]);

	const primaryDealers = useMemo(() => {
		if (primaryDealersList?.length) {
			return [...primaryDealersList]
				.sort((a, b) => a.dealername_c.localeCompare(b.dealername_c))
				.map((d) => ({
					id: d?.dealercode_c,
					value: `${d?.dealername_c} - ${d?.dealercode_c}`,
				}));
		}
		return [];
	}, [primaryDealersList]);

	const handleSubmit = useCallback(async () => {
		setIsCoordinatesFetching(true);
		const { latitude, longitude, error } = await getCoordinatesFromTownName(
			formik.values.town?.split('-')?.[1]?.trim()
		);
		if (error) {
			setIsCoordinatesFetching(false);
		}
		setIsCoordinatesFetching(false);
		if (
			compareDatePartsOnly(new Date(), new Date(formik.values.startdate)) &&
			isTimePast(formatTime(roundToNearestQuarterHour(new Date(formik.values.startdate))), formik.values.start_time_c)
		) {
			setToaster(true, ToastType.error, 'Unable to create past meetings');
		}

		const payload: MeetingDetails = {
			meeting_type_c: formik.values.meetingType,
			mdi_name_c: formik.values.name,
			startdate: formatDate(new Date(formik.values.startdate)),
			enddate: formatDate(new Date(formik.values.enddate)),
			start_time_c:
				generateTimeOptions()
					.find((item) => item.value === formik.values.start_time_c)
					?.id.toString() ?? '',
			end_time_c:
				generateTimeOptions()
					.find((item) => item.value === formik.values.end_time_c)
					?.id.toString() ?? '',
			club_name_c: formik.values.club.map((item) => item.value).join(),
			meeting_venue_c: formik.values.meetingVenue,
			meetFormat: 'Physical',
			meeting_theme_c: formik.values.meetingTheme,
			focus_product_1: formik.values.product1Discussed,
			focus_product_2: formik.values.product2Discussed,
			focus_product_4: formik.values.primaryFocusProduct,
			focus_product_5: formik.values.secondaryFocusProduct,
			status: formik.values.status,
			members: formik.values.participants,
			club_name_id_c: formik.values.club.map((item) => item.id.toString()).join(),
			town_venue_c:
				towns?.find((item) => item?.towndescription_c?.trim() === formik.values.town?.split('-')?.[1]?.trim())
					?.towncode_c ?? '',
			longitude_against_town: longitude ?? 0,
			latitude_against_town: latitude ?? 0,
			mobileno_c: formik.values.contact.toString(),
			town_city:
				towns?.find((item) => item?.towndescription_c?.trim() === formik.values.town?.split('-')?.[1]?.trim())
					?.towndescription_c ?? '',
		};

		if (!checkBdeIMR) {
			const [imrCode1, imrCode2] = formik.values.imr_code;
			imrCode1?.value && (payload.imr_code = imrCode1.value.toString());
			imrCode2?.value && (payload.imr_code_2 = imrCode2.value.toString());
		}

		if (checkTSIPermissions) {
			const dealer = primaryDealers.find((item) => item.value === formik.values.dealer);
			if (dealer) {
				payload.dealer_name_c = dealer?.value.split('-')[0]?.trim();
				payload.dealer_code_c = dealer?.id;
			}
		}

		mutate(payload, {
			onSuccess: () => {
				if (state?.id) {
					navigate('/meeting-details', { state: { id: state?.id } });
					setToaster(true, ToastType.success, 'Meeting Updated Successfully');
					resetMembersStore();
				} else {
					navigate('/meetings');
					setToaster(true, ToastType.success, 'Meeting Added Successfully');
					refetch?.();
					resetMembersStore();
				}
			},
			onError: (error) => {
				setToaster(true, ToastType.error, error.message);
			},
		});
	}, [
		formik.values,
		towns,
		checkBdeIMR,
		checkTSIPermissions,
		mutate,
		setToaster,
		primaryDealers,
		state?.id,
		navigate,
		resetMembersStore,
		refetch,
	]);

	const handleSubmitClick = (): void => {
		formik.handleSubmit();
	};

	useEffect(() => {
		if (
			compareDatePartsOnly(new Date(), new Date(formik.values.startdate)) &&
			isTimePast(formatTime(roundToNearestQuarterHour(new Date(formik.values.startdate))), formik.values.start_time_c)
		) {
			formik.setFieldValue('start_time_c', formatTime(roundToNearestQuarterHour(new Date(formik.values.startdate))));
		}
		if (formik.values.start_time_c && formik.values.startdate) {
			const startDate = new Date(formik.values.startdate);
			const { endTime, endDate } = calculateEndTimeAndDate(formik.values.start_time_c, startDate);
			formik.setFieldValue('end_time_c', endTime);
			if (
				endDate.getDate() !== startDate.getDate() ||
				endDate.getMonth() !== startDate.getMonth() ||
				endDate.getFullYear() !== startDate.getFullYear()
			) {
				formik.setFieldValue('enddate', endDate);
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [formik.values.start_time_c, formik.values.startdate]);

	const handleClubRecommended = useCallback(
		(id: string) => {
			clubRecommendationMutate(
				{
					club_id: id,
					start_date: formatDate(new Date(formik?.values?.startdate)),
				},
				{
					onSuccess: (data) => {
						setRecommendedData(data);
						setClubRecommendation(true);
					},
					onError(error) {
						setToaster(true, ToastType.error, error.message);
					},
				}
			);
		},
		[clubRecommendationMutate, formik?.values?.startdate, setToaster]
	);

	const handleClubValidationModal = useCallback(() => {
		setClubRecommendation(false);
		setRecommendedData(undefined);
	}, []);

	const renderClubSelection = (): JSX.Element | null => {
		if (checkTSIPermissions) return null;

		if (formik.values.meetingType === 'FCC PRODUCT AWARENESS MEET') {
			return (
				<li className="d-col d-col-2">
					<Dropdown
						id="fccClub"
						name="fccClub"
						label="Club"
						onBlur={formik.handleBlur}
						title={formik.values.fccClub ?? ''}
						error={formik.touched.fccClub && formik.errors.fccClub ? formik.errors.fccClub : null}
						options={clubOptionsFormatted}
						setFieldValue={formik.setFieldValue}
						required
						onChange={(e) => {
							const user = clubOptionsFormatted.find((item) => item.id === e.id);
							formik.setFieldValue('club', [
								{
									value: user?.value,
									id: user?.id,
								},
							]);
							handleClubRecommended(user?.id.toString() ?? '');
							formik.setFieldValue('participants', []);
						}}
					/>
				</li>
			);
		}

		return (
			<li className="d-col d-col-2">
				<MultiselectDropdown
					id="club"
					name="club"
					label="Club"
					onBlur={formik.handleBlur}
					values={formik.values.club}
					options={clubOptionsFormatted}
					setFieldValue={formik.setFieldValue}
					required
					className="input-border"
				/>
			</li>
		);
	};

	const isManageParticipantsDisabled = useMemo(() => {
		const dealerCode = formik.values.dealer?.split('-')?.[1]?.trim();
		const foundDealer = primaryDealersList?.some((d) => d?.dealercode_c === dealerCode);
		const userType = profile[0]?.usertype_c;

		return (
			(userType !== RoleType.TSI &&
				formik.values.meetingType === 'FCC PRODUCT AWARENESS MEET' &&
				!formik.values.fccClub?.length) ||
			(userType !== RoleType.TSI &&
				formik.values.meetingType !== 'FCC PRODUCT AWARENESS MEET' &&
				!formik.values.club?.length) ||
			(userType === RoleType.TSI && !foundDealer)
		);
	}, [
		primaryDealersList,
		profile,
		formik.values.meetingType,
		formik.values.fccClub?.length,
		formik.values.club?.length,
		formik.values.dealer,
	]);

	return (
		<>
			<div className="form-popup show">
				<section className="new-meeting-section new-site-desktop overflow desktop-popup">
					<div className="container">
						<Wrapper isError={error} isLoaderVisible={isFetching}>
							<>
								<form onSubmit={formik.handleSubmit}>
									<ul className="new-meeting-wrapper row">
										<li className="form-control d-col d-col-2 ">
											<Dropdown
												id="meetingType"
												name="meetingType"
												label="Meeting Type"
												onBlur={formik.handleBlur}
												title={formik.values.meetingType ?? ''}
												error={
													formik.touched.meetingType && formik.errors.meetingType ? formik.errors.meetingType : null
												}
												options={checkTSIPermissions ? TSIMeetingTypes : meetingTypesFormatted}
												setFieldValue={formik.setFieldValue}
												required
												onChange={(e) => {
													if (e.value === 'FCC PRODUCT AWARENESS MEET') {
														formik.setFieldValue('club', []);
														formik.setFieldValue('fccClub', '');
														formik.setFieldValue('participants', []);
													}
												}}
											/>
										</li>

										<li className="form-control d-col d-col-2 location">
											<TextInput
												id="mdi"
												name="mdi"
												onBlur={formik.handleBlur}
												type="text"
												value={formik.values.mdi ?? ''}
												setFieldValue={formik.setFieldValue}
												label="MDI"
												error={formik.touched.mdi && formik.errors.mdi ? formik.errors.mdi : null}
												required
												disabled
											/>
										</li>

										<li className="form-control d-col d-col-2 ">
											<TextInput
												id="name"
												name="name"
												type="text"
												onBlur={formik.handleBlur}
												value={formik.values.name ?? ''}
												setFieldValue={formik.setFieldValue}
												label="Name"
												error={formik.touched.name && formik.errors.name ? formik.errors.name : null}
												required
												disabled
											/>
										</li>
										<li className="form-control d-col d-col-2 ">
											<TextInput
												id="contact"
												name="contact"
												type="text"
												onBlur={formik.handleBlur}
												value={formik.values.contact ?? ''}
												setFieldValue={formik.setFieldValue}
												label="Contact"
												error={formik.touched.contact && formik.errors.contact ? formik.errors.contact : null}
												onKeyDown={keyDownEvent}
												maxLength={10}
												required
												disabled
											/>
										</li>

										<li className="d-col">
											<div className="meeting-box-wrapper">
												<ul>
													<li>
														<SingleDatePicker
															className="single-date-picker"
															label="Meeting Start Date"
															name="startdate"
															id="startdate"
															value={formik.values.startdate}
															error={
																formik.touched.startdate && formik.errors.startdate ? formik.errors.startdate : null
															}
															required={true}
															formik={formik}
															minDate={state?.id && data ? undefined : new Date()}
															onChange={(value) => {
																formik.setFieldValue('enddate', value);
															}}
														/>
													</li>
													<li>
														<SingleDatePicker
															className="single-date-picker"
															id="enddate"
															name="enddate"
															value={formik.values.enddate}
															label="Meeting End Date"
															formik={formik}
															required={true}
															error={formik.touched.enddate && formik.errors.enddate ? formik.errors.enddate : null}
															minDate={state?.id && data?.startdate ? undefined : new Date(formik.values.startdate)}
														/>
													</li>
													<li>
														<TimePicker
															name="start_time_c"
															id="start_time_c"
															label="Meeting Start Time"
															error={
																formik.touched.start_time_c && formik.errors.start_time_c
																	? formik.errors.start_time_c
																	: null
															}
															required={true}
															value={formik.values.start_time_c}
															formik={formik}
															icon={'icon-clock'}
															minTime={
																compareDatePartsOnly(new Date(), new Date(formik.values.startdate))
																	? formatTime(roundToNearestQuarterHour(new Date(formik.values.startdate)))
																	: ''
															}
														/>
													</li>

													<li>
														<TimePicker
															name="end_time_c"
															id="end_time_c"
															label="Meeting End Time"
															error={
																formik.touched.end_time_c && formik.errors.end_time_c ? formik.errors.end_time_c : null
															}
															required={true}
															value={formik.values.end_time_c}
															formik={formik}
															icon={'icon-clock'}
														/>
													</li>
												</ul>
											</div>
										</li>

										<li className="d-col d-col-2  ">
											<Dropdown
												id="town"
												name="town"
												label="Town"
												onBlur={formik.handleBlur}
												title={formik.values.town ?? ''}
												error={formik.touched.town && formik.errors.town ? formik.errors.town : null}
												options={townsList}
												setFieldValue={formik.setFieldValue}
												required
											/>
										</li>

										{renderClubSelection()}

										{checkTSIPermissions && (
											<li className="d-col d-col-2  ">
												<Dropdown
													id="dealer"
													name="dealer"
													label="Dealer"
													onBlur={formik.handleBlur}
													title={formik.values.dealer ?? ''}
													error={formik.touched.dealer && formik.errors.dealer ? formik.errors.dealer : null}
													options={primaryDealers}
													setFieldValue={formik.setFieldValue}
													required
												/>
											</li>
										)}

										<li className="form-control d-col d-col-2 ">
											<TextInput
												id="meetingVenue"
												name="meetingVenue"
												type="text"
												onBlur={formik.handleBlur}
												value={formik.values.meetingVenue ?? ''}
												setFieldValue={formik.setFieldValue}
												label="Meeting Venue"
												error={
													formik.touched.meetingVenue && formik.errors.meetingVenue ? formik.errors.meetingVenue : null
												}
												required
												labelClass="meeting-venue-label"
											/>
										</li>

										<li className="d-col d-col-2  ">
											<Dropdown
												id="meetingTheme"
												name="meetingTheme"
												label="Meeting Theme"
												onBlur={formik.handleBlur}
												title={formik.values.meetingTheme ?? ''}
												error={
													formik.touched.meetingTheme && formik.errors.meetingTheme ? formik.errors.meetingTheme : null
												}
												options={meetingTheme}
												setFieldValue={formik.setFieldValue}
											/>
										</li>

										<AddProductsFields
											formik={formik}
											primaryFocusProduct={'primaryFocusProduct'}
											secondaryFocusProduct={'secondaryFocusProduct'}
											product1Discussed={'product1Discussed'}
											product2Discussed={'product2Discussed'}
											isEditing={false}
										/>

										<li className="d-col d-col-2  ">
											<Dropdown
												id="meetFormat"
												name="meetFormat"
												label="Meet Format"
												onBlur={formik.handleBlur}
												title={formik.values.meetFormat ?? ''}
												error={formik.touched.meetFormat && formik.errors.meetFormat ? formik.errors.meetFormat : null}
												options={meetFormate}
												setFieldValue={formik.setFieldValue}
												required
												disabled
											/>
										</li>

										{!checkBdeIMR && (
											<li className="d-col d-col-2  ">
												<MultiselectDropdown
													id="imr_code"
													name="imr_code"
													label="Imr Code"
													onBlur={formik.handleBlur}
													options={IMRCodeListData}
													setFieldValue={formik.setFieldValue}
													values={formik.values.imr_code}
													className="input-border"
													maxLimit={2}
												/>
											</li>
										)}

										<li className="d-col d-col-2">
											<div
												className={`manage-participants-button ${isManageParticipantsDisabled ? 'disable-btn' : ''}`}
											>
												<div className="label">
													<span className="required content">Manage Participants</span>
													{formik.values.participants?.length !== 0 && (
														<span className="count">{formik.values.participants?.length}</span>
													)}
												</div>
												<NavLink
													to={state?.id ? `/edit-meeting/manage-participants` : '/add-new-meeting/manage-participants'}
													aria-label={state?.id ? 'edit participants' : 'add participants'}
													className="add-user"
													onClick={() => {
														setNonAttendedMembers(formik.values);
													}}
												>
													<span className="icon-add-user"></span>
													<span className="icon-right-arrow"></span>
												</NavLink>
											</div>
										</li>
									</ul>
								</form>
								<SubmitButton
									label="Submit"
									isDisabled={!formik.isValid || formik.values.participants?.length == 0 || isCoordinatesFetching}
									isEditing={false}
									handleSubmitClick={handleSubmitClick}
									isPending={isMeetingFormPending}
								/>
							</>
						</Wrapper>
					</div>
				</section>
			</div>
			<Modal
				modalId="metingModal"
				className="dialog-sm pt-25"
				modalOpen={clubRecommendation}
				onModalClose={handleClubValidationModal}
			>
				<ClubRecommendation />
			</Modal>
		</>
	);
};
