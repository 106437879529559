import { ReactElement, useMemo, useState } from 'react';
import { getDraftSite, IContractorSites } from '.';
import { useNavigate } from 'react-router-dom';
import { Wrapper } from '@components/common/Wrapper/wrapper';
import useStepperStore from '@store/stepperStore';
import { Site } from './site';
import { SiteSummaryDetails } from '@services/hooks/contractor360';
import useSiteLeadStore from '@store/siteLeadStore';
import { Modal } from '@components/common/modal/modal';
import { SixMonthsConfirmationModal } from '@components/contractor360/components';
import { useEndUserProfileStore } from '@store/endUserProfileStore';
import { useEndUserProfileService } from '@services/hooks/endUserProfile';
import { ToastType, useTosterStore } from '@store/toster';

export const ContractorSites = (props: IContractorSites): ReactElement => {
	const { data, isGetContractorDetailFetching, error, allSites, isSiteSummaryFetching } = props;
	const [isOpen, setModal] = useState<boolean>(false);
	const navigate = useNavigate();
	const { setCurrentStep } = useStepperStore();
	const { setSiteSfid, setKeyBaseValue } = useSiteLeadStore();
	const { isConformationNeeded, setKeyBaseValue: setEndUserKeyBaseValue } = useEndUserProfileStore();
	const { refetch: endUserProfileService } = useEndUserProfileService(data?.id);
	const { setToaster } = useTosterStore();

	const handleEndUserProfileVerificationStage = (fn: () => void): void => {
		if (isConformationNeeded && data?.id && endUserProfileService) {
			endUserProfileService()
				.then((response) => {
					if (response.isError) {
						setToaster(true, ToastType.error, 'Please contact admin for enrolled details updates.');
						return;
					}
					setEndUserKeyBaseValue('basicDetailsRevalidate', response?.data?.data?.basic);
					setEndUserKeyBaseValue('moreDetailsRevalidate', response?.data?.data?.more);
					setModal(true);
				})
				.catch(() => {
					setToaster(true, ToastType.error, 'Please contact admin for enrolled details updates.');
				});
			return;
		}
		fn();
	};

	const handleAddNewSite = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>): void => {
		e.preventDefault();
		handleEndUserProfileVerificationStage(() => {
			setCurrentStep(0);
			setSiteSfid('');
			setKeyBaseValue('addNewSiteLead', false);
			setKeyBaseValue('siteDetails', {
				name: data?.name,
				number: data?.phone,
				sfid: data?.sfid,
				membershipno: data?.membershipno,
			});
			navigate('/add-new-site');
		});
	};

	const sortedSites = useMemo((): SiteSummaryDetails[] => {
		if (!Array.isArray(allSites)) return [];

		const sortedByCreatedDate = [...allSites].sort((a, b) => {
			const dateA = a?.createddate ? new Date(a.createddate) : null;
			const dateB = b?.createddate ? new Date(b.createddate) : null;

			if (!dateA) return 1;
			if (!dateB) return -1;

			return dateB.getTime() - dateA.getTime();
		});
		const draftSites = sortedByCreatedDate.filter(getDraftSite);
		const nonDraftSites = sortedByCreatedDate.filter((site) => !getDraftSite(site));

		return [...draftSites, ...nonDraftSites];
	}, [allSites]);

	return (
		<>
			<section className="contractor-sites">
				<div className="container">
					<div className="contractor-sites-inner">
						<div className="sites-title">
							<span className="h3">
								Sites<span className="h5">(Last 6 months)</span>
							</span>
							<button
								type="button"
								id="site-list-btn"
								aria-label="add-new-site-button"
								className="btn btn-secondary btn-small add-new-site-btn"
								value="add-new-site-button"
								onClick={handleAddNewSite}
								disabled={isGetContractorDetailFetching}
							>
								Add New Site{' '}
								<span className="add-new-site-icon">
									<span className="icon-plus"></span>
								</span>
							</button>
						</div>
						<Wrapper isLoaderVisible={isGetContractorDetailFetching || isSiteSummaryFetching} isError={error}>
							<ul className="sites-card-list row">
								{allSites?.length ? (
									sortedSites?.map((site, index) => (
										<Site
											handleEndUserProfileVerificationStage={handleEndUserProfileVerificationStage}
											key={site?.id}
											site={site}
											siteIndex={index}
											membershipno={data?.membershipno}
											contractor_name={data?.name}
										/>
									))
								) : (
									<li className="d-col d-col-2">
										<div className="summary-info-main">
											<p className="h3">No Data Found!</p>
										</div>
									</li>
								)}
							</ul>
						</Wrapper>
					</div>
				</div>
			</section>
			<Modal
				modalOpen={isOpen}
				modalId={'closeLeads'}
				onModalClose={() => {
					setModal(false);
				}}
				className="dialog-sm close-site-popup dialog-conform"
			>
				<SixMonthsConfirmationModal
					message={'Kindly review and confirm the highlighted enrollment data.'}
					data={data}
					modalClose={() => setModal(false)}
				/>
			</Modal>
		</>
	);
};
