import { Dropdown, MultiselectDropdown, OtpModal, RadioButton, Stepper, TextInput } from '@components/common';
import { gluePotentialofSiteList, siteRadioTypeList } from '@config/constant';
import { useFormik } from 'formik';
import { ReactElement, useCallback, useEffect, useMemo, useState } from 'react';
import { defaultSiteAddressFormInitialValues, veneerOptions } from '.';
import { AccordianDetailList } from '@components/endUserDetails/components/moreDetailsForm/accordianDetailList';
import { useUpdateSiteLeadMutation } from '@services/hooks/site-leads/useUpdateSiteLeadMutation';
import { useValidation } from '@hooks/validations';
import useStepperStore from '@store/stepperStore';
import useSiteLeadStore from '@store/siteLeadStore';
import { useAvailableProductsListQuery } from '@services/hooks/site-leads/useAvailableProductsListQuery';
import { useGetVisitQuery } from '@services/hooks/site-leads/useGetVisitQuery';
import { useUpdateSiteVisitMutation } from '@services/hooks/site-leads/useUpdateSiteVisit';
import { usePresignedURLQuery } from '@services/hooks/enduser-details/usePresignedURLQuery';
import { useUploadSiteImagesMutation } from '@services/hooks/site-leads/useUploadSiteImagesMutation';
import { ProductImages } from '../siteProducts/productImages';
import { Wrapper } from '@components/common/Wrapper/wrapper';
import { useGetSiteLeadQuery } from '@services/hooks/site-leads/useGetSiteLeadQuery';
import { DealersList } from '@components/endUserDetails/components/moreDetailsForm';
import { AvailableProductsList, UpdateSiteLeadData, UpdateSiteVisitData } from '@services/hooks/site-leads';
import { useGetRxDb } from '@hooks/getRxdbData';
import { useDealersQuery } from '@services/hooks/enduser-details/useDealerQuery';
import { useSetMainHeader } from '@hooks/useSetMainHeader';
import { isNullOrEmpty } from '@components/delaerVisit/components/addEditDealerVisit';
import { DropdownOptions } from '@components/common/formControl/dropdown';
import { useSiteSummaryListing } from '@services/hooks/site-summary-listing';
import { useImageUpload } from '@hooks/useImageUpload';
import { useDealerListRxDb } from '@services/hooks/enduser-details/rxdbHooks/useDealerListRxDb';
import { useDebounce } from '@hooks/useDebounce';
import { Modal } from '@components/common/modal/modal';
import { QrModal } from '@components/dashboard/components/meeting/qrModal';
import { overlayRemoveFadeAction } from '@components/common/sort';
import { ConfirmationModal } from '@components/conductedMeetings/confirmationModal/confirmationModal';
import { SiteLeadConfigurations } from '@components/siteLeads';

export const SiteAddress = (): ReactElement => {
	useSetMainHeader('Add New Site Visit');
	const { setCurrentStep } = useStepperStore();
	const { SiteAddressValidationSchema } = useValidation();
	useDealersQuery();
	useAvailableProductsListQuery();
	const { data: siteLeadData, isFetching: isSiteLeadFetching } = useGetSiteLeadQuery();
	const { result: availableProductsDataList } = useGetRxDb<AvailableProductsList>('products');
	const { mutate: updateSiteLead, isPending: isSiteLeadPending } = useUpdateSiteLeadMutation();
	const { dealerName, ownerDetails, siteVisitId } = useSiteLeadStore();
	const { data: siteVisitData, isFetching: isFetchingSiteVisit, error: siteVisitError } = useGetVisitQuery();
	const { mutate: updateSiteVisit, isPending: isSiteVisitPending } = useUpdateSiteVisitMutation();
	const { mutate: mutatePresignedURL, data: getSiteImagesPresignedUrl } = usePresignedURLQuery();
	const { mutate: putSiteImagesData, isPending: isPutSiteImagePending } =
		useUploadSiteImagesMutation(getSiteImagesPresignedUrl);
	const { refetch: refetchSiteSummaryList } = useSiteSummaryListing();
	const isDisabled = (siteLeadData?.site_visit_count ?? 0) > 1;
	const { handleAddImage } = useImageUpload();
	const [dealerSearch, setDealerSearch] = useState<string>('');
	const { result: dealerList } = useDealerListRxDb({
		searchParams: [
			{ key: 'dealername_c', value: dealerSearch },
			{ key: 'dealercode_c', value: dealerSearch },
		],
		sortBy: 'dealername_c',
	});
	const [openQrModal, setOpenQrModal] = useState<boolean>(false);
	const [confirmationModal, setConfirmationModal] = useState<boolean>(false);
	const [openOtpModal, setOpenOtpModal] = useState<boolean>(false);

	const onDropDownSearch = useDebounce((e: React.KeyboardEvent<HTMLInputElement>): void => {
		const element = e.target as HTMLInputElement;
		setDealerSearch(element.value);
	}, 300);

	const formik = useFormik({
		initialValues: defaultSiteAddressFormInitialValues,
		onSubmit: () => {},
		validationSchema: SiteAddressValidationSchema,
		validateOnMount: true,
	});

	const updateSiteProducts = useCallback((): void => {
		const { availableProducts, productImages } = formik.values;

		const siteVisitPayload: UpdateSiteVisitData = {
			product_available_at_site: isNullOrEmpty(availableProducts),
		};

		const imageKeys = ['visit_image1', 'visit_image2', 'visit_image3', 'visit_image4', 'visit_image5'];

		imageKeys.forEach((key, index) => {
			const imageUrl = getSiteImagesPresignedUrl?.[index]?.normal_url;
			if (imageUrl) {
				siteVisitPayload[key] = imageUrl;
			}
		});

		putSiteImagesData(productImages, {
			onSuccess: () => {
				updateSiteVisit(siteVisitPayload, {
					onSuccess: () => {
						refetchSiteSummaryList?.();
						if (formik?.values?.isContractorAvailable?.toLowerCase() === 'no' && SiteLeadConfigurations.OTP_FLOW) {
							setOpenOtpModal(true);
							return;
						}
						setOpenQrModal(true);
					},
				});
			},
		});
	}, [formik, getSiteImagesPresignedUrl, putSiteImagesData, updateSiteVisit, refetchSiteSummaryList]);

	const handleNext = useCallback((): void => {
		const payload: UpdateSiteLeadData = {
			site_type: formik.values.siteType,
			dealer_code: formik.values.dealerName.split('-')[0],
			dealer_name: formik.values.dealerName.split('-')[1],
			glue_potential: formik.values.gluePotentialOfSite,
			architect_name: formik.values.architect_details[0].name,
			architect_mobile_no: formik.values.architect_details[0].number,
			owner_name: formik.values.owner_details[0].name,
			owner_mobile_no: formik.values.owner_details[0].number,
			epoxy_adhesive_used_c: formik.values.veneer,
		};

		updateSiteLead(payload, {
			onSuccess: () => {
				updateSiteProducts();
			},
		});
	}, [formik.values, updateSiteLead, updateSiteProducts]);

	const handleImageAdd = useCallback(
		(event: React.ChangeEvent<HTMLInputElement>) => {
			const maxImageLimit = 5;
			const folderName = 'sitelead';
			const maximumFileSize = 20 * 1024 * 1024;
			const result = handleAddImage(event, formik.values.productImages, maximumFileSize, maxImageLimit, folderName);
			if (result) {
				const { payload, updatedImages } = result;
				updatedImages && formik.setFieldValue('productImages', updatedImages);
				payload && mutatePresignedURL?.(payload);
			}
		},
		[formik, handleAddImage, mutatePresignedURL]
	);

	const availableProductsList = useMemo((): DropdownOptions[] => {
		if (availableProductsDataList?.length) {
			return availableProductsDataList.map((product, index) => ({
				id: index,
				name: product?.productgroup3description_c,
				value: product?.prdgroup3_c ?? '',
			}));
		}
		return [];
	}, [availableProductsDataList]);

	const dealersList = useMemo((): DealersList[] => {
		if (dealerList?.length) {
			return dealerList.map((d, index) => ({
				id: index,
				value: `${d._data?.dealercode_c} -  ${d._data?.dealername_c}`,
				name: d._data?.dealername_c,
			}));
		}
		return [];
	}, [dealerList]);

	useEffect(() => {
		if (ownerDetails) {
			const details = [{ id: 0, isActive: true, name: ownerDetails.ownerName, number: ownerDetails.ownerNumber }];
			formik.setFieldValue('owner_details', details);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [ownerDetails]);

	const combinedSiteData = useMemo(() => {
		return {
			siteVisitData,
			siteLeadData,
			dealerName,
		};
	}, [siteVisitData, siteLeadData, dealerName]);

	useEffect(() => {
		formik.resetForm();

		if (combinedSiteData.siteVisitData && Object.keys(combinedSiteData.siteVisitData).length) {
			formik.setFieldValue('demoedProducts', combinedSiteData.siteVisitData?.product1_demoed_name);
			formik.setFieldValue('discussedProducts', combinedSiteData.siteVisitData?.product1_discussed?.split('-')[1]);
		}

		if (combinedSiteData.siteLeadData?.current_stage_of_site_c?.toLowerCase() === 'site visited') {
			const {
				glue_potential,
				dealer_name,
				dealer_code,
				owner_name,
				owner_mobile_no,
				architect_name,
				architect_mobile_no,
				epoxy_adhesive_used_c,
			} = combinedSiteData.siteLeadData;

			formik.setFieldValue('gluePotentialOfSite', glue_potential);
			formik.setFieldValue('veneer', epoxy_adhesive_used_c);
			formik.setFieldValue('dealerName', `${dealer_code} - ${dealer_name}`);
			formik.setFieldValue('owner_details', [{ id: 0, isActive: true, name: owner_name!, number: owner_mobile_no! }]);
			formik.setFieldValue('architect_details', [
				{ id: 0, isActive: true, name: architect_name!, number: architect_mobile_no! },
			]);
		}

		if (combinedSiteData.dealerName) {
			formik.setFieldValue('dealerName', combinedSiteData.dealerName);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [combinedSiteData]);

	const handleModalClose = useCallback(() => {
		setOpenQrModal(false);
		setConfirmationModal(true);
	}, []);

	const handleConfirmationModalClose = useCallback(() => {
		setOpenQrModal(true);
		setConfirmationModal(false);
	}, []);

	const handleConfirmSubmit = useCallback(() => {
		handleConfirmationModalClose();
		overlayRemoveFadeAction(() => setOpenQrModal(false));
		setCurrentStep(3);
	}, [handleConfirmationModalClose, setCurrentStep]);

	return (
		<section className="new-site-section add-site-visit-wrapper">
			<div className="container">
				<Wrapper isLoaderVisible={isSiteLeadFetching || isFetchingSiteVisit} isError={siteVisitError}>
					<form>
						<ul className="new-site-wrapper row">
							<li className="d-col d-col-2  form-radio">
								<RadioButton
									name="siteType"
									title="Site Type"
									checked={formik.values.siteType}
									radioOptions={siteRadioTypeList}
									onChange={formik.handleChange}
									isDisabled={isDisabled}
									required
								/>
							</li>
							<li className="d-col d-col-2 ">
								<Dropdown
									id="dealerName"
									name="dealerName"
									label="Dealer Name"
									onBlur={formik.handleBlur}
									title={formik.values.dealerName ?? ''}
									error={formik.touched.dealerName && formik.errors.dealerName ? formik.errors.dealerName : null}
									options={dealersList}
									setFieldValue={formik.setFieldValue}
									disabled={
										isDisabled &&
										!!(
											siteLeadData?.current_stage_of_site_c?.toLocaleLowerCase() === 'site visited' &&
											siteLeadData?.dealer_code &&
											siteLeadData?.dealer_name
										)
									}
									handelKeyDownProp={onDropDownSearch}
									listFetching={true}
									required
								/>
							</li>
							<li className="d-col d-col-2 ">
								<Dropdown
									id="gluePotentialOfSite"
									name="gluePotentialOfSite"
									label="Glue Potential of Site"
									onBlur={formik.handleBlur}
									title={formik.values.gluePotentialOfSite ?? ''}
									error={
										formik.touched.gluePotentialOfSite && formik.errors.gluePotentialOfSite
											? formik.errors.gluePotentialOfSite
											: null
									}
									options={gluePotentialofSiteList}
									setFieldValue={formik.setFieldValue}
									additionalLabel="in Vol"
									disabled={
										isDisabled &&
										!!(
											siteLeadData?.current_stage_of_site_c?.toLocaleLowerCase() === 'site visited' &&
											siteLeadData?.glue_potential
										)
									}
									required
								/>
							</li>

							<li className="d-col d-col-2 ">
								<Dropdown
									id="veneer"
									name="veneer"
									label="Is MDF/Veneer being used at site?"
									onBlur={formik.handleBlur}
									title={formik.values.veneer ?? ''}
									error={formik.touched.veneer && formik.errors.veneer ? formik.errors.veneer : null}
									options={veneerOptions}
									setFieldValue={formik.setFieldValue}
									disabled={
										isDisabled &&
										!!(
											siteLeadData?.current_stage_of_site_c?.toLocaleLowerCase() === 'site visited' &&
											siteLeadData?.epoxy_adhesive_used_c
										)
									}
									required
								/>
							</li>

							<li className="d-col">
								<span className="divider-border"></span>
							</li>

							{formik.values.owner_details.map((owner, index) => (
								<AccordianDetailList
									key={owner.id}
									title="Owner Details"
									accordianName="owner_details"
									accordianListIndex={index}
									formik={formik}
									data={owner}
									isEditing={
										isDisabled &&
										!!(
											siteLeadData?.current_stage_of_site_c?.toLocaleLowerCase() === 'site visited' &&
											siteLeadData?.owner_name &&
											siteLeadData?.owner_mobile_no
										)
									}
								/>
							))}
							{formik.values.architect_details.map((architect, index) => (
								<AccordianDetailList
									key={architect.id}
									title="Architect Details"
									accordianName="architect_details"
									accordianListIndex={index}
									formik={formik}
									data={architect}
									isEditing={isDisabled && !!(siteLeadData?.architect_name && siteLeadData?.architect_mobile_no)}
								/>
							))}

							<li className="d-col">
								<span className="divider-border"></span>
							</li>

							<li className="form-control d-col d-col-2">
								<TextInput
									id="demoedProducts"
									name="demoedProducts"
									type="text"
									onBlur={formik.handleBlur}
									value={formik.values.demoedProducts ?? ''}
									setFieldValue={formik.setFieldValue}
									label="Product Demo / Discuss"
									error={
										formik.touched.demoedProducts && formik.errors.demoedProducts ? formik.errors.demoedProducts : null
									}
									isAutoFocus
									disabled
								/>
							</li>
							<li className="form-control d-col d-col-2">
								<TextInput
									id="discussedProducts"
									name="discussedProducts"
									type="text"
									onBlur={formik.handleBlur}
									value={formik.values.discussedProducts ?? ''}
									setFieldValue={formik.setFieldValue}
									label="Product Demo / Discuss"
									error={
										formik.touched.discussedProducts && formik.errors.discussedProducts
											? formik.errors.discussedProducts
											: null
									}
									disabled
								/>
							</li>
							<li className="d-col d-col-2">
								<ProductImages fieldName="productImages" formik={formik} handleAddImage={handleImageAdd} required />
							</li>
							<li className="d-col d-col-2">
								<MultiselectDropdown
									id="availableProducts"
									name="availableProducts"
									label="Product Available at Site"
									className="input-border"
									onBlur={formik.handleBlur}
									values={formik.values.availableProducts}
									options={availableProductsList}
									setFieldValue={formik.setFieldValue}
									maxLimit={3}
									required
									error={
										formik.touched.availableProducts && formik.errors.availableProducts
											? formik.errors.availableProducts.toString()
											: null
									}
								/>
							</li>
							{SiteLeadConfigurations.OTP_FLOW && (
								<li className="d-col d-col-2 ">
									<Dropdown
										id="isContractorAvailable"
										name="isContractorAvailable"
										label="Is contractor Present"
										onBlur={formik.handleBlur}
										title={formik.values.isContractorAvailable ?? ''}
										error={
											formik.touched.isContractorAvailable && formik.errors.isContractorAvailable
												? formik.errors.isContractorAvailable
												: null
										}
										options={veneerOptions}
										setFieldValue={formik.setFieldValue}
										required
									/>
								</li>
							)}
						</ul>
					</form>
				</Wrapper>

				<Stepper
					label="Submit"
					nextHandler={handleNext}
					isNext={!formik.isValid || !formik.dirty || isPutSiteImagePending || !formik.values.productImages.length}
					isPending={isSiteLeadPending || isSiteVisitPending || isPutSiteImagePending}
				/>
			</div>
			{openQrModal && (
				<Modal
					modalId="siteQrCode"
					className="dialog-sm site-lead-qr-code dialog-bottom"
					modalOpen={openQrModal}
					isEscAllow={false}
					onModalClose={handleModalClose}
					modalTitle={'Scan QR Code To Verify Site'}
				>
					<QrModal qrId={siteVisitId?.toString()} modalClose={handleModalClose} isCloseVisible={false} />
				</Modal>
			)}
			{openOtpModal && (
				<Modal
					modalId="siteOtpCode"
					className="dialog-sm site-lead-otp-code-modal dialog-bottom"
					modalOpen={openOtpModal}
					isEscAllow={false}
					onModalClose={handleModalClose}
					modalTitle={'Validate OTP'}
				>
					<OtpModal
						onClose={() => setConfirmationModal(true)}
						submitButtonLabel={'Generate OTP'}
						hideCancelButton={true}
						onSubmit={() => {
							setOpenOtpModal(false);
							handleConfirmSubmit();
						}}
					/>
				</Modal>
			)}
			{confirmationModal && (
				<Modal
					modalId="confirmationModal"
					className="dialog-sm site-lead-confrim"
					modalOpen={confirmationModal}
					isEscAllow={false}
					onModalClose={handleConfirmationModalClose}
				>
					<ConfirmationModal
						onClose={handleConfirmationModalClose}
						messageClassName={'h3'}
						onSubmit={handleConfirmSubmit}
						message="Are you sure you do not want to verify this site?"
						buttonLabel={'Confirm'}
					/>
				</Modal>
			)}
		</section>
	);
};
