import { RxCollectionCreator } from 'rxdb';

export const subContractorsSchema: RxCollectionCreator = {
	schema: {
		title: 'Sub Contractor List',
		description: 'SubContractor Schema',
		version: 0,
		primaryKey: 'id',
		type: 'object',
		properties: {
			id: { type: 'string', maxLength: 250 },
			phone: { type: ['string'] },
			membershipno: { type: ['string'] },
			name: { type: ['string', 'null'] },
		},
		required: ['id'],
	},
};
