import { ReactElement, useRef, useState } from 'react';
import { NavLink } from 'react-router-dom';
import { IAddNewDataButton } from '@components/common';
import { useOutsideDialogClose } from '@hooks/useOutsideDialogClose';

export const AddNewDataButton = (props: IAddNewDataButton): ReactElement => {
	const { redirectUrl, labelName, handleClick, redirectUrlList } = props;
	const [openMenu, setOpenMenu] = useState<boolean>(false);
	const subMenuRef = useRef<HTMLInputElement>(null);
	useOutsideDialogClose({
		dialogRef: subMenuRef,
		callBackFunction: () => setOpenMenu(false),
	});

	const backRouteNavigation = (): void => {
		handleClick && handleClick();
	};

	const menuOpen = (): void => {
		setOpenMenu(!openMenu);
	};

	return (
		<div ref={subMenuRef} className="container floting-component-wrapper">
			{openMenu && redirectUrlList && redirectUrlList?.length !== 0 && (
				<div className={`floating-sub-menu`}>
					<ul>
						{redirectUrlList.map((list) => {
							return (
								<li key={list.key}>
									<span className="floating-btn-label">{list.ariaLabel}</span>
									<NavLink
										to={list?.route}
										aria-label={list.ariaLabel}
										className="floating-btn"
										onClick={backRouteNavigation}
									>
										<span className={list.icon}></span>
									</NavLink>
								</li>
							);
						})}
					</ul>
				</div>
			)}
			{redirectUrl ? (
				<NavLink to={redirectUrl} aria-label={labelName} className="floating-btn" onClick={backRouteNavigation}>
					<span className="icon-plus"></span>
				</NavLink>
			) : (
				<button aria-label={labelName} className={`floating-btn ${openMenu && 'active'}`} onClick={menuOpen}>
					<span className="icon-plus"></span>
				</button>
			)}
		</div>
	);
};
