import * as Yup from 'yup';
import useCommanValidationFields from './common';
import useValidationFields from './fields';
import { useOneToOneMeetingFields, useQuickAddEndUserFields } from './oneToOneMeetingFormValidation';
import { useTSIContractorDetailFormFields } from './TSIContractorForm';
import { useTSIQuickEndMeetingFormValidation } from './TSIQuickEndMeetingFormValidation';
import { ISupervisorFields, useSupervisorValidationFields } from '@hooks/validations/supervisorForm';

// TODO:: Need to implement type of the schemas.
// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export const useValidation = () => {
	const { first_name, last_name, middle_name, religion, language, birth_date, email } = useCommanValidationFields();
	const {
		primary_number,
		secondary_number,
		sites_worked,
		sites_competition,
		average_glue,
		calculated_glue,
		permanent_town,
		permanent_address,
		permanent_city,
		permanent_state,
		permanent_street,
		permanent_zipcode,
		local_address,
		local_city,
		local_state,
		local_street,
		local_zipcode,
		work_address,
		work_city,
		work_state,
		work_street,
		work_zipcode,
		document,
		document_number,
		club,
		primary_details,
		average_glue_potential,
		workshop_address,
		workshop_zipcode,
		workshop_city,
		workshop_street,
		workshop_state,
		competition_details,
		siteName,
		siteLocation,
		gradeUsed,
		contractType,
		competitionReason,
		contractorName,
		contractorNumber,
		title,
		meeting_venue_c,
		startdate,
		enddate,
		end_time_c,
		start_time_c,
		dealerName,
		gluePotentialOfSite,
		availableProducts,
		architect_details,
		influencer_details,
		owner_details,
		kyc_file_name,
		feedback,
		name,
		contact,
		town,
		meetingVenue,
		meetingType,
		mdi,
		productDiscussed,
		primaryFocusProduct,
		meetFormat,
		productHierarchyCode,
		catagories,
		designation,
		natureOfWork,
		userToBeLinked,
		learningForm,
		obstacleFaced,
		meeting_expenses,
		food,
		venue,
		gifts,
		others,
		slab,
		exception,
		activityDiscussed,
		productRSI,
		address,
		contractorNumber1,
		contractorNumber2,
		sku_names_c,
		ref_lot_no_c,
		manufactured_in_c,
		quantity_c,
		customer_name_c,
		registered_mobile_number_c,
		type_of_complaint_c,
		dealer_membership_code_c,
		wss_names_c,
		site_under_warranty_c,
		what_is_the_warranty_certificate_number_c,
		nature_of_complaints_c,
		sub_category_defect_c,
		description_c,
		pictures_videos_c,
		complaint_sample_availability_c,
		sample_non_availability_reason,
		sample_non_availability_reason_others,
		contractorName1,
		contractorName2,
		applicator_name,
		stage_of_application_during_complaint_c,
		application_surface_c,
		area_in_sqft_c,
		observation_c,
		factory_address,
		factory_city,
		factory_state,
		factory_zipcode,
		veneer,
		isContractorAvailable,
		substratesCombinations,
		glueUsedAtSite,
		meetingClub,
		meetingDealer,
		competitionConsumption,
	} = useValidationFields();

	const { endUser, typeOfActivity, otherTypeOfActivity, Product1Demo, meetingTime } = useOneToOneMeetingFields();
	const { firstName, lastName, mobileNumber, endUserCategory, primaryDealer, wssTerritory, zipcode } =
		useQuickAddEndUserFields();
	const TSIContractorFormFields = useTSIContractorDetailFormFields();
	const TSIQuickEndMeetingFormFields = useTSIQuickEndMeetingFormValidation();
	const { largeContractor } = useSupervisorValidationFields();

	const basicDetailsValidationSchema = Yup.object({
		first_name: first_name,
		last_name: last_name,
		middle_name: middle_name,
		religion: religion,
		language: language,
		primary_number: primary_number,
		secondary_number: secondary_number,
		sites_worked: sites_worked,
		sites_competition: sites_competition,
		average_glue: average_glue,
		competitionConsumption: competitionConsumption,
		calculated_glue: calculated_glue,
		permanent_town: permanent_town,
		permanent_address: permanent_address,
		permanent_city: permanent_city,
		permanent_state: permanent_state,
		permanent_street: permanent_street,
		permanent_zipcode: permanent_zipcode,
		local_address: local_address,
		local_city: local_city,
		local_state: local_state,
		local_street: local_street,
		local_zipcode: local_zipcode,
		work_address: work_address,
		work_city: work_city,
		work_state: work_state,
		work_street: work_street,
		work_zipcode: work_zipcode,
		birth_date: birth_date,
		email: email,
	});

	const KYCDetailsValidationSchema = Yup.object({
		document: document,
		document_number: document_number,
		club: club,
		kyc_file_name: kyc_file_name,
	});
	const MoreDetailsValidationSchema = Yup.object({
		primary_details: primary_details,
		competition_details: competition_details,
		average_glue_potential: average_glue_potential,
		workshop_address: workshop_address,
		workshop_zipcode: workshop_zipcode,
		workshop_city: workshop_city,
		workshop_street: workshop_street,
		workshop_state: workshop_state,
		architect_details: architect_details,
		influencer_details: influencer_details,
		factory_address: factory_address,
		factory_city: factory_city,
		factory_state: factory_state,
		factory_zipcode: factory_zipcode,
	});

	const AddSiteLeadValidationSchema = Yup.object({
		siteName: siteName,
		siteLocation: siteLocation,
		gradeUsed: gradeUsed,
		contractType: contractType,
		competitionReason: competitionReason,
		contractorName: contractorName,
		contractorNumber: contractorNumber,
		substratesCombinations: substratesCombinations,
		glueUsedAtSite: glueUsedAtSite,
	});

	const AddMeetingValidationSchema = Yup.object({
		startdate: startdate,
		enddate: enddate,
		start_time_c: start_time_c,
		end_time_c: end_time_c,
		name: name,
		contact: contact,
		town: town,
		meetingVenue: meetingVenue,
		meetingType: meetingType,
		mdi: mdi,
		primaryFocusProduct: primaryFocusProduct,
		product1Discussed: productDiscussed,
		product2Discussed: productDiscussed,
		meetFormat: meetFormat,
		club: meetingClub,
		dealer: meetingDealer,
	});

	const UpdateMeetingValidationSchema = Yup.object({
		primaryFocusProduct: primaryFocusProduct,
		product1Discussed: productDiscussed,
		product2Discussed: productDiscussed,
	});
	const SiteAddressValidationSchema = Yup.object({
		dealerName: dealerName,
		gluePotentialOfSite: gluePotentialOfSite,
		architect_details: architect_details,
		owner_details: owner_details,
		availableProducts: availableProducts,
		veneer: veneer,
		isContractorAvailable: isContractorAvailable,
	});

	const SiteProductsValidationSchema = Yup.object({
		availableProducts: availableProducts,
	});

	const TaskListValidationSchema = Yup.object({
		feedback: feedback,
		slab: slab,
		exception: exception,
	});

	const CalendarSchema = Yup.object({
		title: title,
		meeting_venue_c: meeting_venue_c,
		startdate: startdate,
		enddate: enddate,
		end_time_c: end_time_c,
		start_time_c: start_time_c,
	});

	const AddUserSchema = Yup.object({
		firstName: first_name,
		lastName: last_name,
		primary_number: primary_number,
		secondary_number: secondary_number,
		productHierarchyCode: productHierarchyCode,
		...TSIQuickEndMeetingFormFields,
	});

	const NonEndUserSchema = Yup.object({
		catagories: catagories,
		designation: designation,
		natureOfWork: natureOfWork,
		userToBeLinked: userToBeLinked,
		firstName: first_name,
		lastName: last_name,
		primary_number: primary_number,
		secondary_number: secondary_number,
		productHierarchyCode: productHierarchyCode,
	});

	const FeedbackValidationSchema = Yup.object({
		obstacleFaced: obstacleFaced,
		learningForm: learningForm,
	});

	const ExpenseValidationSchema = Yup.object({
		meetingExpense: meeting_expenses,
		foodCost: food,
		venueCost: venue,
		giftCost: gifts,
		otherCost: others,
	});

	const DealerVisitValidationSchema = Yup.object({
		dealerName: dealerName,
		siteLocation: siteLocation,
		activityDiscussed: activityDiscussed,
		productRSI: productRSI,
		address: address,
		contractorNumber1: contractorNumber1,
		contractorNumber2: contractorNumber2,
		contractorName1: contractorName1,
		contractorName2: contractorName2,
	});

	const PcmsRegistrationSchema = Yup.object({
		sku_names_c: sku_names_c,
		ref_lot_no_c: ref_lot_no_c,
		manufactured_in_c: manufactured_in_c,
		quantity_c: quantity_c,
		customer_name_c: customer_name_c,
		registered_mobile_number_c: registered_mobile_number_c,
		type_of_complaint_c: type_of_complaint_c,
		dealer_membership_code_c: dealer_membership_code_c,
		wss_names_c: wss_names_c,
		site_under_warranty_c: site_under_warranty_c,
		what_is_the_warranty_certificate_number_c: what_is_the_warranty_certificate_number_c,
		nature_of_complaints_c: nature_of_complaints_c,
		sub_category_defect_c: sub_category_defect_c,
		description_c: description_c,
		pictures_videos_c: pictures_videos_c,
		complaint_sample_availability_c: complaint_sample_availability_c,
		sample_non_availability_reason: sample_non_availability_reason,
		sample_non_availability_reason_others: sample_non_availability_reason_others,
		applicator_name: applicator_name,
		stage_of_application_during_complaint_c: stage_of_application_during_complaint_c,
		application_surface_c: application_surface_c,
		area_in_sqft_c: area_in_sqft_c,
		observation_c: observation_c,
	});

	const OneToOneMeetingValidationSchema = Yup.object({
		endUser: endUser,
		typeOfActivity: typeOfActivity,
		otherTypeOfActivity: otherTypeOfActivity,
		Product1Demo: Product1Demo,
		meetingTime: meetingTime,
	});

	const supervisorValidation: Yup.ObjectSchema<ISupervisorFields> = Yup.object({
		firstname: first_name,
		middlename: middle_name,
		lastname: last_name,
		phone: primary_number,
		largeContractor: largeContractor,
	});

	const QuickAdEndUserValidationSchema = Yup.object({
		firstName: firstName,
		lastName: lastName,
		mobileNumber: mobileNumber,
		endUserCategory: endUserCategory,
		primaryDealer: primaryDealer,
		wssTerritory: wssTerritory,
		town: town,
		zipcode: zipcode,
	});

	const TSIContractorFormValidationSchema = Yup.object({
		...TSIContractorFormFields,
	});

	return {
		basicDetailsValidationSchema,
		KYCDetailsValidationSchema,
		MoreDetailsValidationSchema,
		AddSiteLeadValidationSchema,
		SiteAddressValidationSchema,
		SiteProductsValidationSchema,
		TaskListValidationSchema,
		CalendarSchema,
		AddMeetingValidationSchema,
		AddUserSchema,
		UpdateMeetingValidationSchema,
		NonEndUserSchema,
		FeedbackValidationSchema,
		ExpenseValidationSchema,
		DealerVisitValidationSchema,
		PcmsRegistrationSchema,
		OneToOneMeetingValidationSchema,
		QuickAdEndUserValidationSchema,
		TSIContractorFormValidationSchema,
		supervisorValidation,
	};
};
